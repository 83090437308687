import React, { useEffect, useMemo } from "react";
import Routers from "./Routers";
import "./App.scss";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./store/store";
import { Button, ConfigProvider } from "antd";
// import "bootstrap/dist/js/bootstrap.bundle";
import "react-toastify/dist/ReactToastify.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { NotificationProvider } from "./NotificationContext";
import { PersistGate } from "redux-persist/integration/react";

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// persistor.purge();

const Context = React.createContext({
  name: "Default",
});

const App = () => {
  useEffect(() => {
    console.log("APPHITTING");
  }, []);

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#00b96b",
              borderRadius: "2px",
            },
          }}
        >
          <NotificationProvider>
            <Context.Provider value={contextValue}>
              <Routers />
            </Context.Provider>
          </NotificationProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
