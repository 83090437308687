import React, { useState } from "react";
import { message, Popconfirm, Select, Table, Typography, Upload } from 'antd';
import { InboxOutlined, CloseOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: true,
    action: process.env.REACT_APP_BASE_URL + "/document/document/upload_document/",
    onChange(info) {
        console.log(info);
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};

const DraggerBoxTable = () => {

    const [documents, setDocuments] = useState([]);

    const columns = [{
        title: 'Name',
        dataIndex: 'name',
        width: '15%',
        editable: true,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        width: '15%',
        editable: true,
    },
    {
        title: 'Type',
        dataIndex: 'type',
        width: '15%',
        editable: true,
    },
    {
        title: 'Size',
        dataIndex: 'size',
        width: '15%',
        editable: true,
    },
    {
        title: 'Actions',
        dataIndex: 'action',
        render: (_, record) => {
            return (
                <span>
                    <Typography.Link onClick={() => console.log("View")}>
                        View
                    </Typography.Link>
                    <Typography.Link onClick={() => console.log("Download")}>
                        Download
                    </Typography.Link>
                    <Popconfirm title="Sure to delete?" onConfirm={() => console.log("delete")}>
                        <a style={{ marginLeft: 10 }}>Delete</a>
                    </Popconfirm>
                </span>
            );
        },
    },
    ];
    return (
        <div>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                    band files
                </p>
            </Dragger>
            <Table
                dataSource={documents}
                columns={columns}
                rowClassName="editable-row"
                size="small"
                pagination={false}
            />
        </div>

    );
}

export default DraggerBoxTable;