import { NavSider, ExpensesTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Expenses = () => {

    return (
        <ExpensesTable />
    );

}
export default Expenses;