import React, { useState, useEffect } from 'react';
import {
    Button,
    Select,
    Form,
    Input,
    Checkbox,
    Col,
    Row,
    Card,
    Space,
    Modal
} from 'antd';
import * as authActions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';
import { BiArrowBack, BiSolidEdit } from 'react-icons/bi';
import { IoIosPause } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { FaRegClone } from 'react-icons/fa';

const MonitorInfo = (params) => {

    const { id, setCurrentId, setAdd, setMonitorInfo, fetchMonitors } = params;
    const [dataSource, setDataSource] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [pauseModal, setPauseModal] = useState(false);
    const beats = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    const fetchMonitor = async () => {
        if (id) {
            const response = await authActions.fetchMonitor(id);
            console.log(response)
            if (response) {
                console.log("Changing state now...")
                setDataSource(response);
            }
        }
        setLoading(false);
    }

    const handleDelete = async () => {
        const response = await authActions.deleteMonitor(dataSource.id);
        // setDeleteModal(false)
        if (response) {
            // fetchMonitors();
            window.location.reload()
        }
    };

    const handlePause = async () => {
        const response = await authActions.pauseMonitor(dataSource.id);
        // setDeleteModal(false)
        if (response) {
            // fetchMonitors();
            window.location.reload()
        }
    };

    useEffect(() => {
        fetchMonitor()
    }, [])

    return (
        <>
            {!loading &&
                <>
                    <div className='py-2 sticky-top custom-top'>
                        <div className="d-flex">
                            <BiArrowBack
                                className='cursor-pointer'
                                size={20}
                                onClick={() => {
                                    setMonitorInfo(false)
                                    setCurrentId(null)
                                }} />
                            <h5 className="ms-3">{dataSource.name}</h5>
                        </div>
                    </div>
                    <Space wrap>
                        <Button
                            className="d-flex justify-content-center align-items-center"
                            type="primary"
                            onClick={() => setPauseModal(true)}
                        >
                            <IoIosPause />&nbsp;Pause
                        </Button>
                        <Button
                            className="d-flex justify-content-center align-items-center"
                            type="primary"
                            onClick={() => {
                                setAdd(true)
                                setMonitorInfo(false)
                            }}
                        >
                            <BiSolidEdit />&nbsp;Edit
                        </Button>
                        {/* <Button className="d-flex justify-content-center align-items-center" type="primary"><FaRegClone />&nbsp;Clone</Button> */}
                        <Button
                            className="d-flex justify-content-center align-items-center"
                            type="primary"
                            onClick={() => setDeleteModal(true)}
                            danger
                        >
                            <MdDelete />&nbsp;Delete
                        </Button>
                    </Space>
                    <Card className="my-3 d-flex flex-column" bordered={false}>
                        {beats.map((beat) => {
                            return (
                                <div className="beat">
                                </div>
                            )
                        })}
                    </Card>
                    <Modal
                        show={deleteModal}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onClose={() => setDeleteModal(false)}
                        onHide={() => setDeleteModal(false)}
                        onExited={() => {
                            setDeleteModal(false);
                        }}
                    >
                        <Modal.Body>
                            <p>Do you want to delete the Monitoring object?</p>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center">
                            <Button
                                variant="primary"
                                onClick={() => {
                                    handleDelete()
                                }}
                            >
                                Confirm
                            </Button>
                            <Button variant="secondary" onClick={() => setDeleteModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={pauseModal}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onClose={() => setPauseModal(false)}
                        onHide={() => setPauseModal(false)}
                        onExited={() => {
                            setPauseModal(false);
                        }}
                    >
                        <Modal.Body>
                            <p>Do you want to pause the Monitoring object?</p>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center">
                            <Button
                                variant="primary"
                                onClick={() => {
                                    handlePause()
                                }}
                            >
                                Confirm
                            </Button>
                            <Button variant="secondary" onClick={() => setPauseModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    );
};
export default MonitorInfo;