import React, { useEffect, useState } from 'react';
import { Button, Table, Row, Col, Card, Space } from 'antd';
import { MdOutlineMonitorHeart } from 'react-icons/md';
import { useNavigate } from "react-router-dom"
import * as authActions from "store/actions/AuthActions"
import axios from 'axios';
import useScrollBottom from 'CustomHooks/useScrollBottom';
import { Input } from "antd";
import AddUpdateMonitorForm from './AddUpdateMonitorForm';
import MonitorInfo from './MonitorInfo';

const AssetFilesTable = () => {

    const bottom = useScrollBottom();
    const { Search } = Input;

    const [searchTerm, setSearchTerm] = useState("");

    const [totalRows, setTotalRows] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);

    const [dataSource, setDataSource] = useState();
    const [add, setAdd] = useState(false);
    const [monitorInfo, setMonitorInfo] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [monitoringStat, setMonitoringStat] = useState(null);

    const fetchMonitors = async (page) => {
        const response = await authActions.loadAllMonitors(page, searchTerm);
        console.log({ response, dataSource });
        if (response && response.results && response.results.length > 0) {
            if (dataSource && dataSource.length > 0) {
                const toPut = [];
                const pushedIds = [];
                dataSource.forEach((d, i) => {
                    if (!pushedIds.includes(d.id)) {
                        pushedIds.push(d.id);
                        toPut.push(d);
                    }
                });
                response.results.forEach((rr, i) => {
                    if (!pushedIds.includes(rr.id)) {
                        pushedIds.push(rr.id);
                        toPut.push(rr);
                    }
                })
                setDataSource(toPut);
            } else {
                setTotalRows(response.count);
                setDataSource(response.results);
            }
        }
    }

    const fetchAllMonitoringInfo = async () => {
        const response = await authActions.loadMonitoringStats()
        if (response) {
            setMonitoringStat(response);
        }
    }

    useEffect(() => {
        fetchAllMonitoringInfo();
    }, [])

    const onSearch = (value) => {
        if (searchTerm === value) {

        } else {
            setSearchTerm(value);
            setDataSource([]);
            setPageNumber(0);
        }
    }

    useEffect(() => {
        let p = pageNumber + 1;
        if (p === 1) {
            fetchMonitors(p);
            setPageNumber(p);
        } else if (dataSource && dataSource.length < totalRows) {
            fetchMonitors(p);
            setPageNumber(p);
        }
    }, [bottom, searchTerm]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    }

    const defaultColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: "100%",
            sorter: (a, b) => a?.name.localeCompare(b?.name),
            render: (_, record) => (
                <div onClick={() => {
                    setCurrentId(record.id)
                    setMonitorInfo(true)
                }}>
                    <u className='cursor-pointer'>{record.name}</u>
                </div>
            ),
        },
    ];

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };


    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const [selectionType, setSelectionType] = useState('checkbox');

    return (
        <>
            <Row>
                <Col span={6}>
                    <div className='d-flex align-items-center justify-content-between py-2 sticky-top custom-top'>
                        <Button
                            className="px-2"
                            // onClick={handleAdd}
                            // onClick={() => navigate("/assetfiles/new")}
                            onClick={() => setAdd(true)}
                            icon={<MdOutlineMonitorHeart />}
                            type="primary"
                        >
                            &nbsp;Add New Monitor
                        </Button>
                        <div>
                            <Search placeholder="input search text" onSearch={onSearch} style={{ width: 200 }} />
                        </div>
                    </div>
                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection,
                        }}
                        bordered
                        size="small"
                        dataSource={dataSource}
                        columns={columns}
                        // rowKey={row => row.id}
                        sticky
                        pagination={false}
                    />
                </Col>
                {add &&
                    <Col className="ps-4" span={18}>
                        <AddUpdateMonitorForm id={currentId} setAdd={setAdd} setMonitorInfo={setMonitorInfo} />
                    </Col>
                }
                {monitorInfo && !add &&
                    <Col className="ps-4" span={18}>
                        <MonitorInfo id={currentId} setCurrentId={setCurrentId} setAdd={setAdd} setMonitorInfo={setMonitorInfo} fetchMonitors={fetchMonitors} />
                    </Col>
                }
                {!monitorInfo && !add &&
                    <Col className="ps-4" span={18}>
                        <div className='py-2 sticky-top custom-top'>
                            <h5>Quick Stats</h5>
                        </div>
                        <Card className="mb-3" bordered={false}>
                            <Row className="text-center">
                                <Col span={4}>
                                    <h4 className="mb-4">Up</h4>
                                    <h4 style={{ color: "green" }}>{monitoringStat?.up}</h4>
                                </Col>
                                <Col span={4}>
                                    <h4 className="mb-4">Down</h4>
                                    <h4 style={{ color: "red" }}>{monitoringStat?.down}</h4>
                                </Col>
                                <Col span={6}>
                                    <h4 className="mb-4">Maintenance</h4>
                                    <h4 style={{ color: "blue" }}>{monitoringStat?.maintenance}</h4>
                                </Col>
                                <Col span={5}>
                                    <h4 className="mb-4">Unknown</h4>
                                    <h4 style={{ color: "grey" }}>{monitoringStat?.unknown}</h4>
                                </Col>
                                <Col span={5}>
                                    <h4 className="mb-4">Pause</h4>
                                    <h4 style={{ color: "grey" }}>{monitoringStat?.pause}</h4>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered={false} style={{}}>
                            <Row className="">
                                <Col span={6}>
                                    <div className="mb-4">Name</div>
                                </Col>
                                <Col span={6}>
                                    <div className="mb-4">Status</div>
                                </Col>
                                <Col span={6}>
                                    <div className="mb-4">Date/Time</div>
                                </Col>
                                <Col span={6}>
                                    <div className="mb-4">Message</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                }
            </Row>
        </>
    );
};
export default AssetFilesTable;