import React, { useState } from "react";
import { Button, Card, Col, Flex, Form, Input, Row, Select } from "antd";
import CodeMirrorEditor from "components/Editors/CodeMirrorEditor";

const EditorViewer = () => {

    const [codeMirrorData, setCodeMirrorData] = useState(null);

    return (
        <>
            <div className='custom-scroll-div' style={{ overflowY: "scroll", maxHeight: "90vh", padding: 10, paddingBottom: 30 }}>
                {/* <Form form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign="left"> */}
                    <Flex justify='end' gap='middle' style={{ margin: "10px auto" }}>
                        <Button type="primary" htmlType="submit" >
                            Save
                        </Button>
                    </Flex>
                    <Row>
                        <Col span={12}>
                            <Card title="Template Meta Information" bordered={false}>
                                <CodeMirrorEditor
                                    // instance={instance}
                                    // codeMirrorData={codeMirrorData}
                                    setCodeMirrorData={(value) => setCodeMirrorData(value)}
                                />
                            </Card>
                        </Col>
                        <Col span={12} style={{ padding: "0px 0px 10px 10px" }}>
                            <Card title="PDF Render" bordered={false}>
                                <div dangerouslySetInnerHTML={{ __html: codeMirrorData }}>

                                </div>
                            </Card>
                        </Col>
                    </Row>
                {/* </Form> */}
            </div>
        </>
    );
}

export default EditorViewer;