import React, { useState, useEffect } from 'react';
import {
    Button,
    Select,
    Form,
    Input,
    Checkbox,
    Col,
    Row,
    Card,
} from 'antd';

import * as authActions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

const AddUpdateMonitorForm = (params) => {

    const { id, setAdd, setMonitorInfo } = params;
    const { TextArea } = Input;
    const [dataSource, setDataSource] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [notifyByMail, setNotifyByMail] = useState(false);
    const [notifyByWhatsapp, setNotifyByWhatsapp] = useState(false);
    const [active, setActive] = useState(false);

    const onFinish = (values) => {
        console.log('Success:', values);
        if (values) {
            onSaveHandler({ ...values, 'notify_by_mail': notifyByMail, 'notify_by_whatsapp': notifyByWhatsapp, 'is_active': active })
        }
    };

    const fetchMonitor = async () => {
        if (id) {
            const response = await authActions.fetchMonitor(id);
            console.log(response)
            if (response) {
                console.log("Changing state now...")
                setDataSource(response);
                setNotifyByMail(response.notify_by_mail);
                setNotifyByWhatsapp(response.notify_by_whatsapp);
                setActive(response.is_active)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchMonitor()
    }, [])

    const onSaveHandler = async (values) => {
        if (id) {
            const response = await authActions.updateMonitor(
                values, id
            );
            console.log(response);
            if (response) {
                // navigate("/systemmonitoring")
                window.location.reload()
            }
        } else {
            const response = await authActions.addMonitor(
                values
            );
            console.log(response);
            if (response) {
                // navigate("/systemmonitoring")
                window.location.reload()
            }
        }
    }

    return (
        <>
            {!loading &&
                <>
                    <div className='py-2 sticky-top custom-top'>
                        {id ?
                            <div className="d-flex">
                                <BiArrowBack
                                    className='cursor-pointer'
                                    size={20}
                                    onClick={() => {
                                        setAdd(false)
                                        setMonitorInfo(true)
                                    }}
                                />
                                <h5 className="ms-3">Edit - {dataSource.name}</h5>
                            </div>
                            :
                            <div className="d-flex">
                                <BiArrowBack
                                    className='cursor-pointer'
                                    size={20}
                                    onClick={() => {
                                        setAdd(false)
                                        setMonitorInfo(false)
                                    }}
                                />
                                <h5 className="ms-3">Add New Monitor</h5>
                            </div>
                        }
                    </div>
                    <Form
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 18 }}
                        onFinish={onFinish}
                        initialValues={dataSource}
                    >
                        <Row>
                            {/* FORM_1 */}
                            <Col className="gutter-row pb-3" span={12}>
                                <div className="site-card-border-less-wrapper" >
                                    <Card title="Monitoring Info" bordered={false} >
                                        <div
                                        >
                                            <Form.Item label="Monitor Type" name="monitor_type">
                                                <Select >
                                                    <Select.Option value='HTTP'>HTTP</Select.Option>
                                                    <Select.Option value='HTTPS'>HTTPS</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="URL" name='url' rules={[{ required: true }]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item className="text-start" label="Name" name='name' rules={[{ required: true }]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="Method" name="method">
                                                <Select >
                                                    <Select.Option value='GET'>GET</Select.Option>
                                                    <Select.Option value='POST'>POST</Select.Option>
                                                    <Select.Option value='PUT'>PUT</Select.Option>
                                                    <Select.Option value='PATCH'>PATCH</Select.Option>
                                                    <Select.Option value='DELETE'>DELETE</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Body Encoding" name="body_encoding">
                                                <Select >
                                                    <Select.Option value='JSON'>JSON</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Body" name='body'>
                                                <TextArea rows={5} placeholder={`{\n\t"key":"value"\n}`} />
                                            </Form.Item>
                                            <Form.Item label="Headers" name='headers'>
                                                <TextArea rows={5} placeholder={`{\n\t"HeaderName":"HeaderValue"\n}`} />
                                            </Form.Item>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                            { /* FORM_3 */}
                            <Col className="gutter-row" span={12}>
                                <div className="site-card-border-less-wrapper ps-3">
                                    <Card title="Notification Info" bordered={false} >
                                        <div className="mb-3">
                                            <Checkbox
                                                checked={notifyByMail}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setNotifyByMail(true)
                                                    } else {
                                                        setNotifyByMail(false)
                                                    }
                                                }}
                                            >
                                                Notify by Email
                                            </Checkbox>
                                            <Checkbox
                                                checked={notifyByWhatsapp}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setNotifyByWhatsapp(true)
                                                    } else {
                                                        setNotifyByWhatsapp(false)
                                                    }
                                                }}
                                            >
                                                Notify by WhatsApp
                                            </Checkbox>
                                        </div>
                                        {notifyByMail &&
                                            <Form.Item label="Email" name='notification_mail'>
                                                <Input />
                                            </Form.Item>
                                        }
                                        {notifyByWhatsapp &&
                                            <Form.Item label="Number" name='notification_whatsapp'>
                                                <Input />
                                            </Form.Item>
                                        }
                                        <Checkbox
                                            checked={active}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setActive(true)
                                                } else {
                                                    setActive(false)
                                                }
                                            }}
                                        >
                                            Is Active?
                                        </Checkbox>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Button block type="primary" htmlType="submit" >
                                Save
                            </Button>
                        </div>
                    </Form>
                </>
            }
        </>
    );
};
export default AddUpdateMonitorForm;