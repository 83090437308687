import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    Input,
    Col,
    Row,
    Flex,
    Select,
    DatePicker,
    InputNumber,
    Switch,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as adminactions from "store/actions/AuthActions";
import dayjs from 'dayjs';
import { CKEditor } from 'ckeditor4-react';
import axios from 'axios';


const QuoteLineForm = ({ inventoryId, quoteItemData, setQuoteItemData, taxRates, instance, form, reload }) => {

    const [products, setProducts] = useState([]);
    const [productDescription, setProductDescription] = useState("<p>Create your Template here!</p>")
    const [unit, setUnit] = useState("UNITS");

    const [taxRate1, setTaxRate1] = useState(0);
    const [taxRate2, setTaxRate2] = useState(0);
    const [taxRate3, setTaxRate3] = useState(0);

    const editorConfig = {
        extraPlugins: ["tableresize", "uploadimage", "createpdf"],
        removePlugins: [
            "exportpdf",
            "forms",
            "smiley",
            "language",
            "iframe",
            "about",
        ],
        filebrowserImageUploadUrl:
            process.env.REACT_APP_BASE_URL +
            `/template/upload_template_image/`,
        fileTools_requestHeaders: axios.defaults.headers.common,
        uploadUrl:
            process.env.REACT_APP_BASE_URL +
            `/template/upload_template_image/`,
        contentsCss: [
            process.env.REACT_APP_BASE_URL +
            "/staticfiles/ckeditor/4.19.0/full-all/document-style.css",
        ],
        bodyClass: "document-editor",
        height: "5cm",
        allowedContent: true,
        exportPdf_options: {
            header_html: '<div class="styled">Header content</div>',
            footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
            header_and_footer_css:
                ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
            margin_left: "1cm",
            margin_right: "2cm",
            format: "A5",
            page_orientation: "landscape",
        },
        protectedSource: [/{%[\s\S]*?%}+/g, /{{[\s\S]*?}}+/g],
    };

    const loadProducts = async () => {
        const response = await adminactions.loadProducts();
        if (response) {
            setProducts(response.data.results);
        }
    }

    useEffect(() => {
        loadProducts();
    }, [])

    const onFinish = async (values) => {
        if (instance) {
            const restOfTheProducts = quoteItemData.filter((prod) => prod.id !== instance.id)
            setQuoteItemData([...restOfTheProducts, { ...values, invoice: inventoryId, item_type: "PRODUCT" }])
            reload();
        } else {
            setQuoteItemData((quoteItemData) => [...quoteItemData, { ...values, invoice: inventoryId, item_type: "PRODUCT", id: new Date().valueOf() }])
            reload();
        }
    };

    const onChange = (tax_rate_1 = 0, tax_rate_2 = 0, tax_rate_3 = 0) => {
        console.log(tax_rate_1, tax_rate_2, tax_rate_3)
        const quantity = form.getFieldValue("quantity");
        const price = form.getFieldValue("price");
        setTaxRate1(tax_rate_1)
        setTaxRate2(tax_rate_2)
        setTaxRate3(tax_rate_3)
        form.setFieldValue("line_total", ((quantity * price) + (quantity * price * tax_rate_1) + (quantity * price * tax_rate_2) + (quantity * price * tax_rate_3)));
    }

    useEffect(() => {
        if (instance) {
            setProductDescription(instance.description);
            setUnit(instance.unit || "UNITS");
            form.setFieldsValue(instance);
        }
    }, [instance])

    return (
        <Form
            form={form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
        >
            <Row style={{ margin: "15px" }}>
                <Col span={24}>
                    <div className="site-card-border-less-wrapper">
                        <div>
                            <Form.Item
                                label="Item"
                                name="item"
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Product"
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(value) => {
                                        const product = products.find(item => item.title === value);
                                        setProductDescription(product.notes)
                                        form.setFieldsValue({ "title": product.title, "description": product.notes, "hsn": product.hsn, "price": product.price, "quantity": 1, "line_total": product.price });
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={products.map((item, index) => {
                                        return { value: item.title, label: item.title }
                                    })}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: `Please Input title!`
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name="description"
                                getValueFromEvent={(event) => {
                                const data = event.editor.getData();
                                return data;
                                }}
                            >
                                {instance ? (
                                <CKEditor
                                    // data={productDescription}
                                    initData={instance.description}
                                    config={editorConfig}
                                    editorUrl={
                                    process.env.REACT_APP_BASE_URL +
                                    "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
                                    }
                                />
                                ) : (
                                <CKEditor
                                    // data={productDescription}
                                    initData={productDescription}
                                    config={editorConfig}
                                    editorUrl={
                                    process.env.REACT_APP_BASE_URL +
                                    "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
                                    }
                                />
                                )}
                            </Form.Item>
                            <Form.Item label="HSN/SAC" name="hsn">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Unit"
                                name="unit"
                                rules={[{ required: true, message: "Please select a unit!" }]}
                            >
                                <Select
                                options={[
                                    { value: "DAYS", label: "DAYS" },
                                    { value: "HOURS", label: "HOURS" },
                                    { value: "FIXED", label: "FIXED" },
                                    { value: "UNITS", label: "UNITS" },
                                ]}
                                onChange={(value) => setUnit(value)}
                                />
                            </Form.Item>
                            <Form.Item label="Price/unit" type="number" name="price">
                                <InputNumber
                                    onChange={(value) => {
                                        if (value) {
                                            onChange(taxRate1, taxRate2, taxRate3)
                                            // const quantity = form.getFieldValue("quantity");
                                            // const price = form.getFieldValue("price");
                                            // form.setFieldValue("line_total", quantity * price);
                                        }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Quantity" type="number" name="quantity">
                                <InputNumber
                                    onChange={(value) => {
                                        if (value) {
                                            onChange(taxRate1, taxRate2, taxRate3)
                                            // const quantity = form.getFieldValue("quantity");
                                            // const price = form.getFieldValue("price");
                                            // form.setFieldValue("line_total", quantity * price);
                                        }
                                    }}
                                    disabled={unit === "FIXED"}
                                />
                            </Form.Item>
                            <Form.Item label="Tax Rate 1" name="tax_rate1">
                                <Select
                                    showSearch
                                    placeholder="Select Tax Rate"
                                    allowClear
                                    onChange={(value) => {
                                        const taxRate = taxRates.find(item => item.id === value);
                                        if (taxRate) {
                                            onChange(taxRate.rate / 100, taxRate2, taxRate3)
                                            // form.setFieldValue("tax_rate1", taxRate.id);
                                        } else {
                                            onChange(0, taxRate2, taxRate3)
                                        }
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={taxRates.map((item, index) => {
                                        return { value: item.id, label: `${item.title + " " + item.rate} %` }
                                    })}
                                />
                            </Form.Item>
                            <Form.Item label="Tax Rate 2" name="tax_rate2">
                                <Select
                                    showSearch
                                    placeholder="Select Tax Rate"
                                    allowClear
                                    onChange={(value) => {
                                        const taxRate = taxRates.find(item => item.id === value);
                                        if (taxRate) {
                                            onChange(taxRate1, taxRate.rate / 100, taxRate3)
                                        } else {
                                            onChange(taxRate1, 0, taxRate3)
                                        }
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={taxRates.map((item, index) => {
                                        return { value: item.id, label: `${item.title + " " + item.rate} %` }
                                    })}
                                />
                            </Form.Item>
                            <Form.Item label="Tax Rate 3" name="tax_rate3">
                                <Select
                                    showSearch
                                    placeholder="Select Tax Rate"
                                    allowClear
                                    onChange={(value) => {
                                        const taxRate = taxRates.find(item => item.id === value);
                                        if (taxRate) {
                                            onChange(taxRate1, taxRate2, taxRate.rate / 100)
                                        } else {
                                            onChange(taxRate1, taxRate2, 0)
                                        }
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={taxRates.map((item, index) => {
                                        return { value: item.id, label: `${item.title + " " + item.rate} %` }
                                    })}
                                />
                            </Form.Item>
                            <Form.Item label="Line Total" name="line_total">
                                <Input disabled />
                            </Form.Item>
                        </div>
                        <Flex justify='flex-end'>
                            <Button block type="primary" htmlType="submit" style={{ maxWidth: 100 }} >
                                Save
                            </Button>
                        </Flex>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

export default QuoteLineForm;