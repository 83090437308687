import React, { useEffect, useState } from "react";
import { Select, Tag, Form } from "antd";

// Use
// <WANumberInput label="Email" name="wa_number" required message="Please enter waNumbers"/>

const WANumberInput = ({
  label,
  name,
  required = false,
  message = "This field is required.",
  defaultValue = [],
}) => {
  // console.log(value);
  // alert();
  const [waNumbers, setWANumbers] = useState([]);

  const handleClose = (removedEmail) => {
    const newWANumbers = waNumbers.filter(
      (wa_number) => wa_number !== removedEmail
    );
    setWANumbers(newWANumbers);
  };

  const whatsappNumberValidation = (number) => {
    // Regex pattern for validating an international phone number
    // This pattern checks for an optional '+' followed by 10 to 15 digits
    const re = /^\+?[1-9]\d{9,14}$/;
    return re.test(number);
  };
  const handleChange = (value, actionMeta) => {
    console.log(value);
    if (actionMeta.action === "remove") {
      handleClose(actionMeta.removedValue);
    } else {
      const newWANumber = value.filter((wa_number) =>
        whatsappNumberValidation(wa_number)
      );
      console.log(newWANumber);
      setWANumbers(newWANumber);
    }
  };

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: required, message: message }]}
      initialValue={defaultValue}
    >
      <Select
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Enter waNumbers"
        // value={waNumbers}
        onChange={handleChange}
        tokenSeparators={[","]}
      />
    </Form.Item>
  );
};

export default WANumberInput;
