import React, { useEffect } from "react";

const InitiatePhonePe = () => {
    useEffect(() => {
        
    }, []);

    return (
        <>Initiate</>
    )
}

export default InitiatePhonePe;