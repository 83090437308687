import { NavSider, InvoicesTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Invoices = () => {



    return (
        <InvoicesTable />
    );

}
export default Invoices;