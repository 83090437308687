import { NavSider,ContactsTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Contacts = () => {

    return (
        <ContactsTable/>
    );

}
export default Contacts;