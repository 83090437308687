import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const loadAccounts = createAsyncThunk(
  'clients/loadAccounts',
  async ({ page = 1, searchValue = '', page_size = 15 }, { rejectWithValue }) => {
    try {
      let url = `clients/client/?page=${page}&page_size=${page_size}`;
      if (searchValue) url += `&search=${searchValue}`;
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addAccounts = createAsyncThunk(
  'accounts/addAccount',
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const response = await axios.post(`/accounts/account/`, data);
      console.log("Response", response);
      return response.data;
    } catch (err) {
      console.log("Error", err);
      return rejectWithValue(err.response.data); // Send the error message to the rejected state
    }
  }
);

export const loadConstitutionTypes = createAsyncThunk(
  'types/constitution_types',
  async () => {
    try {
      let url = "accounts/constitution_types/";
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);


export const loadSalutationChoices = createAsyncThunk(
  'types/salutation_choices',
  async () => {
    try {
      let url = "accounts/salutation_choices/";
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);


export const loadAccountTypes = createAsyncThunk(
  'types/account_types',
  async () => {
    try {
      let url = "accounts/account_types/";
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);


export const loadGstTreatmentTypes = createAsyncThunk(
  'types/gst_treatment_types',
  async () => {
    try {
      let url = "accounts/gst_treatment_types/";
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    clients: [],
    loading: false,
    error: null,
    constitution_types: {},
    salutation_choices: {},
    account_types: {},
    gst_treatment_types: {},
    add_account_loading: false,
    add_account_error: null 
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.clients = action.payload;
      })
      .addCase(loadAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loadConstitutionTypes.fulfilled, (state, action) => {
        state.constitution_types = action.payload;
      })
      .addCase(loadSalutationChoices.fulfilled, (state, action) => {
        state.salutation_choices = action.payload;
      })
      .addCase(loadAccountTypes.fulfilled, (state, action) => {
        state.account_types = action.payload;
      })
      .addCase(loadGstTreatmentTypes.fulfilled, (state, action) => {
        state.gst_treatment_types = action.payload;
      })
      .addCase(addAccounts.pending, (state) => {
        state.add_account_loading = true;
        state.add_account_error = null; // Reset error on new request
      })
      .addCase(addAccounts.fulfilled, (state, action) => {
        state.add_account_loading = false;
        state.accounts.push(action.payload);
      })
      .addCase(addAccounts.rejected, (state, action) => {
        state.add_account_loading = false;
        state.add_account_error = action.payload;
      });
  },
});

export default clientsSlice.reducer;
