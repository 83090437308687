
import { NavSider, PaymentsTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Payments = () => {

    return (
        <PaymentsTable />
    );

}
export default Payments;