import { notification } from "antd";
import axios from "axios";
import { notify } from "components";
import { store, persistor } from "store";

import fileDownload from "js-file-download";

// axios.defaults.baseURL = "http://localhost:4000"
// console.log(process.env.REACT_APP_BASE_URLs)
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;


////////////////////////// USERS - Action Creator //////////////////////////

export const login = (data) => {
  return {
    type: "LOGIN",
    payload: data,
  };
};

export const logout = () => {
  return {
    type: "LOGOUT",
    payload: null,
  };
};

export const accounts = (data) => {
  return {
    type: "ACCOUNTS",
    payload: data,
  };
};

export const accounts_contacts = (data) => {
  return {
    type: "ACCOUNTS_CONTACTS",
    payload: data,
  };
};

export const vendors = (data) => {
  return {
    type: "VENDORS",
    payload: data,
  };
};

export const vendors_contacts = (data) => {
  return {
    type: "VENDORS_CONTACTS",
    payload: data,
  };
};

export const payments = (data) => {
  return {
    type: "PAYMENTS",
    payload: data,
  };
};

export const invoices = (data) => {
  return {
    type: "INVOICES",
    payload: data,
  };
};

export const quotes = (data) => {
  return {
    type: "QUOTES",
    payload: data,
  };
};

export const products = (data) => {
  return {
    type: "PRODUCTS",
    payload: data,
  };
};

export const services = (data) => {
  return {
    type: "SERVICES",
    payload: data,
  };
};

export const expenses = (data) => {
  return {
    type: "EXPENSES",
    payload: data,
  };
};

export const employees = (data) => {
  return {
    type: "EMPLOYEES",
    payload: data,
  };
};

export const users = (data) => {
  return {
    type: "USERS",
    payload: data,
  };
};

export const changeOrg = (data) => {
  return {
    type: "CHANGEORG",
    payload: data,
  };
};

//////////////Login/////////////

export const loggingIn = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/user/login/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return false;
  }
};

export const loggingOut = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/user/logout/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response for logout:", response);
      if (response.status === 204) {
        // notify("You are successfully logged out.", "success");
        dispatch(logout());
      }
    } catch (err) {
      console.log("Error", err);
      dispatch(logout());
      window.location.href = "/";
      // notify("You are successfully logged out.", "success");
    }
  };
};

export const check_token_validity = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/user/check-token-validity/");
      if (response.status === 200) {
      } else {
        dispatch(logout());
      }
    } catch (err) {
      console.log("Error", err);
      dispatch(logout());
    }
  };
};

//////////////Accounts/////////////

// export const loadAllAccounts = async() => {
//   try {
//     const response = await axios.get("/accounts/account/");
//     console.log("Response", response);
//     return response.data;
//   } catch (err) {
//     console.log("Error", err);
//     return err;
//   }
// }

export const loadAccounts = async (page, searchValue, page_size) => {
  try {
    if (!page) {
      page = 1;
    }
    if (!page_size) {
      page_size = 15;
    }
    let url = `accounts/account/?page=${page}&page_size=${page_size}&rand=${new Date().getTime()}`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const fetchAccount = async (id) => {
  try {
    const response = await axios.get(`/accounts/account/${id}/?rand=${new Date().getTime()}`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const addAccounts = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/accounts/account/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    throw err;
  }
};

export const updateAccounts = async (current, id) => {
  try {
    const response = await axios.put(`/accounts/account/${id}/`, current);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    throw err;
  }
};

export const deleteAccounts = async (id) => {
  try {
    const response = await axios.delete(`/accounts/account/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

//////////////Accounts Contacts/////////////

export const loadAccountsContacts = async () => {
  // return async (dispatch) => {
  try {
    const response = await axios.get(`accounts/clientcontact/`);
    console.log("Response", response);
    // dispatch(accounts(response.data));
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const fetchAccountsContacts = async (id) => {
  try {
    const response = await axios.get(`/accounts/client/${id}`);
    console.log("Response", response);
  } catch (err) {
    console.log("Error", err);
  }
};

export const addAccountsContacts = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/accounts/client/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const updateAccountsContacts = async (id) => {
  try {
    const response = await axios.put(`/accounts/client/${id}`);
    console.log("Response", response);
  } catch (err) {
    console.log("Error", err);
  }
};

export const deleteAccountsContacts = async (id) => {
  try {
    const response = await axios.delete(`/accounts/client/${id}`);
    console.log("Response", response);
  } catch (err) {
    console.log("Error", err);
  }
};

//////////////Vendors/////////////

export const loadVendors = async (page, searchValue, page_size) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    if (!page_size) {
      page_size = 15;
    }
    let url = `vendors/vendor/?page=${page}&page_size=${page_size}`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const fetchVendors = async (id) => {
  try {
    const response = await axios.get(`/vendors/vendor/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const addVendors = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/vendors/vendor/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const updateVendors = async (current, id) => {
  try {
    const response = await axios.put(`/vendors/vendor/${id}/`, current);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteVendors = async (id) => {
  try {
    const response = await axios.delete(`/vendors/vendor/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
  }
};

//////////////Vendors Contacts/////////////

export const loadVendorsContacts = async () => {
  // return async (dispatch) => {
  try {
    const response = await axios.get(`vendors/vendor/`);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const fetchVendorsContacts = async (id) => {
  try {
    const response = await axios.get(`/vendors/vendor/${id}`);
    console.log("Response", response);
  } catch (err) {
    console.log("Error", err);
  }
};

export const addVendorsContacts = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/vendors/vendor/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const updateVendorsContacts = async (id) => {
  try {
    const response = await axios.put(`/vendors/vendor/${id}`);
    console.log("Response", response);
  } catch (err) {
    console.log("Error", err);
  }
};

export const deleteVendorsContacts = async (id) => {
  try {
    const response = await axios.delete(`/vendors/vendor/${id}`);
    console.log("Response", response);
  } catch (err) {
    console.log("Error", err);
  }
};

//////////////Invoices/////////////

export const loadInvoices = async (page, searchValue) => {
  const organization = null;
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `invoices/invoices/?page=${page}&page_size=100&rand=${new Date().getTime()}`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    if (organization) {
      url += `&organization=${organization}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const loadInvoiceStatuses = async () => {
  try {
    const response = await axios.get(`/invoices/invoice_status/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Unable to load Invoice Statuses");
  }
};

export const loadBankAccounts = async (org) => {
  try {
    const response = await axios.get(`/organization/org-bank-accounts/?organization_id=${org}`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Unable to load Bank Accounts");
  }
}

export const loadInvoice = async (id) => {
  try {
    const response = await axios.get(`/invoices/invoices/${id}/?rand=${new Date().getTime()}`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Invoice not loaded!", JSON.stringify(err.response.data));
    // return err;
  }
};

export const invoiceRender = async (id, msgTemplate) => {
  try {
    const response = await axios.post(
      `/invoices/invoices/${id}/render/?rand=${new Date().getTime()}`,
      { template: msgTemplate }
    );
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Invoice not loaded!", JSON.stringify(err.response.data));
    // return err;
  }
};

export const invoicePdf = async (id) => {
  try {
    const response = await axios.post(
      `/invoices/invoices/${id}/pdf/?rand=${new Date().getTime()}`,
      {},
      {
        responseType: "blob",
      }
    );
    console.log("Response", response);
    if (response.status === 200) {
      fileDownload(response.data, "template.pdf");
    }
    // return response;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Invoice not loaded!", JSON.stringify(err.response.data));
    // return err;
  }
};

export const addInvoices = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/invoices/invoices/?rand=${new Date().getTime()}`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    notify("error", "Invoice not created!", JSON.stringify(err.response.data));
    // return err;
  }
};

export const updateInvoices = async (data, id) => {
  try {
    console.log("DATA: ", data);
    const response = await axios.put(`/invoices/invoices/${id}/?rand=${new Date().getTime()}`, data);
    console.log("Response", response);
    // notify("success", "Invoice saved successfully!", null);
    return response;
  } catch (err) {
    console.log("Error", err);
    // notify("error", "Invoice not saved!", JSON.stringify(err.response.data));
    return err;
  }
};

export const deleteInvoices = async (id) => {
  try {
    const response = await axios.delete(`/invoices/invoices/${id}/?rand=${new Date().getTime()}`);
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
  }
};

export const addDocumentsToInvoice = async (id, doc_ids) => {
  try {
    const response = await axios.post(
      `/invoices/invoices/${id}/adddocsfromdocuments/`,
      { documents: doc_ids }
    );
    return response.data;
  } catch (err) {
    return false;
  }
};

export const removeDocumentFromInvoice = async (id, doc_id) => {
  try {
    const response = await axios.post(`/invoices/invoices/${id}/removeDoc/`, {
      documents: [doc_id],
    });
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return false;
  }
};

export const sendInvoice = async (id, data) => {
  try {
    const response = await axios.post(
      `/invoices/invoices/${id}/sendInvoice/`,
      data
    );
    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    throw err;
  }
};

//////////////Payments/////////////

export const loadPayments = async (page, searchValue, organization) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `invoices/payments/?page=${page}&page_size=100&rand=${new Date().getTime()}`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    if (organization) {
      url += `&organization=${organization}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const loadPayment = async (id) => {
  try {
    const response = await axios.get(`/invoices/payments/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Invoice not loaded!", JSON.stringify(err.response.data));
    // return err;
  }
};

export const fetchPayments = async (id) => {
  try {
    const response = await axios.get(`/invoices/payments/${id}`);
    console.log("Response", response);
  } catch (err) {
    console.log("Error", err);
  }
};

export const addPayments = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/invoices/payments/`, data);
    console.log("Response", response);
    notify("success", "Payment saved successfully!", null);
    return response;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Payment save failed!", null);
    return undefined;
  }
};

export const updatePayments = async (data, id) => {
  try {
    console.log("DATA: ", data);
    const response = await axios.put(`/invoices/payments/${id}/`, data);
    console.log("Response", response);
    notify("success", "Payment saved successfully!", null);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Payment not saved!", JSON.stringify(err.response.data));
    return err;
  }
};

export const deletePayments = async (id) => {
  try {
    const response = await axios.delete(`/payments/payment/${id}`);
    console.log("Response", response);
  } catch (err) {
    console.log("Error", err);
  }
};

export const update_payment_statuses = async () => {
  try {
    const response = await axios.post(`/payment/py/update_payment_statuses/`);
    console.log("Response", response);
    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    console.log("Error", err);
  }
};

//////////////Products/////////////

export const loadProducts = async (page, searchValue) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }

    let url = `/product/product/?page=${page}&page_size=100&rand=${new Date().getTime()}`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const fetchProducts = async (id) => {
  try {
    const response = await axios.get(`/product/product/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Erro", err);
    return err;
  }
};

export const addProducts = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/product/product/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const updateProducts = async (current, id) => {
  try {
    const response = await axios.put(`/product/product/${id}/`, current);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteProducts = async (id) => {
  try {
    const response = await axios.delete(`/product/product/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

//////////////Services/////////////

export const loadServices = async (page, searchValue) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/product/service/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const fetchServices = async (id) => {
  try {
    const response = await axios.get(`/product/service/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Erro", err);
    return err;
  }
};

export const addServices = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/product/service/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const updateServices = async (current, id) => {
  try {
    const response = await axios.put(`/product/service/${id}/`, current);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteServices = async (id) => {
  try {
    const response = await axios.delete(`/product/service/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

//////////////Expenses/////////////

export const loadExpenses = async (page, searchValue) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/accounting/expense/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const fetchExpenses = async (id) => {
  try {
    const response = await axios.get(`/accounting/expense/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Erro", err);
    return err;
  }
};

export const addExpenses = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/accounting/expense/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const updateExpenses = async (current, id) => {
  try {
    const response = await axios.put(`/accounting/expense/${id}/`, current);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteExpenses = async (id) => {
  try {
    const response = await axios.delete(`/accounting/expense/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

//////////////Employees/////////////

export const loadEmployees = async (page, searchValue) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/user/employee/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const retrieveEmployee = async (id) => {
  try {
    const response = await axios.get(`/user/employee/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Erro", err);
    return err;
  }
};

export const addEmployees = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/user/employee/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const updateEmployees = async (current, id) => {
  try {
    const response = await axios.put(`/user/employee/${id}/`, current);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteEmployees = async (id) => {
  try {
    const response = await axios.delete(`/user/employee/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

//////////////Contacts/////////////

export const loadWATemplates = async (page, searchValue) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/whatsapp_api/whatsapp-templates/?rand=${new Date().getTime()}`;
    // if (searchValue) {
    //   url += `&search=${searchValue}`;
    // }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const refreshWATemplates = async (page, searchValue) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/whatsapp_api/refresh-templates/`;
    if (searchValue) {
      url += `&search=${searchValue}&rand=${new Date().getTime()}`;
    } else {
      url += `?rand=${new Date().getTime()}`;
    }
    const response = await axios.post(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const loadContacts = async (page, searchValue) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/contact/contacts/?page=${page}&page_size=100&rand=${new Date().getTime()}`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const loadWAContacts = async (page, searchValue) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/whatsapp_api/whatsapp-contacts/?page=${page}`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    url += `&rand=${new Date().getTime()}`;
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const loadMessagesForContact = async (contact) => {
  try {
    // if(!page){
    //   page = 1;
    // }

    const mark_read_response = await axios.post(
      `/whatsapp_api/whatsapp-contacts/mark_read/`,
      { wa_number: contact }
    );

    let url = `/whatsapp_api/whatsapp-messages/?contact__wa_id=${contact}&rand=${new Date().getTime()}`;
    // if (searchValue) {
    //   url += `&search=${searchValue}`;
    // }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const sendMessage = async (contact, type, text) => {
  try {
    // if(!page){
    //   page = 1;
    // }
    let url = `/whatsapp_api/send-message/`;
    // if (searchValue) {
    //   url += `&search=${searchValue}`;
    // }
    console.log(contact);
    const response = await axios.post(url, {
      contact: contact,
      type: type,
      text: text,
    });
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const sendTemplateMessage = async (values) => {
  try {
    // if(!page){
    //   page = 1;
    // }
    let url = `/whatsapp_api/send-template-message/`;
    // if (searchValue) {
    //   url += `&search=${searchValue}`;
    // // }
    console.log(values);
    // console.log(values.template.split("##"))
    const response = await axios.post(url, {
      wa_numbers: values.wa_numbers,
      template_id: values.template,
    });
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const retrieveContact = async (id) => {
  try {
    const response = await axios.get(`/contact/contacts/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Erro", err);
    return err;
  }
};

export const addContacts = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/contact/contacts/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return undefined;
  }
};

export const updateContacts = async (current, id) => {
  try {
    const response = await axios.put(`/contact/contacts/${id}/`, current);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteContacts = async (id) => {
  try {
    const response = await axios.delete(`/contact/contacts/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

////////////// Leads ///////////
export const loadLeads = async (page, searchValue) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/lead/leads/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}&rand=${new Date().getTime()}`;
    } else {
      url += `&rand=${new Date().getTime()}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const retrieveLead = async (id) => {
  try {
    const response = await axios.get(`/lead/leads/${id}/?rand=${new Date().getTime()}`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Erro", err);
    return err;
  }
};

export const addLeads = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/lead/leads/`, data);
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    return undefined;
  }
};

export const updateLeads = async (current, id) => {
  try {
    const response = await axios.put(`/lead/leads/${id}/`, current);
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteLeads = async (id) => {
  try {
    const response = await axios.delete(`/lead/leads/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const removeDocumentFromLeads = async (id, doc_id) => {
  try {
    const response = await axios.post(`/lead/leads/${id}/removeAttachment/`, {
      documents: [doc_id],
    });
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return false;
  }
};

// Lead Activity
export const addLeadActivity = async (data) => {
  try {
    const response = await axios.post(`/lead/leadactivity/`, data);
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    return undefined;
  }
};

export const updateLeadActivity = async (current, id) => {
  try {
    const response = await axios.put(`/lead/leadactivity/${id}/`, current);
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteLeadActivity = async (id) => {
  try {
    const response = await axios.delete(`/lead/leadactivity/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

// Lead Task
export const addLeadTask = async (data) => {
  try {
    const response = await axios.post(`/lead/leadtask/`, data);
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    return undefined;
  }
};

export const updateLeadTask = async (current, id) => {
  try {
    const response = await axios.put(`/lead/leadtask/${id}/`, current);
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteLeadTask = async (id) => {
  try {
    const response = await axios.delete(`/lead/leadtask/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

//////////////Users/////////////

export const loadUsers = async (page, searchValue) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/user/user/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const fetchUsers = async (id) => {
  try {
    const response = await axios.get(`/user/user/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Erro", err);
    return err;
  }
};

export const addUsers = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/user/user/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const updateUsers = async (current, id) => {
  try {
    const response = await axios.put(`/user/user/${id}/`, current);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteUsers = async (id) => {
  try {
    const response = await axios.delete(`/user/user/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const loadOrganizations = async (id) => {
  try {
    const response = await axios.get(`/organization/organization/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify("error", "Error is fetching organizations!", null);
    console.log(err);
    return false;
    return err;
  }
};

export const loadDocuments = async (page, searchValue, page_size) => {
  try {
    if (!page) {
      page = 1;
    }
    if (!page_size) {
      page_size = 15;
    }
    let url = `/document/document/?page=${page}&page_size=${page_size}`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return err;
  }
};

export const loadDocument = async (id) => {
  try {
    const response = await axios.get(`/document/document/${id}/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const updateDocument = async (current, id) => {
  try {
    const response = await axios.put(`/document/document/${id}/`, current);
    console.log("Response", response);
    notify("success", "Document Deleted Successfully!", null);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", err, null);
    return err;
  }
};

export const deleteDocument = async (id) => {
  try {
    const response = await axios.delete(`/document/document/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const loadTaxRates = async () => {
  try {
    const response = await axios.get(`/invoices/taxrates/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const loadPaymentModes = async () => {
  try {
    const response = await axios.get(`/invoices/payment_modes/`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    return err;
  }
};

export const loadPaymentStatuses = async () => {
  try {
    const response = await axios.get(`/invoices/payment_status/`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    return err;
  }
};

export const loadPaymentTypes = async () => {
  try {
    const response = await axios.get(`/invoices/payment_types/`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    return err;
  }
};

//////////////Quotes/////////////

export const loadQuotes = async (page, searchValue, organization) => {
  // return async (dispatch) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `quotes/quotes/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    if (organization) {
      url += `&organization=${organization}`;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    // dispatch(vendors(response.data));
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
  // }
};

export const loadQuoteStatuses = async () => {
  try {
    const response = await axios.get(`/quotes/quote_status/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Unable to load Quote Statuses");
  }
};

export const loadDeliveryTimelineUnits = async () => {
  try {
    const response = await axios.get(`/quotes/delivery_timeline_unit/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Unable to load Quote Statuses");
  }
};

export const loadQuote = async (id) => {
  try {
    const response = await axios.get(`/quotes/quotes/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Quote not loaded!", JSON.stringify(err.response.data));
    // return err;
  }
};

export const quoteRender = async (id, msgTemplate) => {
  try {
    const response = await axios.post(
      `/quotes/quotes/${id}/render/?rand=${new Date().getTime()}`,
      { template: msgTemplate }
    );
    console.log("Response", response);
    return response;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Quote not loaded!", JSON.stringify(err.response.data));
    // return err;
  }
};

export const downloadQuote = async (id, is_preview, unique_name) => {
  try {
    const data = {
      is_preview,
      unique_name,
    };
    const response = await axios.post(
      `/quotes/quotes/${id}/quoteDownload/`,
      data,
      {
        responseType: "blob",
      }
    );
    console.log("Response", response);
    if (response.status === 200) {
      return response.data;
    }
    return undefined;
  } catch (err) {
    console.log("Error: ", err);
    return undefined;
  }
};

export const addQuotes = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/quotes/quotes/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    notify("error", "Quote not created!", JSON.stringify(err.response.data));
    // return err;
  }
};

export const updateQuotes = async (data, id) => {
  try {
    console.log("DATA: ", data);
    const response = await axios.put(`/quotes/quotes/${id}/`, data);
    console.log("Response", response);
    notify("success", "Quote saved successfully!", null);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", "Quote not saved!", JSON.stringify(err.response.data));
    return err;
  }
};

export const deleteQuotes = async (id) => {
  try {
    const response = await axios.delete(`/quotes/quotes/${id}/`);
    console.log("Response", response);
  } catch (err) {
    console.log("Error", err);
  }
};

export const addDocumentsToQuote = async (id, doc_ids) => {
  try {
    const response = await axios.post(
      `/quotes/quotes/${id}/adddocsfromdocuments/`,
      { documents: doc_ids }
    );
    return response.data;
  } catch (err) {
    return false;
  }
};

export const removeDocumentFromQuote = async (id, doc_id) => {
  try {
    const response = await axios.post(`/quotes/quotes/${id}/removeDoc/`, {
      documents: [doc_id],
    });
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return false;
  }
};

export const sendQuote = async (id, data) => {
  try {
    const response = await axios.post(`/quotes/quotes/${id}/sendQuote/`, data);
    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    throw err;
  }
};

export const addDocumentsToEmployee = async (id, doc_ids) => {
  try {
    const response = await axios.post(
      `/user/employee/${id}/adddocsfromdocuments/`,
      { documents: doc_ids }
    );
    return response.data;
  } catch (err) {
    return false;
  }
};

export const removeDocumentFromEmployee = async (id, doc_id) => {
  try {
    const response = await axios.post(`/user/employee/${id}/removeDoc/`, {
      documents: [doc_id],
    });
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return false;
  }
};

export const downloadInvoice = async (id, is_preview, unique_name) => {
  try {
    const data = {
      is_preview,
      unique_name,
    };
    const response = await axios.post(
      `/invoices/invoices/${id}/invoiceDownload/`,
      data,
      {
        responseType: "blob",
      }
    );
    console.log("Response", response);
    if (response.status === 200) {
      return response.data;
    }
    return undefined;
  } catch (err) {
    console.log("Error: ", err);
    return undefined;
  }
};

// Asset files //

export const loadAssetFiles = async (page, searchValue) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/assetfile/assetfile/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return err;
  }
};

export const loadAssetFile = async (id) => {
  try {
    const response = await axios.get(`/assetfile/assetfile/${id}/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const updateAssetFile = async (current, id) => {
  try {
    const response = await axios.put(`/assetfile/assetfile/${id}/`, current);
    console.log("Response", response);
    notify("success", "AssetFile Deleted Successfully!", null);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    notify("error", err, null);
    return err;
  }
};

export const deleteAssetFile = async (id) => {
  try {
    const response = await axios.delete(`/assetfile/assetfile/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

//////////////////////// System Monitoring /////////////////////////////

export const loadAllMonitors = async (page, searchValue) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/uptime/monitors/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const loadMonitoringStats = async () => {
  try {
    const response = await axios.get(`/uptime/monitoring_stats/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const fetchMonitor = async (id) => {
  try {
    const response = await axios.get(`/uptime/monitors/${id}/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const addMonitor = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`/uptime/monitors/`, data);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const updateMonitor = async (current, id) => {
  try {
    const response = await axios.put(`/uptime/monitors/${id}/`, current);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const pauseMonitor = async (id) => {
  try {
    const response = await axios.get(`/uptime/monitors/${id}/pause/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

export const deleteMonitor = async (id) => {
  try {
    const response = await axios.delete(`/uptime/monitors/${id}/`);
    console.log("Response", response);
    return true;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

/////////////////////////Dashboard/////////////////////////////

export const fetchDashboardData = async () => {
  try {
    const response = await axios.post(`/dashboard/`);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Error", err);
    return err;
  }
};

//////////////////////// Tenders /////////////////////////////
export const loadTenders = async (page, perPage, searchValue) => {
  try {
    let url = `/sales/tenders/?page=${page}&perpage=${perPage}`;
    if (searchValue) {
      url += "&search=" + searchValue;
    }
    const response = await axios.get(url);
    console.log("Response", response);
    return response.data;
  } catch (err) {
    console.log("Erro", err);
    return err;
  }
};

// Templates

////////////////////////// TEMPLATE - Actions //////////////////////////

export const loadTemplate = async (templateId) => {
  try {
    const response = await axios.get("/template/" + templateId + "/");
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be fetched!");
  }
};

export const templateToPDF = async (templateId) => {
  try {
    const response = await axios.post(
      "/template/" + templateId + "/print_pdf/",
      {},
      {
        responseType: "blob",
      }
    );
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      fileDownload(response.data, "template.pdf");
      // return response.data;
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be downloaded!");
  }
};

export const templateToPDFPreview = async (templateId) => {
  try {
    const response = await axios.post(
      "/template/" + templateId + "/print_pdf/",
      {},
      {
        responseType: "blob",
      }
    );
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      // fileDownload(response.data, "template.html");
      return response.data;
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be downloaded!");
  }
};

export const loadTemplates = async (page, searchValue) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/template/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    url += `&rand=${new Date().getTime()}`;
    const response = await axios.get(url);
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Templates fetched successfully!", "success");
      return response;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "There was a problem loading Templates!");
  }
};

export const loadTemplatesWithType = async (type) => {
  try {
    let url = `/template/?name__startswith=${type}`;
    url += `&rand=${new Date().getTime()}`;
    const response = await axios.get(url);
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Templates fetched successfully!", "success");
      return response;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "There was a problem loading Templates!");
  }
};

export const createTemplate = async (data) => {
  try {
    const response = await axios.post(`/template/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 201) {
      notify("success", "Your Template has been saved!");
      return response;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be saved!");
  }
};

export const updateTemplate = async (templateId, data) => {
  try {
    const response = await axios.put(`/template/${templateId}/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      notify("success", "Updated the template");
      return response;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be updated!");
  }
};

export const deleteTemplate = async (id) => {
  try {
    const response = await axios.delete(`/template/${id}/`);
    console.log("Response", response);
    notify("success", "Template deleted successfully");
    return "deleted";
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template deleted Error");
  }
};

export const loadTemplateTypes = async () => {
  try {
    const response = await axios.get(`/template/template_types/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Templates fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "There was a problem loading Template Types!");
  }
};
