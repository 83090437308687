import React from "react";
import { Dropdown, Flex, Space } from "antd";
import { MdOutlineSmsFailed } from "react-icons/md";
import moment from 'moment';
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdReply } from "react-icons/md";


const Message = ({ message, activeItemId, changeColor, scrollToItem }) => {

    const items = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    Message Info
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    Reply
                </a>
            ),
            //   icon: <SmileOutlined />,
        }
    ];
    return (
        <Flex justify={message.from_number === "918108934661" ? 'flex-end' : 'flex-start'} style={{ margin: "0px 5px", backgroundColor: activeItemId === message.wa_message_id ? "rgba(142, 212, 195, 0.5)" : "" , width: "100%"}}>
            <div
                style={{
                    margin: "5px 0px",
                    padding: "10px 10px",
                    backgroundColor: message.from_number === "918108934661" ? "#D9FDD3" : "rgba(255, 255, 255, 1)",
                }}
                // loading={loading}
                size='small'
            >
                <a href={`#${message.wa_message_id}`}></a>
                <Flex justify='flex-start' vertical style={{ position: 'relative', paddingRight: 45, maxWidth: "40vw" }}>
                    <div style={{ position: "absolute", right: 10 }}>
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <BsThreeDotsVertical color="#ccc" className="cursor-pointer" />
                                </Space>
                            </a>
                        </Dropdown>

                    </div>
                    <div style={{ display: message?.template ? "flex" : "none" }}>
                        <div>
                            <h4><b>{message?.template_data?.components.find(item => item.type === "HEADER").text}</b></h4>
                            <pre style={{ whiteSpace: "pre-wrap" }}>
                                {message?.template_data?.components.find(item => item.type === "BODY").text}
                            </pre>
                            <pre style={{ whiteSpace: "pre-wrap" }}>
                                {message?.template_data?.components.find(item => item.type === "FOOTER").text}
                            </pre>
                        </div>
                    </div>
                    <div style={{ display: message?.context ? "flex" : "none" }}>
                        <a href={`#${message?.context?.id}`}><MdReply className="cursor-pointer" style={{ marginRight: 20 }} onClick={() => {
                            changeColor(message?.context?.id);
                            scrollToItem(message?.context?.id);
                        }} /></a>{message?.button?.text}
                    </div>
                    <div>
                        {message.text && message.text.body}
                    </div>
                    <div style={{ bottom: -10, right: 10 }}>
                        <small style={{ color: "#777" }}>{moment(message.created_at).format('YYYY-MM-DD HH:mm:ss')}</small><small style={{ color: "#ff0000", display: message.status === "failed" ? "block" : "none" }}><MdOutlineSmsFailed /></small>
                    </div>
                </Flex>
            </div>
        </Flex>
    );
}

export default Message;