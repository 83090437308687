import { notification } from "antd";

export const notify = (type, msg, description=null) => {
  switch (type) {
    case "success":
      notification.success({ message: `${msg}`, description: description });
      break;
    case "error":
      notification.error({ message: `${msg}`, description: description });
      break;
    case "warning":
      notification.warning({ message: `${msg}`, description: description });
      break;
    case "info":
      notification.info({ message: `${msg}`, description: description });
      break;
    default:
      notification.open({ message: `${msg}`, description: description });
  }
  // alert();
};
notification.config({
  placement: "topLeft",
  duration: 3,
  style: "z-index: 9999"
});
