import { NavSider,UsersTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Users = () => {



    return (
        <UsersTable/>
    );

}
export default Users;