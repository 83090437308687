import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const loadPayments = createAsyncThunk(
  'payments/loadPayments',
  async ({ page = 1, searchValue = '', organization = null }, { rejectWithValue }) => {
    try {
      let url = `invoices/payments/?page=${page}&page_size=100`;
      if (searchValue) url += `&search=${searchValue}`;
      if (organization) url += `&organization=${organization}`;
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    payments: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPayments.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = action.payload;
      })
      .addCase(loadPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default paymentsSlice.reducer;
