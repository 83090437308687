import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from 'ckeditor4-react';

import * as actions from "api/api";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import { diet } from "assets";
import PdfPreviewModal from "components/PdfPreviewModal/PdfPreviewModal";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Template = () => {
    const [openCreateModal, setOpenCreateModal] = useState(null);
    const [currentTemplateType, setCurrentTemplateType] = useState(null);
    const [templateInfo, setTemplateInfo] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfPreviewData, setPdfPreviewData] = useState(null);

    const [thisTemplate, setThisTemplate] = useState(null);
    const form = useRef(null);
    const client = useSelector(state => state.authReducer.client);

    const margin_top_ref = useRef();
    const margin_bottom_ref = useRef();
    const margin_left_ref = useRef();
    const margin_right_ref = useRef();

    const page_size_ref = useRef();
    const page_orientation_ref = useRef();
    const page_number_format_ref = useRef();
    const page_number_position_ref = useRef();
    const page_border_ref = useRef();

    const user = useSelector((state) => state.authReducer.user);
    const template_types = [
        { key: "INVOICE", value: "INVOICE", alias: "Invoice" },
        { key: "REPORT", value: "REPORT", alias: "Report" },
        { key: "ADMISSION_CARD", value: "ADMISSION CARD", alias: "Admission Card" },
    ];

    const fetchTemplates = async () => {
        const response = await actions.loadTemplates();
        console.log(response);
        if (response) {
            setTemplates(response);
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    // Letter - 8.5in x 11in (21.6cm x 27.9cm)
    // Legal - 8.5in x 14in (21.6cm x 35.6cm)
    // Tabloid - 11in x 17in (27.9cm x 43.2cm)
    // Ledger - 17in x 11in (43.2cm x 27.9cm)
    // A0 - 33.1in x 46.8in (84.1cm x 118.9cm)
    // A1 - 23.4in x 33.1in (59.4cm x 84.1cm)
    // A2 - 16.54in x 23.4in (42.0cm x 59.4cm)
    // A3 - 11.7in x 16.54in (29.7cm x 42.0cm)
    // A4 - 8.27in x 11.7in (21.0cm x 29.7cm)
    // A5 - 5.83in x 8.27in (14.8cm x 21.0cm)
    // A6 - 4.13in x 5.83in (10.5cm x 14.8cm)

    const page_sizes = [
        {
            id: "A3",
            width: 29.7,
            height: 42.0
        },
        {
            id: "A4",
            width: 21,
            height: 29.7
        }, {
            id: "A5",
            width: 14.8,
            height: 21.0
        }, {
            id: "A6",
            width: 10.5,
            height: 14.8
        },
        {
            id: "Letter",
            width: 21.6,
            height: 27.9
        },
        // {
        //     id: "Legal",
        //     width: 21.6,
        //     height: 35.6
        // }, {
        //     id: "Tabloid",
        //     width: 27.9,
        //     height: 43.2
        // }, {
        //     id: "Ledger",
        //     width: 43.2,
        //     height: 27.9
        // },
        // {
        //     id: "A0",
        //     width: 84.1,
        //     height: 118.9
        // }, {
        //     id: "A1",
        //     width: 59.4,
        //     height: 84.1
        // }, {
        //     id: "A2",
        //     width: 42.0,
        //     height: 59.4
        // }
    ]

    const page_orientation = [
        {
            id: "portrait",
            label: "Portrait"
        },
        {
            id: "landscape",
            label: "Landscape"
        }
    ]

    const page_number_position = [
        {
            id: "top-left",
            label: "Top Left",
        },
        {
            id: "top-center",
            label: "Top Center",
        },
        {
            id: "top-right",
            label: "Top Right",
        },
        {
            id: "bottom-left",
            label: "Bottom Left",
        },
        {
            id: "bottom-center",
            label: "Bottom Center",
        },
        {
            id: "bottom-right",
            label: "Bottom Right",
        }
    ]

    useEffect(() => {
        fetchTemplates(templates);
    }, []);

    useEffect(() => {
        fetchTemplates(templates);
    }, [openCreateModal])

    const resetPageFormatting = () => {
        margin_top_ref.current.value = 12;
        margin_bottom_ref.current.value = 12;
        margin_left_ref.current.value = 18;
        margin_right_ref.current.value = 12;
        page_size_ref.current.value = "A4";
        page_orientation_ref.current.value = "portrait";
        page_number_format_ref.current.value = '"Page " counter(page) " of " counter(pages)';
        page_number_position_ref.current.value = "bottom-center";
        page_border_ref.current.value = "1px solid #ccc";
    }

    const handleTemplateSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        formData.append("created_by", user.id)
        formData.append("modified_by", user.id)
        formData.append("template_type", currentTemplateType.key);
        formData.append("content", templateInfo);
        const response = await actions.createTemplate(formData);
        if (response) {
            // actions.notify("Successfull!", "success");
            // setOpenCreateModal(false);
        }
    };

    const handleTemplateUpdate = async (e) => {
        console.log(thisTemplate.id)
        e.preventDefault();
        const formData = new FormData(form.current);
        formData.append("created_by", user.id)
        formData.append("modified_by", user.id)
        formData.append('template_type', currentTemplateType.key);
        formData.append('content', templateInfo);
        const response = await actions.updateTemplate(thisTemplate.id, formData);
        if (response) {
            // actions.notify("Successfull!", "success");
            // setThisTemplate(null);
            // setOpenCreateModal(false);
        }
    };

    const downloadPdf = async () => {
        await actions.templateToPDF(thisTemplate?.id);
    }

    const previewPdf = async () => {
        console.log("hello");
        const response = await actions.templateToPDFPreview(thisTemplate?.id);
        if (response) {
            setPdfPreviewData(response);
        }
        setPreviewPdfVisible(true);
    }

    const editorConfig = {
        extraPlugins: ["tableresize", "uploadimage", "createpdf"],
        removePlugins: ["exportpdf", "forms", "smiley", "language", "iframe", "about"],
        // filebrowserBrowseUrl: 'http://localhost:4000/media/24b358cf-8e56-4b03-9843-29fabf589b8d/template/home-advt_jzx7KBU.jpeg',
        filebrowserImageUploadUrl: process.env.REACT_APP_BASE_URL + `/template/upload_template_image/`,
        fileTools_requestHeaders: axios.defaults.headers.common,
        uploadUrl: process.env.REACT_APP_BASE_URL + `/template/upload_template_image/`,
        contentsCss: [
            // "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css",
            process.env.REACT_APP_BASE_URL + "/staticfiles/ckeditor/4.19.0/full-all/document-style.css"
        ],
        bodyClass: 'document-editor',
        // height: 900,
        // width: 1000,
        height: "15.8cm",
        width: "21cm",
        // extraPlugins: [
        //     'timestamp'
        // ]
        // exportPdf_options: {
        //     header_html: '<div class="styled">This is the Header</div>',
        //     footer_html: '<div class="styled-counter"><span class="date></span></div>',
        //     header_and_footer_css: '.styled { font-weight: bold; padding: 10px; display: flex; color: red; } .styled-counter {font-size: 10px; color: red; }',
        //     margin_top: '2cm',
        //     margin_bottom: '10cm',
        // }
        // exportPdf_options: {
        //     format: "A3"
        // }
        exportPdf_options: {
            header_html: '<div class="styled">Header content</div>',
            footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
            header_and_footer_css: '.styled { font-weight: bold; padding: 10px; text-align: center; color: red; }',
            margin_left: '1cm',
            margin_right: '2cm',
            format: "A5",
            page_orientation: "landscape"
        }
    }

    return <div>test</div>

    // return (
    //     <Accordion className="m-4 mt-5 pt-5" defaultActiveKey="0">
    //         {template_types.map((template_type, index) => {
    //             return (
    //                 <Accordion.Item eventKey={index.toString()}>
    //                     <Accordion.Header>{template_type?.value}</Accordion.Header>
    //                     <Accordion.Body>
    //                         <div className="d-flex">
    //                             {templates?.filter(item => item.template_type === template_type?.key).map((template) => {
    //                                 return (
    //                                     <div key={template.id}
    //                                         onClick={() => {
    //                                             setCurrentTemplateType(template_type);
    //                                             setThisTemplate(template);
    //                                             console.log(template);
    //                                             setOpenCreateModal(true);
    //                                         }}
    //                                     >
    //                                         <Card
    //                                             className="mx-3 my-3 d-flex justify-content-center align-items-center cursor-pointer shadow"
    //                                             style={styles.cardShadow}
    //                                             onClick={() => {
    //                                                 setCurrentTemplateType(template_type);
    //                                                 setThisTemplate(template);
    //                                                 console.log(template);
    //                                                 setOpenCreateModal(true);
    //                                             }}
    //                                         >
    //                                             {/* <iframe
    //                                             className="position-absolute"
    //                                             style={{ top: 0, left: 0, borderRadius: 5 }}
    //                                             src={`${axios.defaults.baseURL}template/view/${template?.id}`}
    //                                             width="100%"
    //                                             height="100%"
    //                                         /> */}
    //                                         </Card>
    //                                         <p className="h7 text-center">{template.name}</p>
    //                                     </div>
    //                                 );
    //                             })}
    //                             <Card
    //                                 className="mx-5 my-3 d-flex justify-content-center align-items-center cursor-pointer shadow"
    //                                 style={styles.cardShadow}
    //                                 onClick={() => {
    //                                     setCurrentTemplateType(template_type);
    //                                     setThisTemplate(null);
    //                                     setOpenCreateModal(true);
    //                                 }}
    //                             >
    //                                 <AiOutlinePlus size={30} />
    //                             </Card>
    //                         </div>
    //                     </Accordion.Body>
    //                 </Accordion.Item>
    //             )
    //         })}
    //         <div
    //             className={`overflow-auto position-fixed w-100 h-100 fixed-top py-5 ${openCreateModal ? "" : "d-none"}`}
    //             style={{ backgroundColor: "rgb(241, 243, 250)" }}
    //         // show={openCreateModal}
    //         // onHide={() => setOpenCreateModal(false)}
    //         // fullscreen={true}
    //         // centered
    //         // onExited={() => {
    //         //     setOpenCreateModal(false);
    //         // }}
    //         // onClick={(e) => e.preventDefault()}
    //         >
    //             <div className="d-flex flex-row justify-content-center">
    //                 <h4 className="mb-4">Create Your {currentTemplateType?.alias} Template</h4>
    //             </div>
    //             <div className="removeButtonRight position-absolute">
    //                 <AiOutlineClose className="cursor-pointer" size={20} onClick={() => setOpenCreateModal(false)} />
    //             </div>
    //             <Form
    //                 className=""
    //                 ref={form}
    //                 onSubmit={(e) => {
    //                     if (thisTemplate) {
    //                         handleTemplateUpdate(e);
    //                     } else {
    //                         handleTemplateSubmit(e);
    //                     }
    //                 }}
    //             >
    //                 <Row>
    //                     <Col sm={4} className="d-flex justify-content-end">
    //                         <div className="border bg-white">

    //                             <Container className="m-2" style={{ width: 400 }}>
    //                                 <h5 className="text-primary">Meta Data</h5>
    //                                 <Form.Group className="mt-3 mb-5">
    //                                     <Form.Label>
    //                                         Enter the Name of the Template
    //                                     </Form.Label>
    //                                     <Form.Control
    //                                         required
    //                                         size="sm"
    //                                         name="name"
    //                                         type="text"
    //                                         placeholder="Enter Template Name"
    //                                         defaultValue={thisTemplate?.id ? thisTemplate.name : ""}
    //                                     />
    //                                     <Form.Label>
    //                                         Description
    //                                     </Form.Label>
    //                                     <Form.Control
    //                                         as="textarea"
    //                                         size="sm"
    //                                         name="description"
    //                                         placeholder="Description"
    //                                         defaultValue={thisTemplate?.id ? thisTemplate.description : ""}
    //                                     />
    //                                 </Form.Group>
    //                                 <h5 className="text-primary">Page Format</h5>
    //                                 <Form.Group className="my-2">
    //                                     <Form.Label>
    //                                         Margin (in mm)
    //                                     </Form.Label>
    //                                     <div className="d-flex">
    //                                         <div>
    //                                             <Form.Label>
    //                                                 <small>Top</small>
    //                                             </Form.Label>
    //                                             <Form.Control
    //                                                 ref={margin_top_ref}
    //                                                 required
    //                                                 size="sm"
    //                                                 name="page_margin_top"
    //                                                 type="text"
    //                                                 defaultValue={thisTemplate?.id ? thisTemplate.page_margin_top : 12}
    //                                             />
    //                                         </div>
    //                                         <div className="mx-2">
    //                                             <Form.Label>
    //                                                 <small>Bottom</small>
    //                                             </Form.Label>
    //                                             <Form.Control
    //                                                 ref={margin_bottom_ref}
    //                                                 required
    //                                                 size="sm"
    //                                                 name="page_margin_bottom"
    //                                                 type="text"
    //                                                 defaultValue={thisTemplate?.id ? thisTemplate.page_margin_bottom : 12}
    //                                             />
    //                                         </div>
    //                                         <div className="mx-2">
    //                                             <Form.Label>
    //                                                 <small>Left</small>
    //                                             </Form.Label>
    //                                             <Form.Control
    //                                                 ref={margin_left_ref}
    //                                                 required
    //                                                 size="sm"
    //                                                 name="page_margin_left"
    //                                                 type="text"
    //                                                 defaultValue={thisTemplate?.id ? thisTemplate.page_margin_left : 18}
    //                                             />
    //                                         </div>
    //                                         <div>
    //                                             <Form.Label>
    //                                                 <small>Right</small>
    //                                             </Form.Label>
    //                                             <Form.Control
    //                                                 ref={margin_right_ref}
    //                                                 required
    //                                                 size="sm"
    //                                                 name="page_margin_right"
    //                                                 type="text"
    //                                                 defaultValue={thisTemplate?.id ? thisTemplate.page_margin_right : 12}
    //                                             />
    //                                         </div>
    //                                     </div>
    //                                 </Form.Group>
    //                                 <Form.Group className="my-2">
    //                                     <Form.Label>
    //                                         Border
    //                                     </Form.Label>
    //                                     <Form.Control
    //                                         ref={page_border_ref}
    //                                         required
    //                                         size="sm"
    //                                         name="page_border"
    //                                         type="text"
    //                                         defaultValue={thisTemplate?.id ? thisTemplate.page_border : "1px solid #ccc"}
    //                                     />
    //                                 </Form.Group>
    //                                 <Form.Group className="my-2">
    //                                     <Form.Label>
    //                                         Page Size
    //                                     </Form.Label>
    //                                     <Form.Select ref={page_size_ref} aria-label="Default select example" size="sm" name="page_size" defaultValue={thisTemplate?.id ? thisTemplate.page_size : "A4"}>
    //                                         {page_sizes.map((item, index) => {
    //                                             return <option value={item.id} selected={item.id === thisTemplate?.page_size}>{item.id}</option>
    //                                         })}
    //                                     </Form.Select>
    //                                 </Form.Group>
    //                                 <Form.Group className="my-2">
    //                                     <Form.Label>
    //                                         Orientation
    //                                     </Form.Label>
    //                                     <Form.Select ref={page_orientation_ref} aria-label="Default select example" size="sm" name="page_orientation" defaultValue={thisTemplate?.id ? thisTemplate.page_orientation : "portrait"}>
    //                                         {page_orientation.map((item, index) => {
    //                                             return <option value={item.id} selected={item.id === thisTemplate?.page_orientation}>{item.label}</option>
    //                                         })}
    //                                     </Form.Select>
    //                                 </Form.Group>
    //                                 <Form.Group className="my-2">
    //                                     <Form.Label>
    //                                         Page Number Format
    //                                     </Form.Label>
    //                                     <Form.Control
    //                                         ref={page_number_format_ref}
    //                                         required
    //                                         size="sm"
    //                                         name="page_number_format"
    //                                         type="text"
    //                                         defaultValue={thisTemplate?.id ? thisTemplate.page_number_format : '"Page " counter(page) " of " counter(pages)'}
    //                                     />
    //                                 </Form.Group>
    //                                 <Form.Group className="my-2">
    //                                     <Form.Label>
    //                                         Page Number Position
    //                                     </Form.Label>
    //                                     <Form.Select ref={page_number_position_ref} aria-label="Default select example" size="sm" name="page_number_position" defaultValue={thisTemplate?.id ? thisTemplate.page_number_position : "bottom-center"}>
    //                                         {page_number_position.map((item, index) => {
    //                                             return <option value={item.id} selected={item.id === thisTemplate?.page_number_position}>{item.label}</option>
    //                                         })}
    //                                     </Form.Select>
    //                                 </Form.Group>
    //                                 <Button className="my-2" size="sm" onClick={resetPageFormatting}>Reset Page Format</Button>
    //                             </Container>
    //                         </div>
    //                     </Col>
    //                     <Col sm={8}>
    //                         <Container>
    //                             <div>
    //                                 <Button className="my-3 me-2" variant="success" type="submit" size="sm">{thisTemplate?.id ? "UPDATE TEMPLATE" : "SUBMIT TEMPLATE"}</Button>
    //                                 {thisTemplate && <Button size="sm" onClick={downloadPdf} className="my-3 mx-2">Export PDF</Button>}
    //                                 {thisTemplate && <Button size="sm" onClick={previewPdf} className="my-3 mx-2">Preview PDF</Button>}
    //                                 {thisTemplate && <CKEditor
    //                                     initData={thisTemplate?.id ? thisTemplate.content : "<p>Create your Template here!</p>"}
    //                                     config={editorConfig}
    //                                     editorUrl={process.env.REACT_APP_BASE_URL + "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"}
    //                                     onInstanceReady={() => {
    //                                         if (thisTemplate) {
    //                                             setTemplateInfo(thisTemplate.content);
    //                                         }
    //                                     }}
    //                                     onChange={(event) => {
    //                                         const data = event.editor.getData();
    //                                         // console.log({ event, editor, data });
    //                                         setTemplateInfo(data);
    //                                     }}
    //                                 />}
    //                                 {!thisTemplate && <CKEditor
    //                                     initData={thisTemplate?.id ? thisTemplate.content : "<p>Create your Template here!</p>"}
    //                                     config={editorConfig}
    //                                     editorUrl={process.env.REACT_APP_BASE_URL + "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"}
    //                                     onInstanceReady={() => {
    //                                         if (thisTemplate) {
    //                                             setTemplateInfo(thisTemplate.content);
    //                                         }
    //                                     }}
    //                                     onChange={(event) => {
    //                                         const data = event.editor.getData();
    //                                         // console.log({ event, editor, data });
    //                                         setTemplateInfo(data);
    //                                     }}
    //                                 />}
    //                             </div>
    //                         </Container>
    //                     </Col>
    //                 </Row>
    //             </Form>
    //         </div>

    //         {/* Pdf Preview Modal Start */}
    //         <PdfPreviewModal
    //             numPages={numPages}
    //             onDocumentLoadSuccess={onDocumentLoadSuccess}
    //             pdfPreviewData={pdfPreviewData}
    //             previewPdfVisible={previewPdfVisible}
    //             setPreviewPdfVisible={setPreviewPdfVisible}
    //         />
    //         {/* Pdf Preview Modal End */}
    //     </Accordion >
    // );
}

const styles = {
    cardShadow: {
        // boxShadow: "0px 2px 5px #555555",
        width: "100px",
        height: "150px",
    }
}

export default Template;