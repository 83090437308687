import React, { useEffect, useState } from 'react';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SendOutlined,
  SmileFilled
} from '@ant-design/icons';
import { Layout, Menu, theme, List, Avatar, Typography, Flex, Card, Button, Popover, Space, Input, message } from 'antd';
import * as actions from "store/actions/AuthActions";
import EmojiPicker from 'emoji-picker-react';
import { MdOutlineSmsFailed } from "react-icons/md";

import Message from 'components/WhatsApp/Message';


const { TextArea } = Input;

const { Header, Content, Footer, Sider } = Layout;
const { Meta } = Card;

const { Text } = Typography;

const WhatsApp = () => {

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [messages, setMessages] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [text, setText] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);

  const items = [
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    BarChartOutlined,
    CloudOutlined,
    AppstoreOutlined,
    TeamOutlined,
    ShopOutlined,
  ].map((icon, index) => ({
    key: String(index + 1),
    icon: React.createElement(icon),
    label: `nav ${index + 1}`,
  }));

  const fetchContacts = async (page, searchTerm) => {
    setLoading(true);
    try {

      let response = await actions.loadWAContacts(page, searchTerm);
      console.log({ response, dataSource });
      if (response.status == 200) {
        const data = response.data;
        setDataSource([...dataSource, ...data.results])
        setTotalRows(data.count);
      }
    } catch (err) {
      console.log("Error", err);
      return err;
    }
    setLoading(false);
  }

  const fetchMessages = async (contact) => {
    const response = await actions.loadMessagesForContact(contact);
    if (response.status === 200) {
      const data = response.data;
      setMessages(data.results);
    }
  }

  const sendMessage = async () => {
    const response = await actions.sendMessage(selectedContact.wa_id, "text", text);
    if (response.status === 200) {
      console.log(response);
      setText(null);
    }
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchContacts(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchContacts(p);
      setPageNumber(p);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedContact) {
      try {
        fetchMessages(selectedContact);
      } catch (err) {
        console.log("Error", err);
        return err;
      }
    }
  }, [selectedContact]);

  const data = [
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
  ];

  const onEmojiClick = (emojiObject, event) => {
    setText(text + emojiObject.emoji);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <div>
        <Button onClick={() => {
          window.location.href = "/whatsapp/chat";
        }}>
          Go To Chat
        </Button>
        {/* <Button onClick={() => {
          window.location.href = "/whatsapp/send-message";
        }}>
          Send Message In Bulk
        </Button> */}
        <Button onClick={() => {
          window.location.href = "/whatsapp/templates";
        }}>
          Message Templates
        </Button>
      </div>
    </Layout>
  );
};
export default WhatsApp;