import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Row,
  Card,
  Col
} from 'antd';
import * as actions from "store/actions/AuthActions";
import EmailInput from 'components/Inputs/EmailInput';

import { useNotification } from "NotificationContext";

const CheckboxGroup = Checkbox.Group;

const SendQuoteDrawer = ({ instance }) => {

  const [form] = Form.useForm();
  const notify = useNotification()
  const [attachments, setAttachments] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("Teson - Quote");

  const [pdfRenderData, setPdfRenderData] = useState(null);

  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState(null);

  const loadTemplates = async () => {
    try {
      const response = await actions.loadTemplatesWithType("QUOTE__");
      if (response.status === 200) {
        setTemplates(response?.data?.results);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const render_quote = async (msgTemplate) => {
    try {
      const response = await actions.quoteRender(instance?.id, msgTemplate.name);
      if (response.status == 200) {
        setPdfRenderData(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (instance?.id) {
      setAttachments(instance.attachments.map((item, index) => {
        if (item) {
          return { label: item.document.name, value: item.document.id };
        }
      }))
    }
    loadTemplates();
  }, [instance]);

  useEffect(() => {
    if (selectedMessageTemplate) {
      render_quote(selectedMessageTemplate);
    }
  }, [selectedMessageTemplate]);

  const onFinish = async (values) => {
    console.log('Success:', values);
    setLoading(true);
    try {
      const response = await actions.sendQuote(instance.id, values);
      if (response.status === 200) {
        notify("success", "Success", "Quote sent successfully.")
      }
    }
    catch (err) {
      notify("error", "Error", `Failed to send quote. ${err}`)
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setSelectedMessageTemplate(templates.find(item => item.id === value));
  };

  return (
    <>
      <div>

        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          style={{
            minWidth: 800,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          initialValues={{ subject: subject }}
        >
          <EmailInput label="To" name="to" required message="Please enter emails" />
          <EmailInput label="CC" name="cc" />
          <EmailInput label="BCC" name="bcc" />
          {/* <EmailInput label="Email Template" name="template" /> */}
          <Form.Item label="Message" name="template" rules={[{ required: true, message: 'Please enter template!' }]}>
            <Select
              // style={{
              //     width: 120,
              // }}
              onChange={handleChange}
              placeholder="Select Message Template"
              allowClear
              options={templates.map((item, index) => {
                return { label: `${item.name}`, value: `${item.id}` }
              })}
            />
          </Form.Item>
          <Form.Item label="Subject" name="subject" rules={[{ required: true, message: 'Please enter subject!' }]}>
            <Input />
          </Form.Item>
          {instance?.is_manual && <Form.Item name="attachments" label="Select Attachments" rules={[{ required: true, message: 'Please select at least one checkbox!' }]}>
            {/* <CheckboxGroup options={attachments}  style={{flexDirection: "column"}}/> */}
            <CheckboxGroup style={{ flexDirection: "column" }}>
              {attachments.map((item, index) => {
                return <Checkbox value={item.value}>{item.label}</Checkbox>
              })}
            </CheckboxGroup>
          </Form.Item>}
          {/* <Form.Item label="Message" name="body">
          <QEditor value={editorValue} setValue={setEditorValue}/>
        </Form.Item> */}
          {/* <Form.Item label="Attachments">
            <PaperClipOutlined />{` `}{document?.name}
        </Form.Item> */}
          {/* <Form.Item label="Upload">
          <Form.Item name="dragger" valuePropName="file" getValueFromEvent={normFile} noStyle>
            <Upload.Dragger maxCount={1} name="document_file" action="/upload.do" defaultFileList={[{ name: document?.name, status: 'done', url: document?.document_file }]}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <hr /> */}
          {/* <Form.Item label="Upload" valuePropName="file" getValueFromEvent={normFile} name="document_file">
          <Upload action="/upload.do" maxCount={1}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item> */}
          {/* <Flex>
          <EmailEditor ref={emailEditorRef} onReady={() => {
            // if (currentItem) {
            //   // setTemplateInfo(currentItem.html_content);
            //   // handleEditorLoad(JSON.parse(currentItem.react_email_design));
            // }
          }} />
        </Flex> */}
          <Form.Item wrapperCol={{ offset: 4 }}>
            <Button htmlType="submit" loading={loading}>{loading ? "Sending..." : "Send Email"}</Button>
          </Form.Item>
        </Form>
        {!instance?.is_manual && <Row>
          <Col span={24} style={{ padding: "10px 10px", backgroundColor: "#F0F2F5" }}>
            <Card title="Message" bordered={false}>
              <div dangerouslySetInnerHTML={{ __html: pdfRenderData?.message }}>

              </div>
            </Card>
            <Card title="Attachment" bordered={false} style={{ marginTop: 10 }}>
              <div dangerouslySetInnerHTML={{ __html: pdfRenderData?.attachment }}>

              </div>
            </Card>
          </Col>
        </Row>}
      </div>
    </>
  );
};
export default SendQuoteDrawer;