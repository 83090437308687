import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { asyncStatuses } from 'store/enum';

// Async thunk for logging in
export const loggingIn = createAsyncThunk(
  'user/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/login/', data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Async thunk for logging out
export const loggingOut = createAsyncThunk(
  'user/logout',
  async (data, { dispatch }) => {
    try {
      const response = await axios.post('/user/logout/', data, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.status === 204) {
        dispatch(logout());
      }
    } catch (err) {
      dispatch(logout());
      window.location.href = '/';
    }
  }
);

// User slice
const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    error: null,
    status: null,
    organization: null,
  },
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.organization = null;
    },
    changeOrg(state, action){
      console.log(action.payload);
      state.organization = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loggingIn.pending, (state) => {
        state.status = asyncStatuses.LOADING;
        state.error = null;
      })
      .addCase(loggingIn.fulfilled, (state, action) => {
        state.status = asyncStatuses.SUCCESS;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isAuthenticated = true;
      })
      .addCase(loggingIn.rejected, (state, action) => {
        state.status = asyncStatuses.FAILED;
        state.error = action.payload;
        state.isAuthenticated = false;
      })
      .addCase(loggingOut.fulfilled, (state) => {
        state.status = asyncStatuses.SUCCESS;
        state.isAuthenticated = false;
        state.user = null;
        state.organization = null;
      });
  },
});

export const { logout, changeOrg } = userSlice.actions;
export default userSlice.reducer;
