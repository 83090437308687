import React, { useEffect, useState } from 'react';
import { Button, Drawer, Flex, Popconfirm, Spin, Table, Typography, Form, Space } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import * as userActions from "store/actions/UserActions";
import useScrollBottom from 'CustomHooks/useScrollBottom';
import { Input } from "antd";
import axios from 'axios';
import { IoLogoWhatsapp } from 'react-icons/io';
import SendWAMessageDrawer from './SendWAMessageDrawer';
import SendEmailDrawer from './SendEmailDrawer';

const { Text, Paragraph } = Typography;

const LeadsTable = () => {

  const navigate = useNavigate();
  const bottom = useScrollBottom();
  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openWAMessageDrawer, setOpenWAMessageDrawer] = useState(false);
  const [openEmailDrawer, setOpenEmailDrawer] = useState(false);
  const [currentInstance, setCurrentInstance] = useState(null);

  const [selectedRecords, setSelectedRecords] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchLeads = async (page) => {
    setLoading(true);
    try {
      const response = await actions.loadLeads(page, searchTerm);
      if (response.status == 200) {
        const data = response.data;
        if (page) {
          setDataSource([...dataSource, ...data.results])
          setTotalRows(data.count);
        } else {
          setDataSource([...data.results])
          setTotalRows(data.count);
        }
      }
    } catch (e) {

    }
    setLoading(false);
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchLeads(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchLeads(p);
      setPageNumber(p);
    }
  }, [searchTerm]);

  const handleDelete = async (id) => {
    const newData = dataSource.find((item) => item.id === id);
    // setDataSource(newData);
    const response = await actions.deleteLeads(
      newData.id
    );
    console.log(response);
    if (response) {
      fetchLeads();
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRecords(selectedRows);

    },
  }


  const defaultColumns = [
    {
      title: 'ID',
      // dataIndex: 'id',
      key: 'id',
      render: (_, record) => <Text ellipsis="expandable">{record.id}</Text>,
      sorter: (a, b) => a?.id - b?.id,
      ellipsis: true
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      ellipsis: true
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      ellipsis: true
      // sorter: (a, b) => a?.closing_date - b?.closing_date,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a?.email - b?.email,
      ellipsis: true
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      sorter: (a, b) => a?.phone_number - b?.phone_number,
      ellipsis: true
    },
    {
      title: 'WA Number',
      dataIndex: 'wa_number',
      key: 'wa_number',
      sorter: (a, b) => a?.wa_number - b?.wa_number,
      ellipsis: true
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      sorter: (a, b) => a?.company_name - b?.company_name,
      ellipsis: true
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
      sorter: (a, b) => a?.job_title - b?.job_title,
      ellipsis: true
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      sorter: (a, b) => a?.source - b?.source,
      ellipsis: true
    },
    {
      title: 'Communication Method',
      dataIndex: 'communication_method',
      key: 'communication_method',
      sorter: (a, b) => a?.communication_method - b?.communication_method,
      ellipsis: true
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      sorter: (a, b) => a?.notes - b?.notes,
      ellipsis: true
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'Actions',
      render: (_, record) => {
        // console.log(record);
        return dataSource?.length >= 1 ? (
          <Flex gap="middle">
            <a>
              <EditOutlined
                style={{ verticalAlign: 'middle' }}
                onClick={() => navigate(`/leads/edit/${record.id}/`)}
              />
            </a>
            <Popconfirm title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
            {record?.wa_number && <a href={`/whatsapp/chat?selectedContact=${record.wa_number}`}>
              <IoLogoWhatsapp color="#00B96B" size="15" />
            </a>}
          </Flex>
        ) : null
      },

    }
  ];

  // const handleAdd = () => {
  //   const newData = {
  //     key: count,
  //     name: `Edward King ${count}`,
  //     age: '32',
  //     address: `London, Park Lane no. ${count}`,
  //   };
  //   setDataSource([...dataSource, newData]);
  //   setCount(count + 1);
  // };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let p = pageNumber + 1;
      // console.log({ next_p: p, dataSource, totalRows });
      if (p === 1) {
        fetchLeads(p);
        setPageNumber(p);
      } else if (dataSource && dataSource.length < totalRows) {
        fetchLeads(p);
        setPageNumber(p);
      }
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <Flex gap="middle" justify='space-between'>
        <Flex gap="middle" align='center'>
          <div>
            ({dataSource?.length} / {totalRows})
          </div>
          <div>
            <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
          </div>
        </Flex>
        <Flex gap="small" justify='flex-end'>
          <Button
            // onClick={() => navigate("/whatsapp/send-message")}
            onClick={() => setOpenEmailDrawer(true)}
            icon={<ImportOutlined />}
            type="primary"
            style={{
              marginRight: "12px",
            }}
          >
            Send Email
          </Button>
          <Button
            // onClick={() => navigate("/whatsapp/send-message")}
            onClick={() => setOpenWAMessageDrawer(true)}
            icon={<ImportOutlined />}
            type="primary"
            style={{
              marginRight: "12px",
            }}
          >
            Send Whatsapp Template
          </Button>
          <Button
            onClick={() => navigate("/leads/new")}
            icon={<UserAddOutlined />}
            type="primary"
            style={{
              float: "right"
            }}
          >
            Add Lead
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }} onScroll={handleScroll}>
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            bordered
            size="small"
            dataSource={dataSource}
            columns={columns}
            rowKey={row => row.id}
            pagination={false}
            scrollToFirstRowOnChange
            stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
            scroll={{ y: `calc(75vh - 150px)` }}
          />
          <div style={{ margin: 20 }}>{
            loading &&
            <>
              <Spin tip="Loading" size="small">
                <div className="content" />
              </Spin>
            </>}
          </div>
        </div>
      </Flex>

      <Drawer
        title={`${'Send WA Message'}`}
        placement="right"
        size={'large'}
        onClose={() => setOpenWAMessageDrawer(false)}
        open={openWAMessageDrawer}
        style={{ zIndex: 10 }}
        extra={
          <Space>
            <Button onClick={() => setOpenWAMessageDrawer(false)}>Cancel</Button>
          </Space>
        }
      >
        {openWAMessageDrawer && <SendWAMessageDrawer selectedRecords={selectedRecords} />}
      </Drawer>
      <Drawer
        title={`${'Send Email'}`}
        placement="right"
        size={'large'}
        onClose={() => setOpenEmailDrawer(false)}
        open={openEmailDrawer}
        style={{ zIndex: 10 }}
        extra={
          <Space>
            <Button onClick={() => setOpenEmailDrawer(false)}>Cancel</Button>
          </Space>
        }
      >
        {openEmailDrawer && <SendEmailDrawer selectedRecords={selectedRecords} />}
      </Drawer>
    </div >
  );
};
export default LeadsTable;