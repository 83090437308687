import React from 'react';
import { InboxOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { message, Upload } from 'antd';
import { Card } from 'antd';

const { Dragger } = Upload;
const props = {
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};


const ImportPayments = () => {

  return (
    <>
      <Card title="Payments" extra={<Link to="/payments"><CloseOutlined /></Link>}>
        <Card
          style={{
            marginTop: 16,
          }}
          type="inner"
          title="Upload Csv File"
          // extra={<a href="#">More</a>}
        >
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
        </Card>
      </Card>

    </>
  );
}
export default ImportPayments;