import { RiTeamLine } from 'react-icons/ri';
import { TbFileInvoice } from 'react-icons/tb';
import { FaRupeeSign } from 'react-icons/fa';
import { GiExpense } from 'react-icons/gi';
import { MdMiscellaneousServices, MdOutlineMonitorHeart } from 'react-icons/md';
import { CgProductHunt } from 'react-icons/cg';
import { FcList } from 'react-icons/fc';
import { FiSettings, FiUploadCloud } from 'react-icons/fi';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
import {
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  UserAddOutlined,
} from '@ant-design/icons';

import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import AccessControlHidden from 'components/Access/AccessControlHidden';
import { useSelector } from 'react-redux';
const { Sider } = Layout;


function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label
  };
}

function checkAllowedPermissions(allowedPermissions, userPermissions) {

  let flag = false;
  if (allowedPermissions.length == 0) {
    return flag;
  } else {
    for (let i in allowedPermissions) {
      if (userPermissions?.find?.(item => item == allowedPermissions[i])) {
        flag = true;
        break;
      }
    }
    return flag;
  }
}

const all_items = [
  {
    key: 1,
    label: "Dashboard",
    icon: <PieChartOutlined />,
    allowedPermissions: ["dashboard:view"],
    route: '/'
  },
  {
    key: 2,
    label: "Accounts",
    icon: <RiTeamLine />,
    allowedPermissions: ["accounts:view"],
    route: '/accounts'
  },
  {
    key: 3,
    label: "Vendors",
    icon: <UserAddOutlined />,
    allowedPermissions: ["vendors:view"],
    route: '/vendors'
  },
  {
    key: 4,
    label: "Payments",
    icon: <FaRupeeSign />,
    allowedPermissions: ["payments:view"],
    route: '/payments'
  },
  {
    key: 5,
    label: "Invoices",
    icon: <TbFileInvoice />,
    allowedPermissions: ["invoices:view"],
    route: '/invoices'
  },
  {
    key: 6,
    label: "Quotes",
    icon: <TbFileInvoice />,
    allowedPermissions: ["quotes:view"],
    route: '/quotes'
  },
  {
    key: 7,
    label: "Expenses",
    icon: <GiExpense />,
    allowedPermissions: ["expenses:view"],
    route: '/expenses'
  },
  {
    key: 8,
    label: "Products",
    icon: <CgProductHunt />,
    allowedPermissions: ["products:view"],
    route: '/products'
  },
  {
    key: 10,
    label: "Employees",
    icon: <TeamOutlined />,
    allowedPermissions: ["employees:view"],
    route: '/employees'
  },
  {
    key: 11,
    label: "Contacts",
    icon: <TeamOutlined />,
    allowedPermissions: ["contacts:view"],
    route: '/contacts'
  },
  {
    key: 12,
    label: "Users",
    icon: <UserOutlined />,
    allowedPermissions: ["users:view"],
    route: '/users'
  },
  {
    key: 13,
    label: "Settings",
    icon: <FiSettings />,
    allowedPermissions: ["settings:view"],
    route: '/settings'
  },
  {
    key: 14,
    label: "Documents",
    icon: <FiUploadCloud />,
    allowedPermissions: ["documents:view"],
    route: '/documents'
  },
  {
    key: 15,
    label: "Templates",
    icon: <TbFileInvoice />,
    allowedPermissions: ["templates:view"],
    route: '/templates'
  },
  {
    key: 16,
    label: "AssetFiles",
    icon: <FiUploadCloud />,
    allowedPermissions: ["assetfiles:view"],
    route: '/assetfiles'
  },
  {
    key: 17,
    label: "System Monitoring",
    icon: <MdOutlineMonitorHeart />,
    allowedPermissions: ["systemmonitoring:view"],
    route: '/systemmonitoring'
  },
  {
    key: 18,
    label: "Sales",
    icon: <MdOutlineMonitorHeart />,
    allowedPermissions: ["sales:view"],
    route: '/sales'
  },
  {
    key: 19,
    label: "Leads",
    icon: <MdOutlineMonitorHeart />,
    allowedPermissions: ["leads:view"],
    route: '/leads'
  },
  {
    key: 20,
    label: "Whatsapp",
    icon: <MdOutlineMonitorHeart />,
    allowedPermissions: ["leads:view"],
    route: '/whatsapp'
  }
];

// const items = [
//   getItem('Dashboard', '1', <PieChartOutlined />),
//   getItem('Accounts', '2', <RiTeamLine />),
//   getItem('Vendors', '3', <UserAddOutlined />),
//   getItem('Payments', '4', <FaRupeeSign />),
//   getItem('Invoices', '5', <TbFileInvoice />),
//   getItem('Quotes', '6', <TbFileInvoice />),
//   getItem('Expenses', '7', <GiExpense />),
//   getItem('Products', '8', <CgProductHunt />),
//   getItem('Services', '9', <MdMiscellaneousServices />),
//   getItem('Employees', '10', <TeamOutlined />),
//   getItem('Contacts', '11', <TeamOutlined />),
//   getItem('Users', '12', <UserOutlined />),
//   getItem('Settings', '13', <FiSettings />),
//   getItem('Documents', '14', <FiUploadCloud />),
//   getItem('Templates', '15', <TbFileInvoice />),
//   getItem('AssetFiles', '16', <FiUploadCloud />),
//   getItem('System Monitoring', '17', <MdOutlineMonitorHeart />),
//   getItem('Sales', '18', <MdOutlineMonitorHeart />),
//   getItem('Leads', '19', <MdOutlineMonitorHeart />)
// ];
// const newItems = items.map(item => {
//   return (
//     <AccessControlHidden userPermissions={user.user_permissions} allowedPermis>
//       {item}
//     </AccessControlHidden>
//   )
// })

const routes = [
  '/',
  '/accounts',
  '/vendors',
  '/payments',
  '/invoices',
  '/quotes',
  '/expenses',
  '/products',
  '/services',
  '/employees',
  '/contacts',
  '/users',
  '/settings',
  '/documents',
  '/template',
  '/assetfiles',
  '/systemmonitoring',
  '/sales',
  '/leads',
  '/whatsapp'
];

//   getItem('User', 'sub1', <UserOutlined />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),

const NavSider = () => {

  const [collapsed, setCollapsed] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  

  useEffect(() => {
    const params = location.pathname.split("\/").filter(x => x.trim() != "")
    console.log(params)
    const params1 = params.length > 0 ? params[0] : "dashboard";
    console.log(params1);
    let current_route = all_items.find(x => params1==x.route.replace("\/", ""));
    console.log(current_route);
    if(current_route){
      setCurrentRoute(current_route.key)
    }
  }, [location])

  const user = useSelector(state => state.auth.user)


  const filtered_items = all_items.filter(item => checkAllowedPermissions(item.allowedPermissions, user?.user_permissions));

  const items = filtered_items.map(item => getItem(item.label, item.key, item.icon));

  const routes = filtered_items.map(item => item.route);
  const all_routes = all_items.map(item => item.route);
  // console.log(routes)
  // console.log(filtered_items)

  return (
    <Sider className='custom-scroll-div' style={{ overflowY: "scroll"}} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div
        className="logo"
        style={{ height: "50px" }}>
        <FcList size={20} style={{ margin: "16px 28px" }} />
        {/* <img src={teson}
          style={{ height: "50px", padding: "10px", marginLeft: "5px" }}>
        </img> */}
      </div>
      <Menu theme="dark" defaultSelectedKeys={[`${currentRoute}`]} selectedKeys={[`${currentRoute}`]} mode="inline" items={items} onClick={e => { console.log(e); setCurrentRoute(e.key); navigate(all_routes[e.key - 1]); }} style={{padding: "auto", paddingBottom: "50px"}} />
    </Sider>
  );

}
export default NavSider;