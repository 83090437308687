import React, { useState, useEffect, useCallback, useReducer } from 'react';
import {
    Button,
    Select,
    Form,
    Input,
    Switch,
    Col,
    Row,
    Card,
    Tabs,
    Checkbox,
} from 'antd';
import { CKEditor } from "ckeditor4-react";
import * as adminactions from "store/actions/AuthActions";
import * as masterslice from "store/slices/MasterSlice";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNotification } from 'NotificationContext';


const onChange = (key) => {
    console.log(key);
};

function formatErrorData(payload) {
    const MAX_LENGTH = 500;

    if (typeof payload === 'string') {
        return payload.length > MAX_LENGTH ? "Description too long to display" : payload;
    }

    if (Array.isArray(payload)) {
        const errorString = payload.join('; ');
        return errorString.length > MAX_LENGTH ? "Description too long to display" : errorString;
    }

    if (typeof payload === 'object' && payload !== null) {
        const errorString = Object.entries(payload)
            .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
            .join('; ');
        return errorString.length > MAX_LENGTH ? "Description too long to display" : errorString;
    }

    return "Unknown error format";
}

const editorConfig = {
    extraPlugins: ["tableresize", "uploadimage", "createpdf"],
    removePlugins: [
        "exportpdf",
        "forms",
        "smiley",
        "language",
        "iframe",
        "about",
    ],
    filebrowserImageUploadUrl:
        process.env.REACT_APP_BASE_URL +
        `/template/upload_template_image/`,
    fileTools_requestHeaders: axios.defaults.headers.common,
    uploadUrl:
        process.env.REACT_APP_BASE_URL +
        `/template/upload_template_image/`,
    contentsCss: [
        process.env.REACT_APP_BASE_URL +
        "/staticfiles/ckeditor/4.19.0/full-all/document-style.css",
    ],
    bodyClass: "document-editor",
    height: "5cm",
    width: "19cm",
    allowedContent: true,
    exportPdf_options: {
        header_html: '<div class="styled">Header content</div>',
        footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
        header_and_footer_css:
            ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
        margin_left: "1cm",
        margin_right: "2cm",
        format: "A5",
        page_orientation: "landscape",
    },
    protectedSource: [/{%[\s\S]*?%}+/g, /{{[\s\S]*?}}+/g],
};

const accountCrudActionTypes = {
    "ADD_ACCOUNT": "ADD_ACCOUNT",
    "ADD_ACCOUNT_SUCCESS": "ADD_ACCOUNT_SUCCESS",
    "ADD_ACCOUNT_FAILURE": "ADD_ACCOUNT_FAILURE",
    "EDIT_ACCOUNT": "EDIT_ACCOUNT",
    "EDIT_ACCOUNT_SUCCESS": "EDIT_ACCOUNT_SUCCESS",
    "EDIT_ACCOUNT_FAILURE": "EDIT_ACCOUNT_FAILURE",
}

const accountCrudInitialState = {
    loading: false,
    error: null,
    success: null,
    error_data: "",
}

const accountCrudReducer = (state, action) => {
    switch (action.type) {
        case accountCrudActionTypes.ADD_ACCOUNT: {
            return {
                loading: true,
                error: null,
                success: null,
                error_data: ""
            }
        }
        case accountCrudActionTypes.ADD_ACCOUNT_SUCCESS: {
            return {
                loading: false,
                error: false,
                success: true,
                error_data: ""
            }
        }
        case accountCrudActionTypes.ADD_ACCOUNT_FAILURE: {
            return {
                loading: false,
                error: true,
                success: false,
                error_data: formatErrorData(action.payload)
            }
        }
        case accountCrudActionTypes.EDIT_ACCOUNT: {
            return {
                loading: true,
                error: null,
                success: null,
                error_data: ""
            }
        }
        case accountCrudActionTypes.EDIT_ACCOUNT_SUCCESS: {
            return {
                loading: false,
                error: false,
                success: true,
                error_data: ""
            }
        }
        case accountCrudActionTypes.EDIT_ACCOUNT_FAILURE: {
            return {
                loading: false,
                error: true,
                success: false,
                error_data: formatErrorData(action.payload)
            }
        }
        default: {
            return state;
        }
    }
}

const AccountForm = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const notify = useNotification()

    const [crudState, crudDispatch] = useReducer(accountCrudReducer, accountCrudInitialState);

    const constitution_types = useSelector(store => store.accounts?.constitution_types);
    const salutation_choices = useSelector(store => store.accounts?.salutation_choices);
    const account_types = useSelector(store => store.accounts?.account_types);
    const gst_treatment_types = useSelector(store => store.accounts?.gst_treatment_types);

    const currency_options = useSelector(store => store.master?.currencies);
    const state_options = useSelector(store => store.master?.states);
    const payment_term_options = useSelector(store => store.master?.payment_terms);
    const country_options = useSelector(store => store.master?.countries);

    const fetchAccountInfo = useCallback(async () => {
        if (id) {
            const response = await adminactions.fetchAccount(id);
            console.log(response)
            if (response) {
                setDataSource(response);
            }
        }
        setLoading(false);
    }, [id]);

    useEffect(() => {
        fetchAccountInfo();

    }, [fetchAccountInfo]);

    useEffect(() => {
        if (!id) {
            let val = {}
            if (constitution_types?.default) {
                val['constitution_of_business'] = constitution_types.default;
            }
            if (salutation_choices?.default) {
                val['salutation'] = salutation_choices.default;
            }
            if (account_types?.default) {
                val['account_type'] = account_types.default;
            }
            if (gst_treatment_types?.default) {
                val['gst_treatment'] = gst_treatment_types.default;
            }
            console.log({ val })
            setDataSource(val);
        }
    }, [constitution_types, salutation_choices, account_types, gst_treatment_types]);

    const onFinish = async (values) => {
        values.enable_portal = !!values.enable_portal;
        if (values) {
            if (id) {
                crudDispatch({ type: accountCrudActionTypes.EDIT_ACCOUNT });
                try {
                    const response = await adminactions.updateAccounts(values, id);
                    crudDispatch({ type: accountCrudActionTypes.EDIT_ACCOUNT_SUCCESS })
                } catch (err) {
                    crudDispatch({ type: accountCrudActionTypes.EDIT_ACCOUNT_FAILURE, payload: err?.response?.data })
                }
            } else {
                crudDispatch({ type: accountCrudActionTypes.ADD_ACCOUNT });
                try {
                    const response = await adminactions.addAccounts(values);
                    crudDispatch({ type: accountCrudActionTypes.ADD_ACCOUNT_SUCCESS })
                } catch (err) {
                    crudDispatch({ type: accountCrudActionTypes.ADD_ACCOUNT_FAILURE, payload: err?.response?.data })
                }
            }
        }
    };

    useEffect(() => {
        if (crudState.loading === false) {
            if (crudState.error === true) {
                notify("error", id ? "Edit operation failed" : "Add operation failed", `${crudState.error_data}`)
            } else if (crudState.success === true) {
                notify("success", id ? "Edit operation succeeded" : "Add operation succeeded");
                navigate("/accounts");
            }
        }
    }, [crudState.loading, crudState.error, crudState.error_data]);

    return (
        <div style={{ maxHeight: "90vh", overflowY: "scroll", paddingBottom: 20 }}>
            {!loading &&
                <Form
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={onFinish}
                    initialValues={dataSource}
                    disabled={crudState.loading}
                >
                    <Row gutter={10} style={{ margin: "15px" }}>
                        {/* FORM_1 */}
                        <Col span={12}>
                            <div className="site-card-border-less-wrapper" style={{ paddingBottom: "10px" }} >
                                <Card title="Basic Info" bordered={false} >
                                    <Form.Item className="text-start" label="Name" name='name'>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item className="text-start" label="Legal name of business" name='legal_name_of_business' rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Constitution of Business" name="constitution_of_business">
                                        <Select
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            loading={Array.isArray(constitution_types?.choices) && constitution_types?.choices?.length > 0 ? false : true}
                                            options={constitution_types?.choices || []}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Account Type" name="account_type" rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            loading={Array.isArray(account_types?.choices) && account_types?.choices?.length > 0 ? false : true}
                                            options={account_types?.choices || []}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Email" name='email'>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Phone" name='phone'>
                                        <Input maxLength={15} showCount />
                                    </Form.Item>
                                    <Form.Item label="Currency" name="currency">
                                        <Select
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            loading={Array.isArray(currency_options) && currency_options?.length > 0 ? false : true}
                                            options={currency_options?.map?.((opt, ind) => {
                                                return {
                                                    label: `(${opt.iso_code}) ${opt.currency}`,
                                                    value: opt.id
                                                }
                                            }) || []}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Website" name='website'
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (!value || /^https?:\/\/[^\s$.?#].[^\s]*$/i.test(value)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Please enter a valid URL'));
                                                },
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Card>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="site-card-border-less-wrapper" style={{ paddingBottom: "10px" }}>
                                <Card title="Address" bordered={false} styles={{ body: { paddingTop: 0 } }}>
                                    <Tabs
                                        defaultActiveKey="1"
                                        onChange={onChange}
                                        items={[
                                            {
                                                label: `Billing Address`,
                                                key: '1',
                                                children:
                                                    (<div>
                                                        <Form.Item label="Address line 1" name='billing_address1'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Address line 2" name='billing_address2'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="City" name='billing_city'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="State" name='billing_state' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Postal Code" name='billing_postal_code' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Country" name="billing_country">
                                                            <Select
                                                                showSearch
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                                                filterSort={(optionA, optionB) =>
                                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                                }
                                                                loading={Array.isArray(country_options) && country_options?.length > 0 ? false : true}
                                                                options={country_options?.map?.((opt, ind) => {
                                                                    return {
                                                                        label: `${opt.name}`,
                                                                        value: opt.id
                                                                    }
                                                                }) || []}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label="Billing Phone" name='billing_phone'>
                                                            <Input maxLength={20} showCount />
                                                        </Form.Item>
                                                    </div>),
                                            },
                                            {
                                                label: `Shipping Address`,
                                                key: '2',
                                                children:
                                                    (<div>
                                                        <Form.Item label="Address line 1" name='shipping_address1'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Address line 2" name='shipping_address2'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="City" name='shipping_city'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="State" name='shipping_state'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Postal Code" name='shipping_postal_code'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Country" name="shipping_country">
                                                            <Select
                                                                showSearch
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                                                filterSort={(optionA, optionB) =>
                                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                                }
                                                                loading={Array.isArray(country_options) && country_options?.length > 0 ? false : true}
                                                                options={country_options?.map?.((opt, ind) => {
                                                                    return {
                                                                        label: `${opt.name}`,
                                                                        value: opt.id
                                                                    }
                                                                }) || []}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label="Shipping Phone" name='shipping_phone'>
                                                            <Input maxLength={20} showCount />
                                                        </Form.Item>
                                                    </div>),
                                            },
                                        ]}
                                    />
                                </Card>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="site-card-border-less-wrapper" style={{ paddingBottom: "10px" }} >
                                <Card title="Primary Contact" bordered={false} >
                                    <Form.Item label="Salutation" name="salutation">
                                        <Select
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            loading={Array.isArray(salutation_choices?.choices) && salutation_choices?.choices?.length > 0 ? false : true}
                                            options={salutation_choices?.choices || []}
                                        />
                                    </Form.Item>
                                    <Form.Item label="First Name" name='first_name'>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Last Name" name='last_name'>
                                        <Input />
                                    </Form.Item>
                                </Card>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="site-card-border-less-wrapper" style={{ paddingBottom: "10px" }} >
                                <Card title="GST/VAT Details" bordered={false} >
                                    <Form.Item label="Gst number" name='gst_number'>
                                        <Input maxLength={15} showCount />
                                    </Form.Item>
                                    <Form.Item label="Vat number" name='vat_number'>
                                        <Input maxLength={15} showCount />
                                    </Form.Item>
                                    <Form.Item label="Gst Treatment" name="gst_treatment">
                                        <Select
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            loading={Array.isArray(gst_treatment_types?.choices) && gst_treatment_types?.choices?.length > 0 ? false : true}
                                            options={gst_treatment_types?.choices || []}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Place of supply" name="place_of_supply">
                                        <Select
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            loading={Array.isArray(state_options) && state_options?.length > 0 ? false : true}
                                            options={state_options?.map?.((opt, ind) => {
                                                return {
                                                    label: `${opt.name} [${opt.abbreviation}] - ${opt.gst_code}`,
                                                    value: opt.id
                                                }
                                            }) || []}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Payment Term" name="payment_term">
                                        <Select
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            loading={Array.isArray(payment_term_options) && payment_term_options?.length > 0 ? false : true}
                                            options={payment_term_options?.map?.((opt, ind) => {
                                                return {
                                                    label: `${opt.name}`,
                                                    value: opt.id
                                                }
                                            }) || []}
                                        />
                                    </Form.Item>
                                </Card>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <div className="site-card-border-less-wrapper" style={{ paddingBottom: "10px" }}>
                                <Card title="Notes" bordered={false} styles={{ body: { paddingTop: 0 } }} >
                                    <Tabs
                                        defaultActiveKey="1"
                                        onChange={onChange}
                                        items={[
                                            {
                                                label: `Public Notes`,
                                                key: '1',
                                                children:
                                                    <Form.Item valuePropName='data' name="public_notes"
                                                        getValueFromEvent={(event) => {
                                                            const data = event.editor.getData();
                                                            return data;
                                                        }}
                                                        style={{ width: "100%" }}
                                                    >
                                                        <CKEditor
                                                            initData={dataSource ? dataSource.public_notes : "<p>Create your Template here!</p>"}
                                                            config={editorConfig}
                                                            editorUrl={process.env.REACT_APP_BASE_URL + "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                            },
                                            {
                                                label: `Private Notes`,
                                                key: '2',
                                                children:
                                                    <Form.Item valuePropName='data' name="private_notes"
                                                        getValueFromEvent={(event) => {
                                                            const data = event.editor.getData();
                                                            return data;
                                                        }}>
                                                        <CKEditor
                                                            initData={dataSource ? dataSource.private_notes : "<p>Create your Template here!</p>"}
                                                            config={editorConfig}
                                                            editorUrl={process.env.REACT_APP_BASE_URL + "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                            },
                                        ]}
                                    />
                                </Card>
                            </div>
                            <div className="site-card-border-less-wrapper" style={{ paddingBottom: "10px" }} >
                                <Card title="Portal" bordered={false} >
                                    <Form.Item
                                        name="enable_portal"
                                        valuePropName="checked"
                                    >
                                        <Checkbox>Enable Portal?</Checkbox>
                                    </Form.Item>

                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ margin: "15px" }}>
                        <Button block type="primary" htmlType="submit" >
                            Save
                        </Button>
                    </div>
                </Form>}
        </div >
    );
};
export default AccountForm;