import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  products: [],
  marketing: [],
  reviews: [],
  advertises: [],
  tasks: [],
};

const generalSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadAllCategories: (state, action) => {
      state.categories = action.payload;
    },
    loadAllProducts: (state, action) => {
      state.products = action.payload;
    },
    searchProducts: (state, action) => {
      state.products = action.payload;
    },
    searchProductsOfCategory: (state, action) => {
      state.products = action.payload;
    },
    sendMessage: (state, action) => {
      state.marketing = action.payload;
    },
    loadAllReviews: (state, action) => {
      state.reviews = action.payload;
    },
    addReview: (state, action) => {
      state.reviews.push(action.payload);
    },
    loadAllAdvertises: (state, action) => {
      state.advertises = action.payload;
    },
    removeReview: (state, action) => {
      state.reviews = state.reviews.filter((item) => item.id !== action.payload);
    },
    fetchTasks: (state, action) => {
      state.tasks = action.payload;
    },
    clearTasks: (state) => {
      state.tasks = [];
    },
    addTask: (state, action) => {
      state.tasks.push(action.payload);
    },
    updateTasks: (state, action) => {
      state.tasks = action.payload;
    },
  },
});

// Export actions
export const {
  loadAllCategories,
  loadAllProducts,
  searchProducts,
  searchProductsOfCategory,
  sendMessage,
  loadAllReviews,
  addReview,
  loadAllAdvertises,
  removeReview,
  fetchTasks,
  clearTasks,
  addTask,
  updateTasks,
} = generalSlice.actions;

// Export reducer
export default generalSlice.reducer;
