import { Button, Modal, Popconfirm, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import * as actions from "store/actions/AuthActions";


const DocumentPicker = ({ selectDocuments }) => {

    const [selectionType, setSelectionType] = useState('checkbox');
    const [documentModal, setDocumentModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const loadDocuments = async () => {
        const response = await actions.loadDocuments(1, undefined, 1000000);
        console.log(response)
        if (response) {
            setDocuments(response.results);
        }
    }

    useEffect(() => {
        loadDocuments();
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    }

    const [documents, setDocuments] = useState([]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a?.id.localeCompare(b?.id),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a?.name.localeCompare(b?.name),
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => moment(a?.created_at) - moment(b?.created_at),
            render: (text, record) => {
                return <div>{moment(text).format('MMMM Do YYYY, h:mm:ss a')}</div>
            }
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            sorter: (a, b) => a?.org_size - b?.org_size,
        },
        {
            title: 'Linked',
            dataIndex: 'is_linked',
            key: 'is_linked',
            sorter: (a, b) => a?.is_linked - b?.is_linked,
            render: (_, record) => {
                const size = 8;
                const radius = "30%";
                return (
                    <div className='d-flex justify-content-center align-items-center'>
                        {record.is_linked ? <>
                            <div style={{ width: size, height: size, backgroundColor: "green", borderRadius: radius }}>

                            </div>
                        </> : <>
                            <div style={{ width: size, height: size, backgroundColor: "red", borderRadius: radius }}>

                            </div>
                        </>}
                    </div>
                )
            }
        },
    ];


    return (
        <>
            <Button onClick={() => setDocumentModal(true)}>Select From Documents</Button>
            <Modal
                title="Select Documents"
                centered
                open={documentModal}
                onOk={() => {
                    selectDocuments(selectedRowKeys);
                    setSelectedRowKeys([]);
                    setDocumentModal(false);
                }}
                onCancel={() => setDocumentModal(false)}
                width={1000}
                okText={`Pick Documents`}
            >
                <Table
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                    }}
                    bordered
                    size="small"
                    dataSource={documents}
                    columns={columns}
                    rowKey={row => row.id}
                // scroll={{ x: 1400 }}
                />
            </Modal>
        </>
    );
}

export default DocumentPicker;