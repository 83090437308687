import { NavSider,EmployeesTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Employees = () => {



    return (
        <EmployeesTable/>
    );

}
export default Employees;