import React, { createContext, useContext, useState } from 'react';
import { notification } from 'antd';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const notify = (type, message, description) => {
    switch (type) {
      case "success":
        api.success({ message: `${message}`, description: description });
        break;
      case "error":
        api.error({ message: `${message}`, description: description });
        break;
      case "warning":
        api.warning({ message: `${message}`, description: description });
        break;
      case "info":
        api.info({ message: `${message}`, description: description });
        break;
      default:
        api.open({ message: `${message}`, description: description });
    }
  }

  return (
    <NotificationContext.Provider value={notify}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};