import React, { useState, useEffect } from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Switch,
    Col,
    Row,
    Card,
    Tabs,
} from 'antd';
import moment from 'moment/moment';

import * as adminactions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';
import { dateFormat } from 'constants/constants';
import { DraggerBoxTable } from 'components';
import DraggerBox from 'components/DraggerBoxTable/DraggerBox';

const onChange = (key) => {
    console.log(key);
};

const DocumentForm = () => {

    return (
        <>
            <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
            >
                <Row
                    style={{ margin: "15px" }}
                >
                    <Col span={24} style={{}}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="Details" bordered={false} style={{}}>
                                <DraggerBox />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
export default DocumentForm;