import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Cascader,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Space,
    Select,
    Switch,
    TreeSelect,
    Col,
    Row,
    Card,
    Tabs,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as adminactions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';


const ExpenseForm = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState(undefined);

    const onFinish = (values) => {
        console.log('Success:', values);
        if (values) {
            onSaveHandler(values)
        }
    };

    const fetchExpenses = async () => {
        const response = await adminactions.fetchExpenses(id);
        console.log(response)
        if (response) {
            console.log("Changing state now...")
            setDataSource(response);
        }
    }

    useEffect(() => {
        fetchExpenses()
    }, [])

    const onSaveHandler = async (values) => {
        if (id) {
            const response = await adminactions.updateExpenses(values, id);
            console.log(response);
            if (response) {
                navigate("/expenses")
            }
        } else {
            const response = await adminactions.addExpenses(values)
            if (response) {
                navigate('/expenses/edit/' + response.id);
            }
        }
    }


    return (
        <>
            {dataSource ?
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                    initialValues={dataSource}
                >
                    <Row
                        style={{ margin: "15px" }}
                    >
                        <Col span={24} style={{}}>
                            <div className="site-card-border-less-wrapper">
                                <Card title="Details" bordered={false} style={{}}>
                                    <div>
                                        <Form.Item label="Vendor" name='vendor'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Client" name='client'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Category" name='category' >
                                            <Select >
                                                <Select.Option value='SALARY'>Salary</Select.Option>
                                                <Select.Option value='RENT'>Rent</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="User" name='user' >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Amount" name='amount' >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Public Notes" name='public_notes' >
                                            <TextArea />
                                        </Form.Item>
                                        <Form.Item label="Private Notes" name='private_notes' >
                                            <TextArea />
                                        </Form.Item>
                                        <Form.Item label="Date" >
                                            <DatePicker name='date' format={"YYYY-MM-DDThh:mm:ss"} />
                                        </Form.Item>
                                        <Form.Item label="Organization" name='organization' >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div style={{ margin: "15px" }}>
                                        <Button block type="primary" htmlType="submit" >
                                            Save
                                        </Button>
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Form>
                : <>
                    {console.log("When if false")}
                </>}
        </>
    );
};
export default ExpenseForm;