import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, Popconfirm, Table, Input, Spin, Drawer } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import * as userActions from "store/actions/UserActions"
import moment from 'moment';
import useScrollBottom from 'CustomHooks/useScrollBottom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, message } from 'antd/lib';
import axios from 'axios';

const getItemsForDropDown = (record, navigate, handleDelete, copyPaymentLinkToNavigator) => (
  [
    {
      key: '1',
      label: (
        // <Popconfirm title="Sure to edit?"
        //   onConfirm={() => navigate(`/invoices/edit/${record.id}/`)}
        // >
          <a href={`/invoices/edit/${record.id}/`}>
            <EditOutlined
              style={{
                verticalAlign: 'middle',
              }}
            />
          </a>
        // </Popconfirm>
      ),
    },
    {
      key: "2",
      label: (<Popconfirm title="Sure to delete?"
        onConfirm={() => handleDelete(record.id)}
      >
        <a>
          <DeleteOutlined />
        </a>
      </Popconfirm>)
    },
    {
      key: "3",
      label: (<a href={`/payments?search=${record.inv_number}`}>
        Payments
      </a>)
    },
    {
      key: "4",
      label: (<span onClick={()=>copyPaymentLinkToNavigator(record)}>
        Copy Payment Link
      </span>)
    }
  ]
)

const InvoicesTable = () => {

  const [messageApi, contextHolder] = message.useMessage();
  const organization = useSelector(state => state.auth.organization);
  const searchRef = useRef();

  const bottom = useScrollBottom();
  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermLive, setSearchTermLive] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [invoiceStatuses, setInvoiceStatuses] = useState([]);
  const navigate = useNavigate();

  // State for drawer
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");


  const fetchInvoices = async (page) => {
    setLoading(true);
    try {
      const response = await actions.loadInvoices(page, searchTerm);
      if (response.status == 200) {
        const data = response.data;
        setDataSource([...dataSource, ...data.results])
        setTotalRows(data.count)
      }
    } catch (e) {

    }
    setLoading(false);
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  const loadInvoiceStatuses = async () => {
    const response = await actions.loadInvoiceStatuses();
    if (response) {
      setInvoiceStatuses(response);
    }
  }

  useEffect(() => {
    // fetchInvoices();
    loadInvoiceStatuses();
  }, []);

  useEffect(() => {
    let p = pageNumber + 1;
    console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchInvoices(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchInvoices(p);
      setPageNumber(p);
    }
  }, [bottom, searchTerm]);

  // useEffect(() => {
  //   window.location.reload();
  // }, [organization]);


  const handleDelete = async (id) => {
    const newData = dataSource.find((item) => item.id === id);
    // setDataSource(newData);
    const response = await actions.deleteInvoices(newData.id);
    console.log(response);
    if (response.status === 204) {
      setLoading(true);
      const response = await actions.loadInvoices();
      if (response.status === 200) {
        const data = response.data;
        setDataSource(data.results)
        setTotalRows(data.count)
      }
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  }

  const copyPaymentLinkToNavigator = (record) => {
    const link = `https://teson.in/invoice/${record.id}`;
    navigator.clipboard.writeText(link);
    messageApi.open({
      type: 'success',
      content: `${link} copied to clipboard.`,
    });
  }

  const handleRowClick = (record) => {
    setSelectedInvoice(record); // Set selected invoice
    setDrawerVisible(true); // Open drawer
    setPdfUrl(`${axios.defaults.baseURL}/invoices/invoices/${record.id}/invoiceView/`);
  };

  const defaultColumns = [
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      filters: invoiceStatuses.map((is, i) => {
        return {
          text: is[0],
          value: is[0]
        }
      }),
      sorter: (a, b) => a?.status.localeCompare(b?.status),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'NUMBER',
      dataIndex: 'inv_number',
      key: 'inv_number',
      sorter: (a, b) => a?.inv_number.localeCompare(b?.inv_number),
    },
    {
      title: 'ACCOUNT',
      dataIndex: ["account_data", "name"],
      key: 'account_name',
      width: '20%',
      sorter: (a, b) => a?.account_name.localeCompare(b?.account_name),
    },
    {
      title: 'AMOUNT PAYABLE',
      dataIndex: 'total_payable',
      key: 'total_payable',
      sorter: (a, b) => a?.total_payable - b?.total_payable,
      align: 'right',
      render: (value, record) => (
        <span>{record.currency?.iso_code || 'INR'} {value}</span>
      ),
    },
    {
      title: 'BALANCE',
      dataIndex: 'balance',
      key: 'balance',
      sorter: (a, b) => a?.balance - b?.balance,
      align: 'right',
      render: (value, record) => (
        <span>{record.currency?.iso_code || 'INR'} {value}</span>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      align: "right",
      sorter: (a, b) => {
        return moment(a?.invoice_date) - moment(b?.invoice_date)
      },
      render: (invoice_date) => (
        <div>
          <div>{moment(invoice_date).format("YYYY-MM-DD")}</div>
          {/* <div>{moment(invoice_date).format("dddd")}</div> */}
        </div>
      )
    },
    {
      title: 'DUE DATE',
      dataIndex: 'due_date',
      key: 'due_date',
      align: "right",
      sorter: (a, b) => {
        return moment(a?.due_date) - moment(b?.due_date);
      },
      render: (due_date, record) => {
        const today = moment();
        const dueDateMoment = moment(due_date);
        const isOverdue = today.isAfter(dueDateMoment);
        const overdueDays = today.diff(dueDateMoment, 'days');
    
        return (
          <div>
            <div>{dueDateMoment.format("YYYY-MM-DD")}</div>
            {/* <div>{dueDateMoment.format("dddd")}</div> */}
            {isOverdue && record.status !== 'PAID' && (
              <div style={{ color: 'red' }}>
                <small>(Overdue by {overdueDays} {overdueDays > 1 ? 'days' : 'day'})</small>
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: 'IS MANUAL',
      dataIndex: 'is_manual',
      key: 'balance',
      align: 'right',
      render: (_, record) => {
        return <>{record.is_manual ? "Manual" : "System Generated"}</>
      }
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => {
        // console.log(record);
        return dataSource?.length >= 1 ? (
          <Flex gap="middle" style={{padding: 5}}>
            <Dropdown
              menu={{ items: getItemsForDropDown(record, navigate, handleDelete, copyPaymentLinkToNavigator) }}
              placement="bottomLeft"
              onClick={(e) => e.stopPropagation}
            >
              <MoreOutlined onClick={(e)=> e.stopPropagation()}/>
            </Dropdown>
          </Flex>
        ) : null
      }
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const [selectionType, setSelectionType] = useState('checkbox');

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let p = pageNumber + 1;
      // console.log({ next_p: p, dataSource, totalRows });
      if (p === 1) {
        fetchInvoices(p);
        setPageNumber(p);
      } else if (dataSource && dataSource.length < totalRows) {
        fetchInvoices(p);
        setPageNumber(p);
      }
    }
  };

  return (
    <div style={{ padding: 10 }}>
      {contextHolder}
      <Flex gap='large' justify='space-between'>
        <Flex gap='large' align='center'>
          <div>
            ({dataSource?.length} / {totalRows})
          </div>

          <div>
            <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
          </div>
        </Flex>
        <Flex gap='large'>
          <Button
            // onClick={handleAdd}
            onClick={() => navigate("/invoices/new")}
            icon={<UserAddOutlined />}
            type="primary"
          >
            Create Invoice
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }} onScroll={handleScroll}>
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            loading={loading}
            // bordered
            onRow={(record) => ({
              onClick: () => handleRowClick(record), // Handle row click
            })}
            size="small"
            dataSource={dataSource}
            columns={columns}
            rowKey={row => row.id}
            pagination={false}
            scrollToFirstRowOnChange
            stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
            scroll={{ y: `calc(75vh - 150px)` }}
          />
          {/* <div style={{ margin: 20 }}>{
            loading &&
            <>
              <Spin tip="Loading" size="small">
                <div className="content" />
              </Spin>
            </>}
          </div> */}
        </div>
      </Flex>

      {/* Drawer for Invoice Details */}
      <Drawer
        title={`Invoice Details - ${selectedInvoice?.inv_number}`}
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={"50%"}
      >
        {/* Render invoice details here */}
        {/* Embed PDF */}
        {pdfUrl && (
          <iframe
            src={`${pdfUrl}?test=1233#view=FitH&toolbar=0&navpanes=0&scrollbar=0`}
            title="Invoice PDF"
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        )}
      </Drawer>
    </div>
  );
};
export default InvoicesTable;