import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Select, DatePicker, Button, Row, Col, Divider, Upload, Switch, Flex, Card } from 'antd';
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons"
import * as actions from "store/actions/AuthActions";
import EmailEditor from 'react-email-editor';
import CodeMirrorEditor from "components/Editors/CodeMirrorEditor";
import { useNavigate, useParams } from "react-router-dom";

const { Option } = Select;

const TemplateForm = ({ instance, onClose, reload }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const emailEditorRef = useRef(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [templateInfo, setTemplateInfo] = useState("");


    const [templateType, setTemplateType] = useState("EMAIL");

    const loadTemplate = async () => {
        if (id) {
            const response = await actions.loadTemplate(id);
            if (response) {
                const thisInstance = { ...response }
                let date_fields = ['converted_at', 'last_contacted_at']
                // for (let field of date_fields) {
                //     thisInstance[field] = thisInstance[field] ? dayjs(thisInstance[field]) : null;
                // }
                form.setFieldsValue(thisInstance);
                // setAttachments(response?.attachments);
                // setLeadActivities(response?.lead_activities);
                // setTasks(response?.tasks);
            }
        }
        setLoading(false);
    }


    const onFinish = async (values) => {
        // Handle form submission here
        console.log('Received values:', values);
        let new_values = values;
        setLoading(true);
        if (values.template_type === "EMAIL") {
            let ree = await getEmailEditorHtml();
            setTemplateInfo(ree.html);
            new_values = { ...new_values, "html_content": ree.html, "react_email_design": JSON.stringify(ree.design) }
        }
        if (values.template_type === "HTML") {
            new_values = { ...new_values, "html_content": templateInfo }
        }
        if (instance) {
            try {
                console.log(instance)
                const response = await actions.updateTemplate(instance?.id, new_values);
                if (response.status === 200) {
                    console.log("Record added successfully");
                    reload();
                }
            } catch (e) {

            }
        } else {
            try {
                const response = await actions.createTemplate(new_values);
                if (response.status === 201) {
                    console.log("Record added successfully");
                    reload();
                }
            } catch (e) {

            }
        }
        setLoading(false);

    };

    const boldDividerStyle = {
        fontWeight: 'bold',
        fontSize: '18px', // Optional: Adjust the font size as needed
    };

    useEffect(() => {
        if (id) {
            loadTemplate()
        }
    }, [id]);

    const handleEditorLoad = (design) => {
        emailEditorRef.current.loadDesign(design);
    }

    const getEmailEditorHtml = async () => {
        return new Promise((resolve) => {
            emailEditorRef.current.editor.exportHtml((data) => {
                const { design, html } = data;
                console.log('exportHtml', html);
                const send = { design, html }
                console.log({ send });
                resolve(send);
            });
        });
    }

    return (
        <>
            <div className='custom-scroll-div' style={{ overflowY: "scroll", maxHeight: "90vh", padding: 10, paddingBottom: 30 }}>
                <Form form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign="left">
                    <Flex justify='end' gap='middle' style={{ margin: "10px auto" }}>
                        <Button type="primary" htmlType="submit" >
                            Save
                        </Button>
                    </Flex>
                    <Row>
                        <Col span={12}>
                            <Card title="Template Meta Information" bordered={false}>
                                <Form.Item label="Template Name" name="name" rules={[{ required: true, message: 'Please input your first name!' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Template Type" name="template_type" rules={[{ required: true }]}>
                                    <Select
                                        // defaultValue="EMAIL"
                                        style={{
                                            width: 120,
                                        }}
                                        value={templateType}
                                        onChange={(value) => setTemplateType(value)}
                                        options={["EMAIL", "HTML"].map((item, index) => {
                                            return { value: item, label: item }
                                        })}
                                    />
                                </Form.Item>
                                <Form.Item label="Description" name="description">
                                    <Input.TextArea />
                                </Form.Item>
                            </Card>
                        </Col>
                        {/* <Col span={12} style={{ padding: "0px 0px 10px 10px" }}>
                        <Card title="Contact Information" bordered={false}>
                            <Form.Item label="Company Name" name="company_name">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Job Title" name="job_title">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Source" name="source">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Communication Method" name="communication_method">
                                <Input />
                            </Form.Item>
                        </Card>
                    </Col> */}
                    </Row>

                    {/* {id && <Row style={{ padding: "10px 0px 0px 0px" }}>
                    <Col span={24}>
                        {id && <div className="site-card-border-less-wrapper">
                            <Card bordered={false}>
                                <Tabs
                                    defaultActiveKey="1"
                                    items={tabElements}
                                />
                            </Card>
                        </div>}
                    </Col>
                </Row>} */}
                </Form>
            </div>
            <Form form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign="left">
                {templateType === "EMAIL" && <Row>
                    <Col span={24}>
                        <EmailEditor ref={emailEditorRef} onReady={() => {
                            if (currentItem) {
                                setTemplateInfo(currentItem.html_content);
                                handleEditorLoad(JSON.parse(currentItem.react_email_design));
                            }
                        }} />
                    </Col>
                </Row>}
                {templateType === "HTML" && <Row>
                    <Col span={14} dangerouslySetInnerHTML={{ __html: templateInfo }}>

                    </Col>
                    <Col span={10}>
                        <CodeMirrorEditor
                            instance={instance}
                            codeMirrorData={templateInfo}
                            setCodeMirrorData={(value) => setTemplateInfo(value)}
                        />
                    </Col>
                </Row>}
                <Flex justify="end" gap="middle">
                    <Col>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Flex>
            </Form>
        </>
    );
}

export default TemplateForm;