import React from 'react';

import { UnControlled as CodeMirror } from "react-codemirror2";
import "./codemirror.scss";
// import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/jinja2/jinja2';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/python/python'; // For Jinja2 Python parts
// import 'codemirror/theme/material.css'; 

const CodeMirrorEditor = ({instance, codeMirrorData, setCodeMirrorData}) => {

    return (
        <CodeMirror
            style={{ padding: 20 }}
            // value={codeMirrorData}
            editorDidMount={(editor) => {
                instance = editor;
            }}
            options={{
                mode: "htmlmixed",
                theme: "material",
                lineNumbers: true,
                autoRefresh: true,
            }}
            onChange={(editor, data, value) => {
                // setCodeMirrorLatestData(value);
                setCodeMirrorData(value)
            }}
        />
    );
}

export default CodeMirrorEditor;