import React, {useEffect, useState} from "react";

const NoAccess = ({permissionNeeded='his:dashboard'}) => {
    
    switch(permissionNeeded){
        case "his:dashboard":
        default:
            return (
                <div>
                    You do not have access for this page.
                </div>
            );
    }
}

export default NoAccess;
