import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Result } from "antd";

const NotFoundPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    console.log({ location })

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={()=>navigate('/')}>Back Home</Button>}
        />
    );
}

export default NotFoundPage;