import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Table, Flex, Input, Spin } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined, DownloadOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import moment from 'moment';
import { Modal } from "antd";
import { Document, Page } from "react-pdf";
import axios from 'axios';
import fileDownload from "js-file-download";
import { notify } from "components";
import useScrollBottom from 'CustomHooks/useScrollBottom';
import PdfPreviewModal from "components/PdfPreviewModal/PdfPreviewModal";

const DocumentsTable = () => {

  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
  const [pdfPreviewData, setPdfPreviewData] = useState(null);
  const [numPages, setNumPages] = useState(0);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchDocuments = async (page) => {
    setLoading(true);
    try {
      let response = await actions.loadDocuments(page, searchTerm);
      console.log({ response, dataSource });
      if (response.status == 200) {
        const data = response.data;
        setDataSource([...dataSource, ...data.results])
        setTotalRows(data.count);
      }
    } catch (e) {

    }
    setLoading(false);
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchDocuments(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchDocuments(p);
      setPageNumber(p);
    }
  }, [searchTerm]);

  const events = {
    onDocumentLoadSuccess: ({ numPages }) => {
      setNumPages(numPages);
    },
  }

  const handleDelete = async (id) => {
    const newData = dataSource.find((item) => item.id === id);
    // setDataSource(newData);
    const response = await actions.deleteDocument(
      newData.id
    );
    console.log(response);
    if (response) {
      setSearchTerm("");
      setDataSource([]);
      setPageNumber(0);
    }
  };

  const viewDownloadDocument = async (record, download, preview) => {
    try {
      const response = await axios.get(record.document_file, {
        responseType: "blob",
      });
      console.log({ response, record });
      if (response.status === 200) {
        if (download) {
          fileDownload(response.data, record.name);
        }
        if (preview) {
          if (record.extension === ".pdf") {
            setPdfPreviewData(response.data);
            setPreviewPdfVisible(true);
          } else {
            notify("error", "only pdfs can be rendered for now");
          }
        }
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  }


  const defaultColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a?.id.localeCompare(b?.id),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => moment(a?.created_at) - moment(b?.created_at),
      render: (text, record) => {
        return <div>{moment(text).format('MMMM Do YYYY, h:mm:ss a')}</div>
      }
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      sorter: (a, b) => a?.org_size - b?.org_size,
    },
    {
      title: 'Linked',
      dataIndex: 'is_linked',
      key: 'is_linked',
      sorter: (a, b) => a?.is_linked - b?.is_linked,
      render: (_, record) => {
        const size = 8;
        const radius = "30%";
        return (
          <div className='d-flex justify-content-center align-items-center'>
            {record.is_linked ? <>
              <div style={{ width: size, height: size, backgroundColor: "green", borderRadius: radius }}>

              </div>
            </> : <>
              <div style={{ width: size, height: size, backgroundColor: "red", borderRadius: radius }}>

              </div>
            </>}
          </div>
        )
      }
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        // console.log(record)
        return dataSource?.length >= 1 ? (
          <Flex gap="middle">
            <div style={{ cursor: "pointer" }} onClick={() => { console.log("View Document"); viewDownloadDocument(record, false, true); }}>
              <EyeOutlined />
            </div>
            <div style={{ cursor: "pointer" }} onClick={() => { console.log("View Document"); viewDownloadDocument(record, true, false); }}>
              <DownloadOutlined />
            </div>
            <Popconfirm title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Flex>
        ) : null
      }
    },
    {
      title: 'Copy link',
      dataIndex: 'clipboard',
      key: 'clipboard',
      render: (_, record) => {
        // console.log(record)
        return (
          <div className='d-flex justify-content-center align-items-center'>
            <CopyOutlined className='cursor-pointer' onClick={() => copyToClipboard(record.document_file)} />
          </div>
        )
      }
    },
  ];

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      notify("success", "Link copied to clipboard");
    } catch (err) {
      notify("error", "Error occurred while copying link");
    }
  }

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const [selectionType, setSelectionType] = useState('checkbox');


  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let p = pageNumber + 1;
      // console.log({ next_p: p, dataSource, totalRows });
      if (p === 1) {
        fetchDocuments(p);
        setPageNumber(p);
      } else if (dataSource && dataSource.length < totalRows) {
        fetchDocuments(p);
        setPageNumber(p);
      }
    }
  };


  return (
    <>
      <div style={{ padding: 10 }}>
        <Flex gap='large' justify='space-between'>
          <Flex gap='large' align='center'>
            <div>
              ({dataSource?.length} / {totalRows})
            </div>

            <div>
              <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
            </div>
          </Flex>
          <Flex gap='large'>
            <Button
              // onClick={handleAdd}
              onClick={() => navigate("/documents/new")}
              icon={<UserAddOutlined />}
              type="primary"
            >
              Add Documents
            </Button>
          </Flex>
        </Flex>
        <Flex>
          <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }} onScroll={handleScroll}>
            <Table
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              bordered
              size="small"
              dataSource={dataSource}
              columns={columns}
              rowKey={row => row.id}
              pagination={false}
              scrollToFirstRowOnChange
              stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
              scroll={{ y: `calc(75vh - 150px)` }}
            />
            <div style={{ margin: 20 }}>{
              loading &&
              <>
                <Spin tip="Loading" size="small">
                  <div className="content" />
                </Spin>
              </>}
            </div>
          </div>
        </Flex>

      </div>

      {/* Pdf Preview Modal Start */}
      <PdfPreviewModal
        numPages={numPages}
        onDocumentLoadSuccess={events.onDocumentLoadSuccess}
        pdfPreviewData={pdfPreviewData}
        previewPdfVisible={previewPdfVisible}
        setPreviewPdfVisible={setPreviewPdfVisible}
      />
      {/* Pdf Preview Modal End */}
    </>
  );
};
export default DocumentsTable;