import { notify } from "components";
import fileDownload from "js-file-download";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

////////////////////////// TEMPLATE - Actions //////////////////////////

export const loadTemplate = async (templateId) => {
  try {
    const response = await axios.get("/template/" + templateId + "/");
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be fetched!");
  }
};

export const templateToPDF = async (templateId) => {
  try {
    const response = await axios.post(
      "/template/" + templateId + "/print_pdf/",
      {},
      {
        responseType: "blob",
      }
    );
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      fileDownload(response.data, "template.pdf");
      // return response.data;
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be downloaded!");
  }
};

export const templateToPDFPreview = async (templateId) => {
  try {
    const response = await axios.post(
      "/template/" + templateId + "/print_pdf/",
      {},
      {
        responseType: "blob",
      }
    );
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      // fileDownload(response.data, "template.html");
      return response.data;
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be downloaded!");
  }
};

export const loadTemplates = async (page, searchValue) => {
  try {
    if (!page) {
      page = 1;
    }
    let url = `/template/?page=${page}&page_size=100`;
    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Templates fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "There was a problem loading Templates!");
  }
};

export const createTemplate = async (data) => {
  try {
    const response = await axios.post(`/template/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 201) {
      notify("success", "Your Template has been saved!");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be saved!");
  }
};

export const updateTemplate = async (templateId, data) => {
  try {
    const response = await axios.put(`/template/${templateId}/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      notify("success", "Updated the template");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template could not be updated!");
  }
};

export const deleteTemplate = async (id) => {
  try {
    const response = await axios.delete(`/template/${id}/`);
    console.log("Response", response);
    notify("success", "Template deleted successfully");
    return "deleted";
  } catch (err) {
    console.log("Error", err);
    notify("error", "Template deleted Error");
  }
};

export const loadTemplateTypes = async () => {
  try {
    const response = await axios.get(`/template/template_types/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Templates fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("error", "There was a problem loading Template Types!");
  }
};
