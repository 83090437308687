import React from 'react';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Outlet, useLocation, Link } from 'react-router-dom';

const NavBreadcrumb = () => {

    const location = useLocation();
    console.log({ location });
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const param2 = params.length > 1 ? params[1] : "";

    return (
        <>
            {!param1 &&
                <Breadcrumb separator=">">
                    <Breadcrumb.Item active><span style={{ marginRight: "5px" }}><HomeOutlined /></span>Home</Breadcrumb.Item>
                </Breadcrumb>
            }
            {
                param1 && !param2 &&
                <Breadcrumb separator=">">
                    <Breadcrumb.Item href="#"><Link to="/"><span style={{ marginRight: "5px" }}><HomeOutlined /></span>Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item
                        href="#"
                    >
                        <Link to={`/${param1}`}>
                            {param1.charAt(0).toUpperCase() + param1.slice(1)}
                        </Link>
                    </Breadcrumb.Item>
                    {/* <Breadcrumb.Item
                        active
                    >
                        Dashboard
                    </Breadcrumb.Item> */}
                </Breadcrumb>
            }
            {
                param2 &&
                <Breadcrumb separator=">">
                    <Breadcrumb.Item href="/"><span style={{ marginRight: "5px" }}><HomeOutlined /></span>Home</Breadcrumb.Item>
                    <Breadcrumb.Item
                        href="#"
                    >
                        <Link to={`/${param1}`}>
                            {param1.charAt(0).toUpperCase() + param1.slice(1)}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        active
                    >
                        {param2.charAt(0).toUpperCase() + param2.slice(1)}
                    </Breadcrumb.Item>
                </Breadcrumb>
            }
        </>
    )

};
export default NavBreadcrumb;