import React from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
const { Option } = Select;

const SendMessageInBulk = () => {


    const onFinish = (values) => {
        console.log('Form values:', values);
    };

    // Example templates for demonstration
    const templates = [
        { id: 1, name: 'Template 1' },
        { id: 2, name: 'Template 2' },
        { id: 3, name: 'Template 3' },
        // Add more templates as needed
    ];

    return (
        <>
            <Row>
                <Col>
                    <Form name="whatsAppForm" onFinish={onFinish} layout="vertical">
                        <Form.Item
                            label="WhatsApp Numbers"
                            name="whatsAppNumber"
                            rules={[
                                { required: true, message: 'Please input the WhatsApp number!' },
                                // Additional validation rules can be added here
                            ]}
                        >
                            <Input placeholder="Enter WhatsApp number" />
                        </Form.Item>

                        <Form.Item
                            label="Select Template"
                            name="template"
                            rules={[{ required: true, message: 'Please select a template!' }]}
                        >
                            <Select placeholder="Select a template">
                                {templates.map((template) => (
                                    <Option key={template.id} value={template.name}>
                                        {template.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default SendMessageInBulk;