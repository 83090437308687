import { AccountsTable } from "components";

const Accounts = () => {



    return (
     <AccountsTable/>     
    );

}
export default Accounts;