import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { loadAccountTypes, loadConstitutionTypes, loadGstTreatmentTypes, loadSalutationChoices } from "store/slices/AccountSlice";
import { loadCountries, loadCurrencies, loadPaymentTerms, loadStates } from "store/slices/MasterSlice";

const AccountsParentPage = () => {

    const dispatch = useDispatch();

    const constitution_types = useSelector(store => store.accounts?.constitution_types);
    const salutation_choices = useSelector(store => store.accounts?.salutation_choices);
    const account_types = useSelector(store => store.accounts?.account_types);
    const gst_treatment_types = useSelector(store => store.accounts?.gst_treatment_types);
    
    const currency_options = useSelector(store => store.master?.currencies);
    const state_options = useSelector(store => store.master?.states);
    const payment_term_options = useSelector(store => store.master?.payment_terms);
    const country_options = useSelector(store => store.master?.countries);

    useEffect(()=>{
        if(!constitution_types || (constitution_types && !constitution_types.choices) ) dispatch(loadConstitutionTypes());
        if(!salutation_choices || (salutation_choices && !salutation_choices.choices)) dispatch(loadAccountTypes());
        if(!account_types || (account_types && !account_types.choices)) dispatch(loadSalutationChoices());
        if(!gst_treatment_types || (gst_treatment_types && !gst_treatment_types.choices)) dispatch(loadGstTreatmentTypes());
        
        if(!currency_options || (Array.isArray(currency_options) && currency_options?.length === 0)) dispatch(loadCurrencies());
        if(!state_options || (Array.isArray(state_options) && state_options?.length === 0)) dispatch(loadStates());
        if(!payment_term_options || (Array.isArray(payment_term_options) && payment_term_options?.length === 0)) dispatch(loadPaymentTerms());
        if(!country_options || (Array.isArray(country_options) && country_options?.length === 0)) dispatch(loadCountries());
        
    }, []);

    return <Outlet />
}

export default AccountsParentPage;


