import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Card, Row, Col, Avatar, Space, App, Typography } from 'antd';
import { login } from 'assets';
import { UserOutlined, CaretUpOutlined, LockOutlined } from '@ant-design/icons';
import { loggingIn } from 'store/slices/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
// import * as authActions from "store/actions/AuthActions";
import { notify } from 'components';

const {Title} = Typography;

const Login = () => {

    // const [loading, setLoading] = useState(false);
    const {status, error} = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const onFinish = async (values) => {
        // console.log('Success:', values);
        dispatch(loggingIn(values));
        // const response = await loggingIn(values);
        // if (response) {

        //     dispatch(loggingIn(response));
        //     notify("success", "Login Successful!", null);
        //     // notification.success({
        //     //     message: `Login Successfull`,
        //     //     description: 'Hello, Ant Design!!',
        //     //     placement: 'topRight',
        //     // });
        // } else {
        //     notify("error", "Login Failed!", null);
        // }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="login-container">
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={{span: 20}} md={{span: 10}} lg={{span: 6}}>
                    <Card>
                        <Title level={3}>Login</Title>
                        <Form
                            name="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please enter your username' }]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Username"
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please enter your password' }]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
export default Login;


