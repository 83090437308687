import React, { useEffect, useState, useRef } from 'react';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SendOutlined,
  SmileFilled
} from '@ant-design/icons';
import { Layout, Menu, theme, List, Avatar, Typography, Flex, Card, Button, Popover, Space, Input, message, Badge } from 'antd';
import * as actions from "store/actions/AuthActions";
import EmojiPicker from 'emoji-picker-react';
import { MdOutlineSmsFailed } from "react-icons/md";

import Message from 'components/WhatsApp/Message';
import { useSearchParams } from 'react-router-dom';


const { TextArea } = Input;

const { Header, Content, Footer, Sider } = Layout;
const { Meta } = Card;

const { Text } = Typography;

const WhatsAppChat = () => {

  const itemRefs = useRef([]);

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [messages, setMessages] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [text, setText] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedContact, setSelectedContact] = useState(searchParams.get("selectedContact"));

  const [activeItemId, setActiveItemId] = useState(null);


  const endOfMessagesRef = useRef(null);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const items = [
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    BarChartOutlined,
    CloudOutlined,
    AppstoreOutlined,
    TeamOutlined,
    ShopOutlined,
  ].map((icon, index) => ({
    key: String(index + 1),
    icon: React.createElement(icon),
    label: `nav ${index + 1}`,
  }));

  const fetchContacts = async (page, searchTerm) => {
    setLoading(true);
    try {

      let response = await actions.loadWAContacts(page, searchTerm);
      console.log({ response, dataSource });
      if (response.status == 200) {
        const data = response.data;
        setDataSource([...dataSource, ...data.results])
        setTotalRows(data.count);
      }
    } catch (err) {
      console.log("Error", err);
      return err;
    }
    setLoading(false);
  }

  const fetchMessages = async (contact) => {
    const response = await actions.loadMessagesForContact(contact);
    if (response.status === 200) {
      const data = response.data;
      setMessages(data.results);
    }
  }

  const sendMessage = async (contact_id) => {
    console.log()
    const response = await actions.sendMessage(selectedContact, "text", text);
    if (response.status === 200) {
      console.log(response);
      setText(null);
    }
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchContacts(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchContacts(p);
      setPageNumber(p);
    }
  }, [searchTerm]);

  useEffect(() => {
    // alert(selectedContact)
    if (selectedContact) {
      try {
        fetchMessages(selectedContact);
      } catch (err) {
        console.log("Error", err);
        return err;
      }
    }
  }, [selectedContact]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  const data = [
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
  ];

  const onEmojiClick = (emojiObject, event) => {
    setText(text + emojiObject.emoji);
  };

  const changeColor = (itemId) => {
    setActiveItemId(itemId);
    setTimeout(() => {
      setActiveItemId(null);
    }, 5000); // Change back after 5 seconds
  };

  const scrollToItem = (id) => {
    // Assuming 'Item to Focus' is the item to scroll to
    const index = messages.findIndex(item => item.wa_message_id === id);
    const itemToFocus = itemRefs.current[index];
    if (itemToFocus) {
      itemToFocus.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  };



  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <Layout
      >
        <Content
          style={{
            margin: '0px 0px'
          }}
        >
          <Layout>
            <Sider
              width={300}
              style={{
                overflow: 'auto',
                height: '85vh',
                left: 0,
                top: 0,
                bottom: 0,
                backgroundColor: "#fcfcfc",
              }}
            >
              <Header
                style={{
                  padding: 0,
                  background: "#ccc",
                }}
              />
              <div className="demo-logo-vertical" />
              <List
                itemLayout="horizontal"
                dataSource={dataSource}
                renderItem={(item, index) => (
                  <Badge.Ribbon text={item?.is_read ? "" : "Unread"} color="volcano" style={{marginRight: 10, fontSize: 10}}>
                    <List.Item style={{ margin: "auto 10px", backgroundColor: selectedContact === item.wa_id ? "#ccc" : "" }} onClick={() => setSelectedContact(item?.wa_id)}>
                      <List.Item.Meta
                        avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=2`} />}
                        title={<a href="#">+{item.wa_id}</a>}
                        description={<Text ellipsis={3} style={{ fontSize: 10 }}>{item?.profile?.name}</Text>}
                      />
                    </List.Item>
                  </Badge.Ribbon>
                )}
              />
            </Sider>
            <Content style={{ padding: '0', minHeight: 280 }}>
              <Header
                style={{
                  padding: 0,
                  background: "#F5EEDF",
                }}
              />
              <Content
                className='chat-background'
                style={{
                  padding: "0px 10px",
                  // backgroundColor: "#eee",
                  overflowY: 'scroll',
                  maxHeight: '74vh',
                }}
              >
                <div
                  id="x"
                  style={{
                    padding: 10,
                  }}
                >
                  {selectedContact && <List
                    dataSource={messages}
                    renderItem={(item, index) => (
                      <List.Item ref={el => itemRefs.current[index] = el}>
                        <Message message={item} activeItemId={activeItemId} changeColor={changeColor} scrollToItem={scrollToItem} ref={el => itemRefs.current[index] = el} />
                      </List.Item>
                    )}
                  // style={{ height: '300px', overflow: 'auto' }}
                  />}
                  <div ref={endOfMessagesRef}></div>
                </div>

              </Content>
              <Footer
                style={{
                  textAlign: 'center',
                  bottom: 0,
                  backgroundColor: "#f0f2f5",
                  display: selectedContact ? 'flex' : 'none'
                }}
              >
                <Flex justify='space-between' align='center'>
                  <div>
                    <Popover content={<EmojiPicker onEmojiClick={onEmojiClick} />} title={"Emoji Picker"} trigger="click">
                      <Button shape="circle" icon={<SmileFilled style={{ fontSize: 20, color: "#777" }} />} />
                    </Popover>
                  </div>
                  <TextArea
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Type a message"
                    defaultValue={text}
                    value={text}
                    style={{
                      height: 40,
                      resize: 'none',
                      fontSize: 18
                    }}
                  />
                  <div>
                    <Button icon={<SendOutlined />} onClick={() => {
                      sendMessage();
                    }}></Button>
                  </div>
                </Flex>
              </Footer>
            </Content>
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
};
export default WhatsAppChat;