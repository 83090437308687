import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    Input,
    Col,
    Row,
    Flex,
    DatePicker,
    Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as adminactions from "store/actions/AuthActions";
import dayjs from 'dayjs';


const LeadTaskForm = ({ leadId, tasks, setTasks, instance, form, reload }) => {

    const status_options = [
        { value: 'OPEN', label: 'OPEN' },
        { value: 'COMPLETED', label: 'COMPLETED' },
    ]

    const onFinish = async (values) => {
        if (instance) {
            const response = await adminactions.updateLeadTask({ ...values, lead: leadId }, instance.id);
            if (response) {
                const restTasks = tasks.filter((task) => task.id !== response.data['id'])
                setTasks([...restTasks, response.data])
                reload();
            }
        } else {
            const response = await adminactions.addLeadTask({ ...values, lead: leadId });
            if (response) {
                setTasks((tasks) => [...tasks, response.data])
                reload();
            }
        }
    };

    useEffect(() => {
        if (instance) {
            const thisInstance = { ...instance }
            let date_fields = ['due_date']
            for (let field of date_fields) {
                thisInstance[field] = thisInstance[field] ? dayjs(thisInstance[field]) : null;
            }
            form.setFieldsValue(thisInstance);
        }
    }, [instance])

    return (
        <Form
            form={form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
        >
            <Row style={{ margin: "15px" }}>
                <Col span={24} style={{}}>
                    <div className="site-card-border-less-wrapper">
                        <div>
                            <Form.Item label="Lead" name='lead' hidden>
                                <Input defaultValue={leadId} />
                            </Form.Item>
                            <Form.Item label="Title" name='title' rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Description" name='description'>
                                <TextArea />
                            </Form.Item>
                            <Form.Item label="Due Date" name="due_date">
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>
                            <Form.Item label="Status" name='status' rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select Status"
                                    options={status_options}
                                />
                            </Form.Item>
                            {/* <Form.Item label="Assigned To" name='assigned_to' rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select User"
                                    options={}
                                />
                            </Form.Item> */}
                        </div>
                        <Flex justify='flex-end'>
                            <Button block type="primary" htmlType="submit" style={{ maxWidth: 100 }} >
                                Save
                            </Button>
                        </Flex>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};
export default LeadTaskForm;