import { NavSider, } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Home = () => {



    return (
        <>
            <Layout
                style={{
                    minHeight: '100vh',
                }}
            >
                <NavSider />
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{
                            padding: 0,
                            backgroundColor:"#001529",
                        }}
                    />
                    <Content
                        style={{
                            margin: '0 16px',
                        }}
                    >

                    </Content>
                    <Footer
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        Teson CRM ©2022 Created by Teson LLP
                    </Footer>
                </Layout>
            </Layout>
        </>
    );

}
export default Home;