import { NavSider, ServicesTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Services = () => {



    return (
        <ServicesTable />
    );

}
export default Services;