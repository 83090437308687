import { NavSider, ProductsTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Products = () => {

    return (
        <ProductsTable />
    );

}
export default Products;