import React, { useState, useEffect } from "react";
import { Button, Flex, Spin, Table, Input, Popconfirm } from "antd";
import { ImportOutlined, SyncOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actions from "store/actions/AuthActions"


const WAMessageTemplates = () => {
    const { Search } = Input;

    const [searchTerm, setSearchTerm] = useState("");

    const [totalRows, setTotalRows] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);

    const [dataSource, setDataSource] = useState([]);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const fetchWATemplates = async (page) => {
        setLoading(true);
        try {

            let response = await actions.loadWATemplates(page, searchTerm);
            console.log({ response, dataSource });
            if (response.status == 200) {
                const data = response.data;
                setDataSource([...dataSource, ...data.results])
                setTotalRows(data.count);
            }
        } catch (e) {

        }
        setLoading(false);
    }

    const refreshWATemplates = async (page) => {
        setLoading(true);
        try {

            let response = await actions.refreshWATemplates(page, searchTerm);
            console.log({ response, dataSource });
            if (response.status == 200) {
                window.location.reload();
                // const data = response.data;
                // setDataSource([...dataSource, ...data.response.data])
                // setTotalRows(data.response.data.length);
            }
        } catch (e) {

        }
        setLoading(false);
    }

    const onSearch = (value) => {
        if (searchTerm === value) {

        } else {
            setSearchTerm(value);
            setDataSource([]);
            setPageNumber(0);
        }
    }

    useEffect(() => {
        let p = pageNumber + 1;
        // console.log({ next_p: p, dataSource, totalRows });
        if (p === 1) {
            fetchWATemplates(p);
            setPageNumber(p);
        } else if (dataSource && dataSource.length < totalRows) {
            fetchWATemplates(p);
            setPageNumber(p);
        }
    }, [searchTerm]);

    const handleDelete = async (id) => {
        const newData = dataSource.find((item) => item.id === id);
        // setDataSource(newData);
        const response = await actions.deleteContacts(
            newData.id
        );
        console.log(response);
        if (response) {
            fetchWATemplates();
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    }


    const defaultColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            sorter: (a, b) => a?.name.localeCompare(b?.name),
        },
        {
            title: 'Template ID',
            dataIndex: 'template_id',
            key: 'template_id',
            width: 100,
            sorter: (a, b) => a?.template_id.localeCompare(b?.template_id),
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
            width: 100,
            sorter: (a, b) => a?.language.localeCompare(b?.language),
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: 100,
            sorter: (a, b) => a?.category.localeCompare(b?.category),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            sorter: (a, b) => a?.status.localeCompare(b?.status),
        },
        {
            title: 'Components',
            dataIndex: 'components',
            key: 'components',
            width: 150,
            render: (_, record) => {
                return (
                    <pre>
                        {JSON.stringify(record?.components, null, 2)}
                    </pre>
                );
            }
            // sorter: (a, b) => a?.client_name.localeCompare(b?.client_name),
        },
        {
            title: 'Formatted Message',
            dataIndex: 'formatted',
            key: 'formatted',
            width: 200,
            render: (_, record) => {
                return (
                    <div>
                        <h4><b>{record.components.find(item => item.type==="HEADER").text}</b></h4>
                        <pre style={{whiteSpace: "pre-wrap"}}>
                            {record.components.find(item => item.type==="BODY").text}
                        </pre>
                        <pre>
                            {record.components.find(item => item.type==="FOOTER").text}
                        </pre>
                    </div>
                );
            }
            // sorter: (a, b) => a?.client_name.localeCompare(b?.client_name),
        }
    ];

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };


    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const [selectionType, setSelectionType] = useState('checkbox');

    // const handleScroll = (e) => {
    //     const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    //     if (bottom) {
    //         fetchData();
    //     }
    // };

    // const handleScroll = (e) => {
    //     const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    //     if (bottom) {
    //         let p = pageNumber + 1;
    //         // console.log({ next_p: p, dataSource, totalRows });
    //         if (p === 1) {
    //             fetchWATemplates(p);
    //             setPageNumber(p);
    //         } else if (dataSource && dataSource.length < totalRows) {
    //             fetchWATemplates(p);
    //             setPageNumber(p);
    //         }
    //     }
    // };


    return (
        <div style={{ padding: 10 }}>
            <Flex gap='large' justify='space-between'>
                <Flex gap='large' align='center'>
                    <div>
                        ({dataSource?.length} / {totalRows})
                    </div>

                    <div>
                        <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
                    </div>
                </Flex>
                <Flex gap='large'>
                    <Button
                        // onClick={handleAdd}
                        onClick={()=> {
                            refreshWATemplates();
                        }}
                        icon={<SyncOutlined />}
                        type="primary"
                    >
                        Refresh Templates
                    </Button>
                </Flex>
            </Flex>
            <Flex>
                <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }}>
                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection,
                        }}
                        bordered
                        size="small"
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={row => row.id}
                        pagination={false}
                        scrollToFirstRowOnChange
                        stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                        scroll={{ y: `calc(75vh - 150px)` }}
                    />
                    <div style={{ margin: 20 }}>{
                        loading &&
                        <>
                            <Spin tip="Loading" size="small">
                                <div className="content" />
                            </Spin>
                        </>}
                    </div>
                </div>
            </Flex>
        </div>
    );
}

export default WAMessageTemplates;