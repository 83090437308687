import React, { useEffect, useState } from "react";
import { Form, Input, TextArea, Button, Switch, Typography, QRCode } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "store/actions/UserActions";

const { Title } = Typography;

const Settings = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm(null);
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.auth.user);
    const user_setting = useSelector((state) => state.userReducer?.user_setting);
    const [useUserEmail, setUseUserEmail] = useState(user_setting ? user_setting.use_user_email : false)

    useEffect(() => {
        if (user_setting) {
            form.setFieldsValue({
                use_user_email: user_setting?.use_user_email,
                host: user_setting?.host,
                port: user_setting?.port,
                username: user_setting?.username,
                password: user_setting?.password,
            })
        }
    }, [user_setting])

    const handleSetting = (values) => {
        if (user_setting) {
            // dispatch(userActions.updateSetting({ ...values, id: user_setting?.id }))
        } else {
            // dispatch(userActions.createSetting({ ...values, user: user.id }))
        }
    };

    const [showLoginQR, setShowLoginQR] = useState(false);
    return (
        <>
            <Title level={3}>SMTP Information</Title>
            <Form
                labelCol={{ span: 4, }}
                wrapperCol={{ span: 14, }}
                layout="horizontal"
                style={{ maxWidth: 600, }}
                initialValues={user_setting}
                onFinish={handleSetting}
                form={form}
            // disabled={useUserEmail}
            >
                {/* <Form.Item label="Use User Email" name="use_user_email">
                    <Switch defaultChecked={user_setting?.use_user_email} onChange={() => setUseUserEmail(!useUserEmail)} />
                </Form.Item> */}
                <Form.Item label="Host" name="host">
                    <Input disabled={useUserEmail} />
                </Form.Item>
                <Form.Item label="Port" name="port">
                    <Input disabled={useUserEmail} />
                </Form.Item>
                <Form.Item label="Username" name="username">
                    <Input disabled={useUserEmail} />
                </Form.Item>
                <Form.Item label="Password" name="password">
                    <Input disabled={useUserEmail} />
                </Form.Item>
                <Form.Item label="Use TLS" name="use_tls">
                    <Switch defaultChecked={user_setting?.use_tls} disabled={useUserEmail} />
                </Form.Item>
                <Form.Item label="Use SSL" name="use_ssl">
                    <Switch defaultChecked={user_setting?.use_ssl} disabled={useUserEmail} />
                </Form.Item>
                <hr />
                <Form.Item wrapperCol={{ offset: 4 }}>
                    <Button htmlType="submit">Save Settings</Button>
                </Form.Item>
            </Form>
            {/* <Title level={3}>Authentication</Title>
            <>
                Show Login QR: <Switch onChange={setShowLoginQR} />
            </>
            {showLoginQR &&
                <div>
                    <div id="login_qr" style={{ padding: 20, backgroundColor: "white" }}>
                        <QRCode value={`${token}`} size={256} bgColor='white' />
                    </div>
                </div>
            } */}
        </>
    )

}

export default Settings;