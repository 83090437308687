import React, { useState } from "react";
import { Select, Tag, Form } from "antd";

// Use
// <EmailInput label="Email" name="email" required message="Please enter emails"/>

const EmailInput = ({
  label,
  name,
  required = false,
  message = "This field is required.",
  defaultValue = [],
}) => {
  const [emails, setEmails] = useState([]);

  const handleClose = (removedEmail) => {
    const newEmails = emails.filter((email) => email !== removedEmail);
    setEmails(newEmails);
  };

  const emailValidation = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleChange = (value, actionMeta) => {
    if (actionMeta.action === "remove") {
      handleClose(actionMeta.removedValue);
    } else {
      const newEmails = value.filter((email) => emailValidation(email));
      setEmails(newEmails);
    }
  };

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: required, message: message }]}
      initialValue={defaultValue}
    >
      <Select
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Enter emails"
        // value={emails}
        onChange={handleChange}
        tokenSeparators={[","]}
      />
    </Form.Item>
  );
};

export default EmailInput;
