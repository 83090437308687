import { NavSider, QuotesTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Quotes = () => {

    return (
        <QuotesTable />
    );

}
export default Quotes;