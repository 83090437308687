import { NavSider,TemplatesTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Templates = () => {



    return (
        <TemplatesTable/>
    );

}
export default Templates;