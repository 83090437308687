import { Modal, Flex } from "antd";
import { Document, Page } from "react-pdf";
import { ZoomInOutlined, ZoomOutOutlined, UndoOutlined } from '@ant-design/icons';
import { useState } from "react";

const PdfPreviewModal = ({ previewPdfVisible, setPreviewPdfVisible, pdfPreviewData, onDocumentLoadSuccess, numPages }) => {
    const [zoomVal, setZoomVal] = useState(1.0);
    return (
        <Modal
            // aria-labelledby="contained-modal-title-vcenter"
            onCancel={() => {
                setPreviewPdfVisible(false)
                setZoomVal(1.0)
            }}
            title="PDF Preview"
            centered
            open={previewPdfVisible}
            footer={null}
            width={'100%'}
        >
            <Flex justify="center">
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <ZoomOutOutlined style={{ fontSize: "200%" }} className="cursor-pointer" onClick={() => { setZoomVal(zoomVal - 0.25) }} />
                    <UndoOutlined style={{ fontSize: "200%" }} className="cursor-pointer" onClick={() => { setZoomVal(1.0) }} />
                    <ZoomInOutlined style={{ fontSize: "200%" }} className="cursor-pointer" onClick={() => { setZoomVal(zoomVal + 0.25) }} />
                </div>
            </Flex>
            <Flex
                justify="center"
                style={{ backgroundColor: "#999" }}
            >
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Document
                        file={pdfPreviewData}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {[...Array(numPages).keys()].map((item, index) => {
                            return (
                                <div style={{ marginTop: "10px" }}>
                                    <Page
                                        key={index + 1}
                                        size="A4"
                                        pageNumber={index + 1}
                                        wrap={false}
                                        scale={zoomVal}
                                    />
                                </div>
                            );
                        })}
                    </Document>
                </div>
            </Flex>
        </Modal>
    )
}

export default PdfPreviewModal;