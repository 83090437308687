import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    Input,
    Col,
    Row,
    Flex,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as adminactions from "store/actions/AuthActions";


const ProductForm = ({ instance, form, reload }) => {

    const onFinish = async (values) => {
        if (instance) {
            const response = await adminactions.updateProducts(
                values, instance.id
            );
            console.log(response);
            if (response) {
                reload();
            }
        } else {
            const response = await adminactions.addProducts(
                values
            );
            if (response) {
                reload();;
            }
        }
    };

    useEffect(() => {
        if (instance) {
            form.setFieldsValue(instance);
        }
    }, [instance])

    return (
        <Form
            form={form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
        >
            <Row style={{ margin: "15px" }}>
                <Col span={24} style={{}}>
                    <div className="site-card-border-less-wrapper">
                        <div
                        >
                            <Form.Item label="Title" name='title' rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="HSN/SAC" name='hsn' >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Notes" name='notes'>
                                <TextArea />
                            </Form.Item>
                            <Form.Item label="Price" name='price' >
                                <Input />
                            </Form.Item>
                        </div>
                        <Flex justify='flex-end'>
                            <Button block type="primary" htmlType="submit" style={{ maxWidth: 100 }} >
                                Save
                            </Button>
                        </Flex>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};
export default ProductForm;