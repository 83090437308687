import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Form,
    Input,
    Switch,
    Col,
    Row,
    Card,
    Tabs,
} from 'antd';

import * as adminactions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';

const onChange = (key) => {
    console.log(key);
};

const EditUsers = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState(null);
    const [loading, setLoading] = useState(true);

    const onFinish = (values) => {
        console.log('Success:', values);
        if (values) {
            onSaveHandler(values)
        }
    };


    const fetchUsers = async () => {
        if(id){
            const response = await adminactions.fetchUsers(id);
            console.log(response)
            if (response) {
                console.log("Changing state now...")
                setDataSource(response);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    const onSaveHandler = async (values) => {
        if(id){
            const response = await adminactions.updateUsers(
                values, id
            );
            console.log(response);
            if (response) {
                navigate("/users");
            }
        } else {
            const response = await adminactions.addUsers(
                values
            );
            if(response){
                navigate("/users");
            }
        }
    }


    return (
        <>
            {!loading &&
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                    initialValues={dataSource}
                >
                    <Row
                        style={{ margin: "15px" }}
                    >
                        <Col span={24} style={{}}>
                            <div className="site-card-border-less-wrapper">
                                <Card title="Details" bordered={false} style={{}}>
                                    <Tabs
                                        defaultActiveKey="1"
                                        onChange={onChange}
                                        items={[
                                            {
                                                label: `User Details`,
                                                key: '1',
                                                children:
                                                    (<div
                                                    >
                                                        <Form.Item label="Username" name='username' rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="First Name" name='first_name'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Last Name" name='last_name' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Email" name='email' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Phone" name='mobile' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Groups" name='groups' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="User Permisiions" name='user_permissions' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Is Staff" name='is_staff' valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                        <Form.Item label="Is Active" name='is_active' valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                    </div>),
                                            },
                                            // {
                                            //     label: `More Details`,
                                            //     key: '2',
                                            //     children:
                                            //         (<div
                                            //         >
                                            //             <Form.Item label="Bank Account Number" name='bank_account_number'>
                                            //                 <Input />
                                            //             </Form.Item>
                                            //             <Form.Item label="Bank Name" name='bank_name'>
                                            //                 <Input />
                                            //             </Form.Item>
                                            //             <Form.Item label="IFSC Code" name='bank_ifsc'>
                                            //                 <Input />
                                            //             </Form.Item>
                                            //             <Form.Item label="Bank Branch" name='bank_branch'>
                                            //                 <Input />
                                            //             </Form.Item>
                                            //         </div>),
                                            // },
                                        ]}
                                    />
                                    <div style={{ margin: "15px" }}>
                                        <Button block type="primary" htmlType="submit" >
                                            Save
                                        </Button>
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Form>}
        </>
    );
};
export default EditUsers;