import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import authReducer from "./slices/AuthSlice";
import accountsReducer from "./slices/AccountSlice";
import vendorsReducer from "./slices/VendorSlide";
import invoicesReducer from "./slices/InvoiceSlice";
import paymentsReducer from "./slices/PaymentSlice";
import generalReducer from "./slices/GeneralSlice";
import masterReducer from "./slices/MasterSlice";

const persistConfig = {
    key: 'auth',
    storage,
    whitelist: ['auth'], // only persist the 'auth' slice of the store
    stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
    auth: authReducer,
    // user: userReducer, // Note: renamed userReducer to user in the root state
    accounts: accountsReducer,
    vendors: vendorsReducer,
    invoices: invoicesReducer,
    payments: paymentsReducer,
    general: generalReducer,
    master: masterReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production", // Enables Redux DevTools in development
});

const persistor = persistStore(store);

export { store, persistor };
