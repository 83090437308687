import React, { useEffect, useState } from "react";
import { message, Popconfirm, Select, Table, Typography, Upload } from 'antd';
import { InboxOutlined, CloseOutlined } from '@ant-design/icons';
import md5 from 'js-md5';
import axios from "axios";
const { Dragger } = Upload;


const DraggerBox = ({ mode }) => {

    const [mode_url, set_mode_url] = useState("/document/document");

    useEffect(() => {
        if (mode === "assetfiles") {
            set_mode_url("/assetfile/assetfile");
        }
    }, []);

    const readChunkAsText = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target.result);
            };

            reader.onerror = (event) => {
                reject(event.target.error);
            };

            reader.readAsText(blob);
        });
    };

    const readFileAsArrayBuffer = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target.result);
            };

            reader.onerror = (event) => {
                reject(event.target.error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const calculateMD5Hash = async (file) => {
        const chunkSize = 1024 * 1024; // 1 MB chunks (adjust as needed)
        const totalSize = file.size;
        const totalChunks = Math.ceil(totalSize / chunkSize);
        let hash = md5.create();
        for (let chunk = 0; chunk < totalChunks; chunk++) {
            const start = chunk * chunkSize;
            const end = Math.min(start + chunkSize, totalSize);
            const chunkData = file.slice(start, end);

            // const text = await readChunkAsText(chunkData);
            const response = await readFileAsArrayBuffer(chunkData);
            // console.log(md5(response));
            hash.update(response)
        }
        console.log(hash.hex());
        const hashString = hash.hex();
        return hashString;
    };

    // const calculateFileMD5 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();

    //         reader.onload = (event) => {
    //             const fileData = event.target.result;
    //             const crypto = window.crypto;
    //             if (crypto.subtle) {
    //                 crypto.subtle.digest("MD5", new TextEncoder().encode(fileData))
    //                     .then((hashBuffer) => {
    //                         const hashArray = Array.from(new Uint8Array(hashBuffer));
    //                         const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    //                         resolve(hashHex);
    //                     })
    //                     .catch(reject);
    //             } else {
    //                 // Handle older browsers that do not support the Web Crypto API
    //                 reject(new Error("Web Crypto API not supported"));
    //             }
    //         };

    //         reader.onerror = reject;

    //         reader.readAsArrayBuffer(file);
    //     });
    // };

    const upload_file = async ({ file, onSuccess, onError, onProgress }) => {
        // console.log(x);
        const hash = await calculateMD5Hash(file);
        // console.log(hash);
        // console.log(file)
        try {

            const chunkSize = 1024 * 1024; // 1 MB chunks (adjust as needed)
            const totalChunks = Math.ceil(file.size / chunkSize);

            let fileId = null;
            const lastModified = Date.now();
            const empty_blob_file = new File([], file.name, { lastModified });

            const formData = new FormData();
            formData.set('file', empty_blob_file);
            const response = await axios.post(process.env.REACT_APP_BASE_URL + mode_url + "/upload_document/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                console.log(response.data);
                fileId = response.data.id;
            }
            formData.delete('file');
            console.log(totalChunks);
            let uploadedSize = 0;
            const totalSize = file.size;
            for (let chunk = 0; chunk < totalChunks; chunk++) {
                const start = chunk * chunkSize;
                const end = Math.min(start + chunkSize, totalSize);
                const chunkData = file.slice(start, end);
                uploadedSize += 1024 * 1024;
                formData.set('chunk', chunkData);
                formData.set('status', 'uploading');
                formData.set('last_chunk', false);
                if (fileId != null) {
                    formData.set('id', fileId);
                }

                console.log(chunk, totalChunks);

                try {
                    if (chunk == 0) {
                        formData.set('status', 'upload_started');
                    }

                    if (chunk == (totalChunks - 1)) {
                        formData.set('last_chunk', true);
                        formData.set('client_hash', hash);
                    }

                    const response = await axios.post(process.env.REACT_APP_BASE_URL + mode_url + "/" + fileId + "/receive_and_merge_chunks/", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    if (response.status === 200) {
                        if (chunk == (totalChunks - 1)) {
                            // return response.data;
                            onSuccess('File uploaded successfully', file);
                        } else {
                            const percent = (uploadedSize / totalSize) * 100;
                            console.log(percent);
                            onProgress({ percent });
                        }
                    } else {
                        console.error('Error uploading chunk', "Error uploading chunk.");
                        onError("Error uploading chunk");
                        break;
                    }
                } catch (error) {
                    console.error('Error uploading chunk', error);
                    onError(error);
                    break;
                    // Handle errors or retry logic as needed
                }

                formData.delete('chunk');
            }


        } catch (e) {
            onError(e);
        }
    }

    const props = {
        name: 'file',
        multiple: true,
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
        // action: process.env.REACT_APP_BASE_URL + mode_url + "/upload_document/",
        customRequest: async ({ file, onSuccess, onError, onProgress }) => await upload_file({ file, onSuccess, onError, onProgress }),
        // customRequest: (x) => console.log(x),
        onChange(info) {
            console.log(info);
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        success(response) {
            console.log(response);
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <div>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                    band files
                </p>
            </Dragger>
        </div>

    );
}

export default DraggerBox;