import React, { useEffect, useState } from "react";
import { Form, Input, DatePicker, Button, Row, Col, Popconfirm, Upload, Switch, Flex, Card, Tabs, Table, message, Typography, Drawer } from 'antd';
import { EditOutlined, DeleteOutlined, InboxOutlined, EyeOutlined, DownloadOutlined } from "@ant-design/icons"
import * as actions from "store/actions/AuthActions";
import { useNavigate, useParams } from "react-router-dom";
import fileDownload from "js-file-download";
import { useNotification } from 'NotificationContext';
import PdfPreviewModal from 'components/PdfPreviewModal/PdfPreviewModal';
import LeadActivityForm from "./LeadActivityForm";
import LeadTaskForm from "./LeadTaskForm";

import dayjs from 'dayjs';
import axios from 'axios';
import moment from 'moment';

const { Dragger } = Upload;

const LeadForm = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [attachments, setAttachments] = useState([]);
    const [leadActivities, setLeadActivities] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);

    const [activityForm] = Form.useForm();
    const [openActivityForm, setOpenActivityForm] = useState(false);
    const [currentActivityInstance, setCurrentActivityInstance] = useState(null);
    const [taskForm] = Form.useForm();
    const [openTaskForm, setOpenTaskForm] = useState(false);
    const [currentTaskInstance, setCurrentTaskInstance] = useState(null);

    const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
    const [pdfPreviewData, setPdfPreviewData] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const notify = useNotification();

    const events = {
        onDocumentLoadSuccess: ({ numPages }) => {
            setNumPages(numPages);
        },
    }

    const saveLead = async (values) => {
        console.log('Received values:', values);
        setLoading(true);
        if (id) {
            const response = await actions.updateLeads(values, id);
        } else {
            const response = await actions.addLeads(values);
            if (response.status === 201) {
                navigate('/leads/edit/' + response.data['id']);
            }
        }
        setLoading(false);
    };

    const handleActivityDelete = async (id) => {
        const response = await actions.deleteLeadActivity(id);
        if (response) {
            const restAct = leadActivities.filter((act) => act.id !== id)
            setLeadActivities(restAct)
        }
    }

    const handleTaskDelete = async (id) => {
        const response = await actions.deleteLeadTask(id);
        if (response) {
            const restTasks = tasks.filter((task) => task.id !== id)
            setTasks(restTasks)
        }
    }

    const boldDividerStyle = {
        fontWeight: 'bold',
        fontSize: '18px', // Optional: Adjust the font size as needed
    };

    const loadLead = async () => {
        if (id) {
            const response = await actions.retrieveLead(id);
            if (response) {
                const thisInstance = { ...response }
                let date_fields = ['converted_at', 'last_contacted_at']
                for (let field of date_fields) {
                    thisInstance[field] = thisInstance[field] ? dayjs(thisInstance[field]) : null;
                }
                form.setFieldsValue(thisInstance);
                setAttachments(response?.attachments);
                setLeadActivities(response?.lead_activities);
                setTasks(response?.tasks);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (id) {
            loadLead()
        }
    }, [id]);

    const props = {
        name: 'file',
        multiple: false,
        action: process.env.REACT_APP_BASE_URL + `/lead/leads/${id}/addAttachment/`,
        headers: { "Authorization": axios.defaults.headers.common['Authorization'] },
        onChange(info) {
            console.log(info);
            const { status, response } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }

            if (response) {
                setAttachments(response);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const viewDownloadDocument = async (record, preview, download) => {
        try {
            const response = await axios.get(record.document['document_file'], {
                responseType: "blob",
            });
            // console.log({ response, record });
            if (response.status === 200) {
                if (download) {
                    fileDownload(response.data, record.document['name']);
                }
                if (preview) {
                    if (record.document['extension'] === ".pdf") {
                        setPdfPreviewData(response.data);
                        setPreviewPdfVisible(true);
                    } else {
                        notify("error", "only pdfs can be rendered for now");
                    }
                }
            }
        } catch (err) {
            console.log("Error: ", err);
        }
    }

    const handleDeleteAttachment = async (doc_id) => {
        const response = await actions.removeDocumentFromLeads(id, doc_id);
        if (response) {
            setAttachments(response);
        }
    }

    const attachmentColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a?.id.localeCompare(b?.id),
        },
        {
            title: 'Name',
            dataIndex: ["document", "name"],
            key: 'name',
            sorter: (a, b) => a?.name.localeCompare(b?.name),
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => moment(a?.created_at) - moment(b?.created_at),
            render: (text, record) => {
                return <div>{moment(text).format('MMMM Do YYYY, h:mm:ss a')}</div>
            }
        },
        {
            title: 'Size',
            dataIndex: ["document", "size"],
            key: 'size',
            sorter: (a, b) => a?.org_size - b?.org_size,
        },
        {
            title: 'Linked',
            dataIndex: ["document", "is_linked"],
            key: 'is_linked',
            sorter: (a, b) => a?.is_linked - b?.is_linked,
            render: (_, record) => {
                const size = 8;
                const radius = "30%";
                return (
                    <div className='d-flex justify-content-center align-items-center'>
                        {record.is_linked ? <>
                            <div style={{ width: size, height: size, backgroundColor: "green", borderRadius: radius }}>
                            </div>
                        </> : <>
                            <div style={{ width: size, height: size, backgroundColor: "red", borderRadius: radius }}>
                            </div>
                        </>}
                    </div>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (_, record) => {
                return (
                    <Flex gap="middle">
                        <Typography.Link onClick={() => { console.log("View Document"); viewDownloadDocument(record, true, false); }}>
                            <EyeOutlined />
                        </Typography.Link>
                        <Typography.Link onClick={() => { console.log("Download Document"); viewDownloadDocument(record, false, true); }}>
                            <DownloadOutlined />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteAttachment(record.id)}>
                            <DeleteOutlined />
                        </Popconfirm>
                    </Flex>
                );
            },
        },
    ];

    const leadActivityColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a?.id.localeCompare(b?.id),
        },
        {
            title: 'Type',
            dataIndex: 'activity_type',
            key: 'activity_type',
            sorter: (a, b) => a?.activity_type.localeCompare(b?.activity_type),
        },
        {
            title: 'Date',
            dataIndex: 'activity_date',
            key: 'activity_date',
            sorter: (a, b) => moment(a?.activity_date) - moment(b?.activity_date),
            render: (text, record) => {
                return <div>{moment(text).format('MMMM Do YYYY, h:mm:ss a')}</div>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a?.status - b?.status,
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            sorter: (a, b) => a?.notes - b?.notes,
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (_, record) => {
                return (
                    <Flex gap="middle">
                        <a>
                            <EditOutlined
                                style={{ verticalAlign: 'middle' }}
                                onClick={() => {
                                    setCurrentActivityInstance(record)
                                    setOpenActivityForm(true)
                                }}
                            />
                        </a>
                        <Popconfirm title="Sure to delete?"
                            onConfirm={() => handleActivityDelete(record.id)}
                        >
                            <a>
                                <DeleteOutlined />
                            </a>
                        </Popconfirm>
                    </Flex>
                );
            },
        },
    ];

    const taskColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a?.id.localeCompare(b?.id),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a?.title.localeCompare(b?.title),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a?.description - b?.description,
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            sorter: (a, b) => moment(a?.due_date) - moment(b?.due_date),
            render: (text, record) => {
                return <div>{moment(text).format('MMMM Do YYYY, h:mm:ss a')}</div>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a?.status - b?.status,
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (_, record) => {
                return (
                    <Flex gap="middle">
                        <a>
                            <EditOutlined
                                style={{ verticalAlign: 'middle' }}
                                onClick={() => {
                                    setCurrentTaskInstance(record)
                                    setOpenTaskForm(true)
                                }}
                            />
                        </a>
                        <Popconfirm title="Sure to delete?"
                            onConfirm={() => handleTaskDelete(record.id)}
                        >
                            <a>
                                <DeleteOutlined />
                            </a>
                        </Popconfirm>
                    </Flex>
                );
            },
        },
    ];

    const tabElements = [
        {
            label: `Lead Activities`,
            key: '1',
            children:
                <div>
                    <Flex justify="end">
                        <Button
                            onClick={setOpenActivityForm}
                            type="primary"
                            style={{ marginBottom: 16 }}
                        >
                            Add Item
                        </Button>
                    </Flex>
                    <Table
                        dataSource={leadActivities}
                        columns={leadActivityColumns}
                        rowClassName="editable-row"
                        size="small"
                        pagination={false}
                    />
                </div>,
        },
        {
            label: `Attachments`,
            key: '2',
            children:
                <div>
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                    </Dragger>
                    <Table
                        dataSource={attachments}
                        columns={attachmentColumns}
                        rowClassName="editable-row"
                        size="small"
                        pagination={false}
                    />
                </div>,
        },
        {
            label: `Tasks`,
            key: '3',
            children:
                <div>
                    <Flex justify="end">
                        <Button
                            onClick={setOpenTaskForm}
                            type="primary"
                            style={{ marginBottom: 16 }}
                        >
                            Add Item
                        </Button>
                    </Flex>
                    <Table
                        dataSource={tasks}
                        columns={taskColumns}
                        rowClassName="editable-row"
                        size="small"
                        pagination={false}
                    />
                </div>,
        },
    ]

    return (
        <>
            <div className='custom-scroll-div' style={{ overflowY: "scroll", maxHeight: "90vh", padding: 10, paddingBottom: 30 }}>
                <Form form={form} onFinish={saveLead} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign="left">
                    <Flex justify='end' gap='middle' style={{ margin: "10px auto" }}>
                        <Button type="primary" htmlType="submit" >
                            Save
                        </Button>
                    </Flex>
                    <Row>
                        <Col span={12}>
                            <Card title="Personal Information" bordered={false}>
                                <Form.Item label="First Name" name="first_name" rules={[{ required: true, message: 'Please input your first name!' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Last Name" name="last_name">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Email" name="email">
                                    <Input type="email" />
                                </Form.Item>
                                <Form.Item label="Phone Number" name="phone_number">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="WhatsApp Number" name="wa_number">
                                    <Input />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={12} style={{ padding: "0px 0px 10px 10px" }}>
                            <Card title="Contact Information" bordered={false}>
                                <Form.Item label="Company Name" name="company_name">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Job Title" name="job_title">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Source" name="source">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Communication Method" name="communication_method">
                                    <Input />
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ padding: "10px 0px 0px 0px" }}>
                            <Card title="Communication Log" bordered={false}>
                                <Form.Item label="Last Contacted At" name="last_contacted_at">
                                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                                </Form.Item>
                                <Form.Item label="Notes" name="notes">
                                    <Input.TextArea />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={12} style={{ padding: "0px 0px 10px 10px" }}>
                            <Card title="Conversion Details" bordered={false}>
                                <Form.Item label="Converted At" name="converted_at">
                                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                                </Form.Item>
                                <Form.Item label="Converted Product" name="converted_product">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Converted Value" name="converted_value">
                                    <Input type="number" />
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                    {id && <Row style={{ padding: "10px 0px 0px 0px" }}>
                        <Col span={24}>
                            {id && <div className="site-card-border-less-wrapper">
                                <Card bordered={false}>
                                    <Tabs
                                        defaultActiveKey="1"
                                        items={tabElements}
                                    />
                                </Card>
                            </div>}
                        </Col>
                    </Row>}
                </Form>
                <PdfPreviewModal
                    numPages={numPages}
                    onDocumentLoadSuccess={events.onDocumentLoadSuccess}
                    pdfPreviewData={pdfPreviewData}
                    previewPdfVisible={previewPdfVisible}
                    setPreviewPdfVisible={setPreviewPdfVisible}
                />
            </div>
            <Drawer
                title={`${currentActivityInstance ? 'Edit' : 'Add'} Lead Activity`}
                placement="right"
                width={"35%"}
                onClose={() => {
                    setCurrentActivityInstance(null)
                    setOpenActivityForm(false)
                    activityForm.resetFields();
                }}
                open={openActivityForm}
                style={{ zIndex: 10 }}
            >
                <LeadActivityForm
                    leadId={id}
                    leadActivities={leadActivities}
                    setLeadActivities={setLeadActivities}
                    instance={currentActivityInstance}
                    form={activityForm}
                    reload={() => {
                        setCurrentActivityInstance(null)
                        setOpenActivityForm(false)
                        activityForm.resetFields();
                    }}
                />
            </Drawer>
            <Drawer
                title={`${currentTaskInstance ? 'Edit' : 'Add'} Task`}
                placement="right"
                width={"35%"}
                onClose={() => {
                    setCurrentTaskInstance(null)
                    setOpenTaskForm(false)
                    taskForm.resetFields();
                }}
                open={openTaskForm}
                style={{ zIndex: 10 }}
            >
                <LeadTaskForm
                    leadId={id}
                    tasks={tasks}
                    setTasks={setTasks}
                    instance={currentTaskInstance}
                    form={taskForm}
                    reload={() => {
                        setCurrentTaskInstance(null)
                        setOpenTaskForm(false)
                        taskForm.resetFields();
                    }}
                />
            </Drawer>
        </>
    );
}

export default LeadForm;