import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, Popconfirm, Table, Input, Spin, Drawer } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import moment from 'moment';
import useScrollBottom from 'CustomHooks/useScrollBottom';
import { useSelector } from 'react-redux';
import { message } from 'antd/es';
import { Dropdown } from 'antd/lib';
import axios from 'axios';

const getItemsForDropDown = (record, navigate, handleDelete, copyPaymentLinkToNavigator) => (
  [
    {
      key: '1',
      label: (
        <a href={`/quotes/edit/${record.id}/`}>
          <EditOutlined
            style={{
              verticalAlign: 'middle',
            }}
          />
        </a>
      ),
    },
    {
      key: "2",
      label: (<Popconfirm title="Sure to delete?"
        onConfirm={() => handleDelete(record.id)}
      >
        <a>
          <DeleteOutlined />
        </a>
      </Popconfirm>)
    },
    {
      key: "3",
      label: (<a href={`/payments?search=${record.inv_number}`}>
        Payments
      </a>)
    },
    // {
    //   key: "4",
    //   label: (<span onClick={() => copyPaymentLinkToNavigator(record)}>
    //     Copy Payment Link
    //   </span>)
    // }
  ]
)

const QuotesTable = () => {

  const [messageApi, contextHolder] = message.useMessage();
  const organization = useSelector(state => state.auth.organization);
  const searchRef = useRef();

  const bottom = useScrollBottom();
  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermLive, setSearchTermLive] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [dataSource, setDataSource] = useState();
  const [quoteStatuses, setQuoteStatuses] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedQuote, setSelectedQuote] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const navigate = useNavigate();

  const fetchQuotes = async (page, startFresh) => {
    let searchTermToSend = searchTerm;
    if (startFresh) {
      setSearchTerm("");
      setSearchTermLive("");
      searchTermToSend = "";
    }
    let response = await actions.loadQuotes(page, searchTermToSend, organization);
    console.log({ response, dataSource });
    if (response && response.results && response.results.length > 0) {
      response.results = response.results.map((r, i) => {
        if (r.client_data) {
          r.client_name = r.client_data.name;
        }
        if (r.total) {
          r.total_str = '₹ ' + r.total.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }
        return r;
      });
    }
    if (dataSource && dataSource.length > 0 && !startFresh) {
      const toPut = [];
      const pushedIds = [];
      dataSource.forEach((d, i) => {
        if (!pushedIds.includes(d.id)) {
          pushedIds.push(d.id);
          toPut.push(d);
        }
      });
      response.results.forEach((rr, i) => {
        if (!pushedIds.includes(rr.id)) {
          pushedIds.push(rr.id);
          toPut.push(rr);
        }
      })
      setDataSource(toPut);
    } else {
      setTotalRows(response.count || "0");
      setDataSource(response.results);
    }
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  const loadQuoteStatuses = async () => {
    const response = await actions.loadQuoteStatuses();
    if (response) {
      setQuoteStatuses(response);
    }
  }

  useEffect(() => {
    // fetchQuotes();
    loadQuoteStatuses();
  }, []);

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchQuotes(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchQuotes(p);
      setPageNumber(p);
    }
  }, [bottom, searchTerm]);

  useEffect(() => {
    console.log({ pageNumber, searchTerm, dataSource });
    fetchQuotes(1, true);
  }, [organization]);

  const handleDelete = async (id) => {
    const newData = dataSource.find((item) => item.id === id);
    // setDataSource(newData);
    const response = await actions.deleteQuotes(
      newData.id
    );
    console.log(response);
    if (response) {
      fetchQuotes();
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  }

  const handleRowClick = (record) => {
    setSelectedQuote(record); // Set selected invoice
    setDrawerVisible(true); // Open drawer
    setPdfUrl(`${axios.defaults.baseURL}/quotes/quotes/${record.id}/quoteView/`);
  };

  const copyPaymentLinkToNavigator = (record) => {
    const link = `https://teson.in/invoice/${record.id}`;
    navigator.clipboard.writeText(link);
    messageApi.open({
      type: 'success',
      content: `${link} copied to clipboard.`,
    });
  }


  const defaultColumns = [
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      filters: quoteStatuses.map((is, i) => {
        return {
          text: is[0],
          value: is[0]
        }
      }),
      sorter: (a, b) => a?.status.localeCompare(b?.status),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'NUMBER',
      dataIndex: 'qt_number',
      key: 'qt_number',
      sorter: (a, b) => a?.qt_number.localeCompare(b?.qt_number),
    },
    {
      title: 'ACCOUNT',
      dataIndex: ["account_data", "name"],
      key: 'account_name',
      width: '20%',
      sorter: (a, b) => a?.account_name.localeCompare(b?.account_name),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'total_payable',
      key: 'total_payable',
      sorter: (a, b) => a?.total_payable - b?.total_payable,
      align: 'right',
      render: (value, record) => (
        <span>{record.currency?.iso_code || 'INR'} {value}</span>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'quote_date',
      key: 'quote_date',
      align: 'right',
      sorter: (a, b) => moment(a?.quote_date) - moment(b?.quote_date),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      align: 'right',
      sorter: (a, b) => moment(a?.expiry_date) - moment(b?.expiry_date),
      render: (expiry_date, record) => {
        const isExpired = moment().isAfter(moment(expiry_date));
        return (
          <div>
            <div>{moment(expiry_date).format("YYYY-MM-DD")}</div>
            {isExpired && record.status === 'SENT' && (
              <div style={{ color: 'red' }}>
                (Expired)
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => {
        // console.log(record);
        return dataSource?.length >= 1 ? (
          <Flex gap="middle" style={{padding: 5}}>
            <Dropdown
              menu={{ items: getItemsForDropDown(record, navigate, handleDelete, copyPaymentLinkToNavigator) }}
              placement="bottomLeft"
              onClick={(e) => e.stopPropagation}
            >
              <MoreOutlined />
            </Dropdown>
          </Flex>
        ) : null
      }
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const [selectionType, setSelectionType] = useState('checkbox');

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let p = pageNumber + 1;
      // console.log({ next_p: p, dataSource, totalRows });
      if (p === 1) {
        fetchQuotes(p);
        setPageNumber(p);
      } else if (dataSource && dataSource.length < totalRows) {
        fetchQuotes(p);
        setPageNumber(p);
      }
    }
  };


  return (
    <div style={{ padding: 10 }}>
      {contextHolder}
      <Flex gap='large' justify='space-between'>
        <Flex gap='large' align='center'>
          <div>
            ({dataSource?.length} / {totalRows})
          </div>
          <div>
            <Search placeholder="input search text" onSearch={onSearch} onChange={(e) => { setSearchTermLive(e.target.value); }} value={searchTermLive} style={{ width: 300 }} />
          </div>
        </Flex>
        <Flex gap='large'>
          {/* <Button
            onClick={() => {
              // dispatch(userActions.createExportTask({ model: "Contact" }))
              // const response = await axios.post('/contact/contacts/export/');
              // if (response.status === 200) {
              //   console.log(response.data)
              // }
              // dispatch({ type: "ADD_TASK", payload: { id: null, process: "Contact", status: "started" } });
            }}
            icon={<UserAddOutlined />}
            type="primary"
          >
            Export Quotes
          </Button> */}
          <Button
            // onClick={handleAdd}
            onClick={() => navigate("/quotes/new")}
            icon={<UserAddOutlined />}
            type="primary"
          >
            Create Quote
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }} onScroll={handleScroll}>
          <Table
          size="small"
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            loading={loading}
            // bordered
            onRow={(record) => ({
              onClick: () => handleRowClick(record), // Handle row click
            })}
            dataSource={dataSource}
            columns={columns}
            rowKey={row => row.id}
            pagination={false}
            scrollToFirstRowOnChange
            stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
            scroll={{ y: `calc(75vh - 150px)` }}
          />
          <div style={{ margin: 20 }}>{
            loading &&
            <>
              <Spin tip="Loading" size="small">
                <div className="content" />
              </Spin>
            </>}
          </div>
        </div>

      </Flex>

      {/* Drawer for Quote Details */}
      <Drawer
        title={`Quote Details - ${selectedQuote?.qt_number}`}
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={"50%"}
      >
        {/* Render invoice details here */}
        {/* Embed PDF */}
        {pdfUrl && (
          <iframe
            src={`${pdfUrl}#view=FitH&toolbar=0&navpanes=0&scrollbar=0`}
            title="Invoice PDF"
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        )}
      </Drawer>
    </div>
  );
};
export default QuotesTable;