import React, { useState, useEffect } from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Switch,
    Col,
    Row,
    Card,
    Tabs,
    Upload,
    message,
    Table,
    Popconfirm,
    Typography,
} from 'antd';
import moment from 'moment/moment';
import * as adminactions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';
import { InboxOutlined, CloseOutlined } from '@ant-design/icons';
import { dateFormat } from 'constants/constants';
import { DocumentPicker, DraggerBoxTable } from 'components';

const { Dragger } = Upload;

const onChange = (key) => {
    console.log(key);
};

const EmployeeForm = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState(null);
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([]);


    const onFinish = (values) => {
        console.log('Success:', values);
        if (values) {
            onSaveHandler({ ...values, dob: values?.dob?.format(dateFormat), joining_date: values?.joining_date?.format(dateFormat), last_working_day: values?.last_working_day?.format(dateFormat) })
        }
    };

    const handleAddDocument = async (doc_ids = []) => {
        const response = await adminactions.addDocumentsToEmployee(id, doc_ids);
        if (response) {
            setDocuments(response);
        }
    }

    const handleDeleteDocument = async (doc_id) => {
        const response = await adminactions.removeDocumentFromQuote(id, doc_id);
        if (response) {
            setDocuments(response);
        }
    }

    const props = {
        name: 'file',
        multiple: false,
        action: process.env.REACT_APP_BASE_URL + `/user/employee/${id}/addDoc/`,
        onChange(info) {
            console.log(info);
            const { status, response } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }

            if (response) {
                setDocuments(response);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const documentColumns = [{
        title: 'Name',
        dataIndex: 'name',
        width: '15%',
        editable: true,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        width: '15%',
        editable: true,
    },
    {
        title: 'Type',
        dataIndex: 'type',
        width: '15%',
        editable: true,
    },
    {
        title: 'Size',
        dataIndex: 'size',
        width: '15%',
        editable: true,
    },
    {
        title: 'Actions',
        dataIndex: 'action',
        render: (_, record) => {
            return (
                <span>
                    <Typography.Link onClick={() => console.log("View")}>
                        View
                    </Typography.Link>
                    <Typography.Link onClick={() => console.log("Download")}>
                        Download
                    </Typography.Link>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteDocument(record.id)}>
                        <a style={{ marginLeft: 10 }}>Delete</a>
                    </Popconfirm>
                </span>
            );
        },
    },
    ];

    const retrieveEmployee = async () => {
        if (id) {
            const response = await adminactions.retrieveEmployee(id);
            console.log(response)
            if (response) {
                console.log("Changing state now...")
                setDataSource(response);
                setDocuments(response.documents)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        retrieveEmployee()
    }, [])

    const onSaveHandler = async (values) => {
        values.documents = documents;
        if (id) {
            const response = await adminactions.updateEmployees(
                values, id
            );
            console.log(response);
            if (response) {
                navigate("/employees")
            }
        } else {
            const response = await adminactions.addEmployees(
                values
            );
            console.log(response);
            if (response) {
                navigate("/employees")
            }
        }
    }


    return (
        <>
            {!loading && <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                onFinish={onFinish}
                initialValues={{
                    ...dataSource,
                    dob: dataSource?.dob ? moment(dataSource?.dob) : null,
                    joining_date: dataSource?.joining_date ? moment(dataSource?.joining_date) : null,
                    last_working_day: dataSource?.last_working_day ? moment(dataSource?.last_working_day) : null
                }}
            >
                <Row
                    style={{ margin: "15px" }}
                >
                    <Col span={24} style={{}}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="Details" bordered={false} style={{}}>
                                <Tabs
                                    defaultActiveKey="1"
                                    onChange={onChange}
                                    items={[
                                        {
                                            label: `Employee Details`,
                                            key: '1',
                                            children:
                                                (<div
                                                >
                                                    <Form.Item label="Username" name='username' rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Employee Id" name='employee_id' rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="First Name" name='first_name'>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Middle Name" name='middle_name'>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Last Name" name='last_name' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Email" name='email' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Phone" name='mobile' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Date Of Birth" name="dob">
                                                        <DatePicker />
                                                    </Form.Item>
                                                    <Form.Item label="Pan Card" name='pan_card' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Groups" name='groups' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="User Permisiions" name='user_permissions' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Joining date" name='joining_date' >
                                                        <DatePicker />
                                                    </Form.Item>
                                                    <Form.Item label="Last Working Date" name='last_working_day'>
                                                        <DatePicker />
                                                    </Form.Item>
                                                    <Form.Item label="Is Staff" name='is_staff' valuePropName="checked">
                                                        <Switch />
                                                    </Form.Item>
                                                    <Form.Item label="Is Active" name='is_active' valuePropName="checked">
                                                        <Switch />
                                                    </Form.Item>
                                                </div>),
                                        },
                                        {
                                            label: `Bank Details`,
                                            key: '2',
                                            children:
                                                (<div
                                                >
                                                    <Form.Item label="Bank Account Number" name='bank_account_number'>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Bank Name" name='bank_name'>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="IFSC Code" name='bank_ifsc'>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Bank Branch" name='bank_branch'>
                                                        <Input />
                                                    </Form.Item>
                                                </div>),
                                        },
                                        {
                                            label: `Documents`,
                                            key: '5',
                                            children:
                                                (<div>
                                                    {id ? <>
                                                        <Row>
                                                            <Col span={12}>
                                                                <DocumentPicker selectDocuments={handleAddDocument} />
                                                            </Col>
                                                            <Col span={12}>
                                                                <Dragger {...props}>
                                                                    <p className="ant-upload-drag-icon">
                                                                        <InboxOutlined />
                                                                    </p>
                                                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                                    <p className="ant-upload-hint">
                                                                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                                                        band files
                                                                    </p>
                                                                </Dragger>
                                                            </Col>
                                                        </Row>
                                                        <Table
                                                            dataSource={documents}
                                                            columns={documentColumns}
                                                            rowClassName="editable-row"
                                                            size="small"
                                                            pagination={false}
                                                        />
                                                    </> : <>
                                                        <div className='d-flex justify-content-center align-items-center' style={{minHeight: '40vh'}}>
                                                            Please save the basic details first and then add document
                                                        </div>
                                                    </>}

                                                </div>),
                                        },
                                    ]}
                                />
                                <div style={{ margin: "15px" }}>
                                    <Button block type="primary" htmlType="submit" >
                                        Save
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Form>}
        </>
    );
};
export default EmployeeForm;