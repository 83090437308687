import { PaperClipOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Slider,
  Switch,
  TreeSelect,
  Upload,
  Flex,
} from "antd";
import * as actions from "store/actions/AuthActions";
import QEditor from "components/Editors/QEditor";
import EmailEditor from "react-email-editor";
import WANumberInput from "components/Inputs/WANumberInput";

import { useNotification } from "NotificationContext";
import EmailInput from "components/Inputs/EmailInput";

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const { TextArea } = Input;
const normFile = (e) => {
  // if (Array.isArray(e)) {
  //   return e;
  // }
  return e?.file;
};
const SendWAMessageDrawer = ({ selectedRecords }) => {
  const [form] = Form.useForm();

  const notify = useNotification();

  const [attachments, setAttachments] = useState([]);

  const [docToEdit, setDocToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editorValue, setEditorValue] = useState("Dear xyz, PFA Document.");
  const [subject, setSubject] = useState("Teson - Tax Invoice");

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [locked, setLocked] = useState(false);

  const [templates, setTemplates] = useState([]);

  const loadTemplates = async () => {
    setLoading(true);
    try {
      const response = await actions.loadTemplatesWithType("PROMOTION__");
      if (response.status === 200) {
        setTemplates(response?.data?.results);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  const onFinish = async (values) => {
    console.log("Success:", values);
    // alert();
    // const new_values = { ...values, documents: [document?.id], to: toEmails, body: editorValue }
    // console.log("New values", new_values);
    setLoading(true);
    try {
      const response = await actions.sendTemplateMessage(values);
      if (response.status === 200) {
        notify("success", "Success", "Message sent successfully.");
      }
    } catch (err) {
      notify("error", "Error", `Failed to send message. ${err}`);
    }
    // const response = await authActions.emailDocuments(new_values);
    // if (response) {

    //   // dispatch(authActions.login(response));
    //   // notify("success", "DocumenttoEmails Updated Successfully!", null);
    //   // notification.success({
    //   //     message: `Login Successfull`,
    //   //     description: 'Hello, Ant Design!!',
    //   //     placement: 'topRight',
    //   // });
    // } else {
    //   notify("error", "Login Failed!", null);
    // }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const emailEditorRef = useRef(null);
  const handleEditorLoad = (design) => {
    emailEditorRef.current.loadDesign(design);
  };

  // const options = ['Option 1', 'Option 2', 'Option 3'];

  const [componentDisabled, setComponentDisabled] = useState(true);
  const handleChange = (value) => {
    console.log(`selected ${value}`);

    setSelectedTemplate(templates.find((item) => item.id === value));
  };

  useEffect(() => {
    console.log(selectedRecords);
  }, [selectedRecords]);

  return (
    <>
      <div>
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          // style={{
          //     minWidth: 700,
          // }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          initialValues={{ subject: subject }}
        >
          <EmailInput
            label="To"
            name="emails"
            required
            message="Please enter emails"
            defaultValue={selectedRecords
              .filter((item) => item?.email)
              .map((item) => item.email)}
          />
          <Form.Item
            label="Template"
            name="template"
            rules={[{ required: true, message: "Please select a template!" }]}
          >
            <Select
              // style={{
              //     width: 120,
              // }}
              onChange={handleChange}
              options={templates.map((item, index) => {
                return {
                  label: `${item.name}`,
                  value: `${item.id}`,
                };
              })}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4 }}>
            <Button htmlType="submit" loading={loading}>
              {loading ? "Sending..." : "Send Email"}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {selectedTemplate && (
        <>
          <h4 style={{ marginTop: 50 }}>
            <b>Email Template Format</b>
          </h4>
          <div style={{ backgroundColor: "#DDD6CE", padding: 10 }}>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedTemplate?.html_content,
                }}
              ></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default SendWAMessageDrawer;
