import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    Input,
    Col,
    Row,
    Flex,
    Select,
    DatePicker,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as adminactions from "store/actions/AuthActions";
import dayjs from 'dayjs';


const LeadActivityForm = ({ leadId, leadActivities, setLeadActivities, instance, form, reload }) => {

    const activity_type_options = [
        { value: 'MEETING', label: 'MEETING' },
        { value: 'CALL', label: 'CALL' },
        { value: 'EMAIL', label: 'EMAIL' },
        { value: 'DEMO', label: 'DEMO' },
        { value: 'FOLLOW_UP', label: 'FOLLOW_UP' },
        { value: 'PROPOSAL', label: 'PROPOSAL' },
        { value: 'NEGOTIATION', label: 'NEGOTIATION' },
        { value: 'SITEVISIT', label: 'SITEVISIT' },
        { value: 'WEBINAR', label: 'WEBINAR' },
        { value: 'TRAINING', label: 'TRAINING' },
        { value: 'NETWORKING', label: 'NETWORKING' },
        { value: 'FEEDBACK', label: 'FEEDBACK' },
        { value: 'PRESENTATION', label: 'PRESENTATION' },
        { value: 'ONBOARDING', label: 'ONBOARDING' },
    ]

    const status_options = [
        { value: 'PENDING', label: 'PENDING' },
        { value: 'CONTACTED', label: 'CONTACTED' },
        { value: 'COLD', label: 'COLD' },
        { value: 'WARM', label: 'WARM' },
        { value: 'HOT', label: 'HOT' },
        { value: 'CONVERTED', label: 'CONVERTED' },
        { value: 'DEAD', label: 'DEAD' },
    ]

    const onFinish = async (values) => {
        if (instance) {
            const response = await adminactions.updateLeadActivity({ ...values, lead: leadId }, instance.id);
            if (response) {
                const restAct = leadActivities.filter((act) => act.id !== response.data['id'])
                setLeadActivities([...restAct, response.data])
                reload();
            }
        } else {
            const response = await adminactions.addLeadActivity({ ...values, lead: leadId });
            if (response) {
                setLeadActivities((leadActivities) => [...leadActivities, response.data])
                reload();
            }
        }
    };

    useEffect(() => {
        if (instance) {
            const thisInstance = { ...instance }
            let date_fields = ['activity_date']
            for (let field of date_fields) {
                thisInstance[field] = thisInstance[field] ? dayjs(thisInstance[field]) : null;
            }
            form.setFieldsValue(thisInstance);
        }
    }, [instance])

    return (
        <Form
            form={form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
        >
            <Row style={{ margin: "15px" }}>
                <Col span={24} style={{}}>
                    <div className="site-card-border-less-wrapper">
                        <div>
                            <Form.Item label="Activity Type" name='activity_type' rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select Activity Type"
                                    options={activity_type_options}
                                />
                            </Form.Item>
                            <Form.Item label="Date" name="activity_date">
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>
                            <Form.Item label="Status" name='status' rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select Status"
                                    options={status_options}
                                />
                            </Form.Item>
                            <Form.Item label="Notes" name='notes'>
                                <TextArea />
                            </Form.Item>
                        </div>
                        <Flex justify='flex-end'>
                            <Button block type="primary" htmlType="submit" style={{ maxWidth: 100 }} >
                                Save
                            </Button>
                        </Flex>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};
export default LeadActivityForm;