import React, { useEffect, useState } from "react";


const AccessControl = (props) => {
    
    const {children, userPermissions=[], allowedPermissions = [], renderNoAccess} = props;

    const [loading, setLoading] = useState(true);
    const [permitted, setPermitted] = useState(false);
    
    useEffect(()=> {
        // console.log(allowedPermissions);
        // console.log(userPermissions);
        if(allowedPermissions.length == 0){
            setPermitted(true);
        } else {
            for(let i in allowedPermissions){
                if(userPermissions?.find(item => item == allowedPermissions[i])){
                    setPermitted(true);
                    break;
                }
            }
        }
        setLoading(false);
    }, [userPermissions]);

    if(loading){
        return (
            <div>
                loading...
            </div>
        );
    }

    if(!permitted){
        return renderNoAccess();
    }

    return (
        <>
            {children}
        </>
    );
}

export default AccessControl;
