import React, { useState, useEffect } from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Switch,
    Col,
    Row,
    Card,
    Tabs,
} from 'antd';
import moment from 'moment/moment';
import { Select } from 'antd';
import * as adminactions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';
import { dateFormat } from 'constants/constants';
import TextArea from 'antd/lib/input/TextArea';

const onChange = (key) => {
    console.log(key);
};

const ContactForm = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [vendors, setVendors] = useState([]);


    const onFinish = (values) => {
        if (values) {
            onSaveHandler({ ...values })
        }
    };

    const fetchAccounts = async () => {
        const response = await adminactions.loadAccounts(1, undefined, 1000)
        console.log(response)
        if (response) {
            setAccounts(response);
        }
    }

    const fetchVendors = async () => {
        const response = await adminactions.loadVendors(1, undefined, 1000);
        console.log(response)
        if (response) {
            setVendors(response);
        }
    }


    const retrieveContact = async () => {
        if (id) {
            const response = await adminactions.retrieveContact(id);
            console.log(response)
            if (response) {
                console.log("Changing state now...")
                setDataSource(response);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        retrieveContact();
        fetchAccounts();
        fetchVendors();
    }, [])

    const onSaveHandler = async (values) => {
        if (id) {
            const response = await adminactions.updateContacts(
                values, id
            );
            console.log(response);
            if (response) {
                navigate("/contacts")
            }
        } else {
            const response = await adminactions.addContacts(
                values
            );
            console.log(response);
            if (response) {
                navigate("/contacts")
            }
        }
    }


    return (
        <>
            {!loading && <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                onFinish={onFinish}
                initialValues={{
                    ...dataSource
                }}
            >
                <Row
                    style={{ margin: "15px" }}
                >
                    <Col span={24} style={{}}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="Details" bordered={true} style={{}}>
                                <Tabs
                                    defaultActiveKey="1"
                                    onChange={onChange}
                                    items={[
                                        {
                                            label: `Contact Details`,
                                            key: '1',
                                            children:
                                                (<div>
                                                    <Form.Item label="First Name" name='first_name'>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Middle Name" name='middle_name'>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Last Name" name='last_name' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Account" name='account' >
                                                        <Select
                                                            style={{
                                                                width: 120,
                                                            }}
                                                            allowClear
                                                            options={accounts?.results?.map((c, i) => {
                                                                return {
                                                                    value: c.id,
                                                                    label: c.name
                                                                }
                                                            })}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label="Vendor" name='vendor' >
                                                        <Select
                                                            style={{
                                                                width: 120,
                                                            }}
                                                            allowClear
                                                            options={vendors?.results?.map((v, i) => {
                                                                return {
                                                                    value: v.id,
                                                                    label: v.name
                                                                }
                                                            })}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label="Email" name='email' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Alt Email" name='alt_email' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Phone" name='phone_number' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Alt Phone" name='alt_phone_number' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Company" name='company' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Job Title" name='job_title' >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Address" name="address" >
                                                        <TextArea />
                                                    </Form.Item>
                                                    <Form.Item label="State" name="state">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Country" name="country">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Pincode" name="pincode">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Website" name="website">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Notes" name="notes">
                                                        <TextArea />
                                                    </Form.Item>
                                                </div>),
                                        }
                                    ]}
                                />
                                <div style={{ margin: "15px" }}>
                                    <Button block type="primary" htmlType="submit" >
                                        Save
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Form>}
        </>
    );
};
export default ContactForm;