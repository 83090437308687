import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    Input,
    Select,
    Col,
    Row,
    Card,
    Tabs,
} from 'antd';
import * as adminactions from "store/actions/AuthActions";
import { useNavigate, useParams } from 'react-router-dom';

const onChange = (key) => {
    console.log(key);
};

const EditVendors = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState(null);
    const [loading, setLoading] = useState(true);

    const onFinish = (values) => {
        console.log('Success:', values);
        if (values) {
            onSaveHandler(values)
        }
    };


    const fetchVendors = async () => {
        if (id) {
            const response = await adminactions.fetchVendors(id);
            console.log(response)
            if (response) {
                console.log("Changing state now...")
                setDataSource(response);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchVendors()
    }, []
    )

    const onSaveHandler = async (values) => {
        if (id) {
            const response = await adminactions.updateVendors(
                values, id
            );
            console.log(response);
            if (response) {
                navigate("/vendors")
            }
        } else {
            const response = await adminactions.addVendors(
                values
            );
            if (response) {
                navigate("/vendors");
            }
        }
    }

    return (
        <div className='custom-scroll-div' style={{ overflowY: "scroll", maxHeight: "90vh", padding: 10, paddingBottom: 30 }}>
            {!loading &&
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                    initialValues={dataSource}
                // layout="horizontal"
                // initialValues={{ size: componentSize }}
                // onValuesChange={onFormLayoutChange}
                // size={componentSize as SizeType}
                // onFinish={onFinish}
                // ref={companyDetails}
                >
                    <Row
                        style={{ margin: "15px" }}
                    >
                        {/* FORM_1 */}
                        <Col className="gutter-row" span={12}>
                            <div className="site-card-border-less-wrapper" style={{ paddingBottom: "10px" }} >
                                <Card title="Company Details" bordered={false} >
                                    <div>
                                        <Form.Item className="text-start" label="Name" name='name' rules={[
                                            {
                                                required: true,
                                            },
                                        ]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="GST Number" name='gst_number'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="VAT Number" name='vat_number'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Mobile" name='phone'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Email" name='email'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Currency" name="currency">
                                            <Select >
                                                <Select.Option value='rupees' >Rupees</Select.Option>
                                                <Select.Option value='dollar' >Dollars</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Website" name='website'>
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </Card>
                            </div>
                            { /* FORM_2 */}
                            <div className="site-card-border-less-wrapper">
                                <Card title="Address" bordered={false} >
                                    <Tabs
                                        defaultActiveKey="1"
                                        onChange={onChange}
                                        items={[
                                            {
                                                label: `Billing Address`,
                                                key: '1',
                                                children:
                                                    <div>
                                                        <Form.Item label="Address" name='billing_address1'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="City" name='billing_city'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="State" name='billing_state' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Postal Code" name='billing_postal_code' >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Country" name='billing_country'>
                                                            <Input />
                                                        </Form.Item>
                                                    </div>,
                                            },
                                            {
                                                label: `Shipping Address`,
                                                key: '2',
                                                children:
                                                    <div>
                                                        <Form.Item label="Address" name='shipping_address1'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="City" name='shipping_city'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="State" name='shipping_state'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Postal Code" name='shipping_postal_code'>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Country" name='shipping_country'>
                                                            <Input />
                                                        </Form.Item>
                                                    </div>,
                                            },
                                        ]}
                                    />
                                </Card>
                            </div>
                        </Col>
                        { /* FORM_3 */}
                        <Col className="gutter-row" span={12}>
                            <div className="site-card-border-less-wrapper" style={{ padding: "0px 0px 10px 10px" }}>
                                <Card title="Contacts" bordered={false} >
                                    <div
                                    >
                                        <Form.Item label="First Name" name='first_name'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Last Name" name='last_name'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Email" name='emailContact'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Phone" name='phoneContact'>
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </Card>
                            </div>
                            { /* FORM_4 */}
                            <div className="site-card-border-less-wrapper" style={{ paddingLeft: "10px" }}>
                                <Card title="Additional Info" bordered={false} >
                                    <Tabs
                                        defaultActiveKey="1"
                                        onChange={onChange}
                                        items={[
                                            {
                                                label: `Settings`,
                                                key: '1',
                                                children: (<div>
                                                    <Form.Item label="Name">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Number">
                                                        <Input />
                                                    </Form.Item>

                                                </div>),
                                            },
                                            {
                                                label: `Contact Fields`,
                                                key: '2',
                                                children: (
                                                    <div>
                                                        <Form.Item label="Name">
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Number">
                                                            <Input />
                                                        </Form.Item>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                    />
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ margin: "15px" }}>
                        <Button block type="primary" htmlType="submit" >
                            Save
                        </Button>
                    </div>
                </Form>
            }
        </div>
    );
};
export default EditVendors;