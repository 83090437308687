import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to load countries
export const loadCountries = createAsyncThunk(
  'masters/loadCountries',
  async (_, { rejectWithValue }) => {
    try {
      let url = `masters/countries/`;
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Async thunk to load payment terms
export const loadPaymentTerms = createAsyncThunk(
  'masters/loadPaymentTerms',
  async () => {
    try {
      const url = 'masters/payment-terms/';
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

// Async thunk to load states
export const loadStates = createAsyncThunk(
  'masters/loadStates',
  async () => {
    try {
      const url = 'masters/states/';
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

// Async thunk to load currencies
export const loadCurrencies = createAsyncThunk(
  'masters/loadCurrencies',
  async () => {
    try {
      const url = 'masters/currencies/';
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

// Async thunk to load holidays
export const loadHolidays = createAsyncThunk(
  'masters/loadHolidays',
  async () => {
    try {
      const url = 'masters/holidays/';
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

// Async thunk to load leave types
export const loadLeaveTypes = createAsyncThunk(
  'masters/loadLeaveTypes',
  async () => {
    try {
      const url = 'masters/leave-types/';
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

// Master slice definition
const masterSlice = createSlice({
  name: 'master',
  initialState: {
    countries: [],
    payment_terms: [],
    states: [],
    currencies: [],
    holidays: [],
    leave_types: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // Load countries
      .addCase(loadCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.countries = action.payload;
      })
      .addCase(loadCountries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Load payment terms
      .addCase(loadPaymentTerms.fulfilled, (state, action) => {
        state.payment_terms = action.payload;
      })
      // Load states
      .addCase(loadStates.fulfilled, (state, action) => {
        state.states = action.payload;
      })
      // Load currencies
      .addCase(loadCurrencies.fulfilled, (state, action) => {
        state.currencies = action.payload;
      })
      // Load holidays
      .addCase(loadHolidays.fulfilled, (state, action) => {
        state.holidays = action.payload;
      })
      // Load leave types
      .addCase(loadLeaveTypes.fulfilled, (state, action) => {
        state.leave_types = action.payload;
      });
  },
});

export default masterSlice.reducer;
