import React, { useEffect, useState } from 'react';
import { Button, Flex, Popconfirm, Table } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import useScrollBottom from 'CustomHooks/useScrollBottom';
import { Input } from "antd";

const EmployeesTable = () => {

  const bottom = useScrollBottom();
  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [dataSource, setDataSource] = useState();
  const navigate = useNavigate();

  const fetchEmployees = async (page) => {
    const response = await actions.loadEmployees(page, searchTerm);
    console.log({ response, dataSource });
    if (response && response.results && response.results.length > 0) {
      if (dataSource && dataSource.length > 0) {
        const toPut = [];
        const pushedIds = [];
        dataSource.forEach((d, i) => {
          if (!pushedIds.includes(d.id)) {
            pushedIds.push(d.id);
            toPut.push(d);
          }
        });
        response.results.forEach((rr, i) => {
          if (!pushedIds.includes(rr.id)) {
            pushedIds.push(rr.id);
            toPut.push(rr);
          }
        })
        setDataSource(toPut);
      } else {
        setTotalRows(response.count);
        setDataSource(response.results);
      }
    }
  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchEmployees(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchEmployees(p);
      setPageNumber(p);
    }
  }, [bottom, searchTerm]);

  const handleDelete = async (id) => {
    const newData = dataSource.find((item) => item.id === id);
    // setDataSource(newData);
    const response = await actions.deleteEmployees(
      newData.id
    );
    console.log(response);
    if (response) {
      fetchEmployees();
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  }


  const defaultColumns = [
    {
      title: 'EMP ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
      sorter: (a, b) => a?.employee_id.localeCompare(b?.employee_id),
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a?.first_name.localeCompare(b?.first_name),
    },
    {
      title: 'LAST NAME',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a?.last_name.localeCompare(b?.last_name),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a?.email.localeCompare(b?.email),
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => {
        // console.log(record);
        return dataSource?.length >= 1 ? (
          <Flex gap="middle">
            <a>
              <EditOutlined
                onClick={() => navigate(`/employees/edit/${record.id}/`)}
                style={{
                  verticalAlign: 'middle',
                }}
              />
            </a>
            <Popconfirm title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Flex>
        ) : null
      }
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const [selectionType, setSelectionType] = useState('checkbox');

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between p-2 sticky-top custom-top'>
        <p className='ms-2 mb-0'>
          ({dataSource?.length} / {totalRows})
        </p>

        <div>
          <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
        </div>
        <Button
          // onClick={handleAdd}
          onClick={() => navigate("/employees/new")}
          icon={<UserAddOutlined />}
          type="primary"
          style={{
            float: "right"
          }}
        >
          Add Employee
        </Button>
      </div>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        bordered
        size="small"
        dataSource={dataSource}
        columns={columns}
        rowKey={row => row.id}
        sticky
        pagination={false}
      // scroll={{ x: 1400 }}
      />
    </div>
  );
};
export default EmployeesTable;