import { BrowserRouter as Router, Outlet, Route, Routes } from "react-router-dom";
import { NavSider, NavBreadcrumb, Navbar, Organization } from "components";
import { Affix, Collapse, Layout, List, Modal, Popconfirm, Select, Spin, Typography } from 'antd';
import { CloseCircleOutlined, LoadingOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import * as authActions from "store/actions/AuthActions";
import * as userActions from "store/actions/UserActions";

// login
import Login from "./pages/Login/Login"

// clients
import Home from "./pages/Home/Home"

// clients
import Accounts from "./pages/Accounts/Accounts"
import AccountForm from "./pages/Accounts/AccountForm"
import AccountsParentPage from "pages/Accounts/AccountsParentPage";

// dasboard
import Dashboard from "./pages/Dashboard/Dashboard"

// employees
import Employees from "./pages/Employees/Employees"
import EmployeeForm from "./pages/Employees/EmployeeForm"
import EmployeesParentPage from "pages/Employees/EmployeesParentPage";

// contacts
import Contacts from "./pages/Contacts/Contacts"
import ContactForm from "./pages/Contacts/ContactForm"
import ContactsParentPage from "pages/Contacts/ContactsParentPage";

// expenses
import Expenses from "./pages/Expenses/Expenses"
import ExpenseForm from "./pages/Expenses/ExpenseForm"
import ImportExpenses from "./pages/Expenses/ImportExpenses"
import ExpensesParentPage from "pages/Expenses/ExpensesParentPage";

// invoices
import Invoices from "./pages/Invoices/Invoices"
import InvoiceForm from "./pages/Invoices/InvoiceForm"
import InvoicesParentPage from "pages/Invoices/InvoicesParentPage";

// quotes
import Quotes from "./pages/Quotes/Quotes"
import QuoteForm from "./pages/Quotes/QuoteForm"
import QuotesParentPage from "pages/Quotes/QuotesParentPage";

// payments
import Payments from "./pages/Payments/Payments"
import ImportPayments from "./pages/Payments/ImportPayments"
import PaymentForm from "./pages/Payments/PaymentForm"
import PaymentsParentPage from "pages/Payments/PaymentsParentPage";

// users
import Users from "./pages/Users/Users"
import UserForm from "./pages/Users/UserForm"
import UsersParentPage from "pages/Users/UsersParentPage";

// settings
import Settings from "./pages/Settings/Settings"

// vendors
import Vendors from "./pages/Vendors/Vendors"
import VendorForm from "./pages/Vendors/VendorForm"
import ImportVendors from "./pages/Vendors/ImportVendors"
import VendorsParentPage from "pages/Vendors/VendorsParentPage";

// services
import Services from "./pages/Services/Services"
import ImportServices from "./pages/Services/ImportServices"
import ServicesParentPage from "pages/Services/ServicesParentPage";

// products
import Products from "./pages/Products/Products"
import ImportProducts from "./pages/Products/ImportProducts"
import ProductsParentPage from "pages/Products/ProductsParentPage";

import Documents from "pages/Documents/Documents";
import DocumentForm from "pages/Documents/DocumentForm";
import DocumentParentPage from "pages/Documents/DocumentParentPage";

// system monitoring
import SystemMonitoring from "pages/SystemMonitoring/SystemMonitoring";

// Templates
import Template from "./pages/Template/Template";
import TemplateMaster from "./pages/Common/Master/Template";


// Sales
import Sales from "./pages/Sales/Sales";
import SalesParentPage from "./pages/Sales/SalesParentPage";

// Leads
import LeadsParentPage from "./pages/Leads/LeadsParentPage";
import Leads from "./pages/Leads/Leads";
import LeadForm from "./pages/Leads/LeadForm";

// WhatsApp
import WhatsApp from "./pages/WhatsApp/WhatsApp";
import WhatsAppChat from "pages/WhatsApp/WhatsAppChat";
import WAMessageTemplates from "pages/WhatsApp/WAMessageTemplates";
import WhatsappParentPage from "./pages/WhatsApp/WhatsAppParentPage";
import SendMessageInBulk from "pages/WhatsApp/SendMessageInBulk";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import AssetFileParentPage from "pages/AssetFiles/AssetFileParentPage";
import AssetFiles from "pages/AssetFiles/AssetFiles";
import AssetFileForm from "pages/AssetFiles/AssetFileForm";
import NotFoundPage from "pages/NotFoundPage";
import AccessControl from "components/Access/AccessControl";
import NoAccess from "components/Access/NoAccess";
import InitiatePhonePe from "pages/PhonePe/InitiatePhonePe";
import TemplateParentPage from "pages/Template/TemplateParentPage";
import Templates from "pages/Template/Templates";
import TemplateForm from "pages/Template/TemplateForm";
import EditorViewer from "pages/Template/EditorViewer";


axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const { Content, Footer } = Layout;

const Routers = () => {
  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated
  );

  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);

  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  const tasks = useSelector(
    (state) => state.general.tasks
  )

  const organization = useSelector(state => state.auth.organization);
  const [orgModal, setOrgModal] = useState(false);
  const [sessionModalOpen, setSessionModalOpen] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(10);
  const sessionTimeoutRef = useRef(sessionTimeout);
  const dispatch = useDispatch();

  // Timeout function
  let authTimeout;

  let sessionTimeoutVar;

  const setSessionCountTimeout = () => {
    // setInterval(() => {
    //   setSeconds(prevSeconds => prevSeconds + 1);
    // }, 1000);
    sessionTimeoutVar = setInterval(() => {
      // dispatch(authActions.logout());
      // setSessionModalOpen(true);
      if (sessionTimeoutRef.current === 0) {
        clearInterval(sessionTimeoutVar);
        clearTimeout(authTimeout);
        // setSessionTimeout(10);
        dispatch(authActions.logout())
        setSessionModalOpen(false);

        // window.location.href = "/";
      } else {
        setSessionTimeout(prevSessionTimeout => prevSessionTimeout - 1);
        // alert(sessionTimeout);
      }
    }, 1000); // 5 minutes in milliseconds
  };

  const setAuthenticationTimeout = () => {
    authTimeout = setTimeout(() => {
      // dispatch(authActions.logout());
      setSessionModalOpen(true);
      setSessionCountTimeout();
      clearTimeout(authTimeout);
    }, 1 * 10 * 1000); // 5 minutes in milliseconds
  };

  const resetAuthenticationTimeout = () => {
    clearTimeout(authTimeout);
    setAuthenticationTimeout();
  };

  useEffect(() => {
    // Update ref whenever sessionTimeout changes
    sessionTimeoutRef.current = sessionTimeout;
  }, [sessionTimeout]);

  useEffect(() => {
    if (!organization) {
      setOrgModal(true);
    } else {
      setOrgModal(false);
    }
    // resetAuthenticationTimeout();
    // dispatch(authActions.check_token_validity());
  }, [organization])

  useEffect(() => {
    // if (!isAuthenticated) {
    //   clearTimeout(authTimeout);
    // } else {
    //   resetAuthenticationTimeout();
    // }
  }, [isAuthenticated])

  const fetchTasks = () => {
    dispatch(userActions.fetchTasks());
  }

  const process_tasks = async () => {
    console.log(tasks);
    if (tasks.length > 0) {
      let tempArray = [];
      for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        switch (task.status) {
          case "started":
            const res_create = await axios.post('/utilities/exporttask/export/', { model: task.process });
            if (res_create.status === 200) {
              const rest_create_data = res_create.data;
              console.log(res_create.data)
              tempArray.push({ id: rest_create_data.task_id, process: task.process, status: "processing" });
            }
            break;
          case "processing":
            tempArray.push(task);
            // const response = await axios.post('/utilities/exporttask/')
            break;
          // case "completed":
          //   break;
          // case "failed":
          //   break;
        }

      }
      console.log(tempArray);
      dispatch({ type: "UPDATE_TASKS", payload: tempArray })
    }
  }

  useEffect(() => {
    // const interval = setInterval(() => {
    //   // process_tasks();
    //   fetchTasks();
    // }, 10000);

    // return () => {
    //   // Clear the interval when the component is unmounted to avoid memory leaks
    //   clearInterval(interval);
    //   if (sessionTimeoutVar) {
    //     clearInterval(sessionTimeoutVar);
    //   }
    // };
  }, [])


  return (
    <>
      <Router>
        <Routes>
          {isAuthenticated && <Route path="" element={
            <Layout
              style={{
                height: '100vh',
              }}
            >
              <NavSider />
              <Layout className="site-layout">
                <Navbar />
                <Content
                  style={{
                    margin: '0',
                    maxHeight: '100vh',
                    overflow: 'hidden',
                  }}
                >
                  <div style={{
                    margin: "10px"
                  }}>
                    <NavBreadcrumb />
                  </div>
                  {organization && <Outlet />}
                  <Modal
                    title="Select Organization"
                    centered
                    open={orgModal}
                    footer={null}
                    closable={false}
                  >
                    <div>
                      <Organization />
                    </div>

                  </Modal>
                </Content>
                <Footer
                  style={{
                    // color:"white",
                    // backgroundColor:"grey",
                    // marginTop: "5px",
                    textAlign: 'center',
                  }}
                >
                  Teson CRM ©2022 Created by Teson LLP
                </Footer>
              </Layout>
            </Layout>
          }>
            <Route
              path=""
              element={<Dashboard />}
            >
            </Route>
            <Route
              path="home"
              element={<Home />}
            >
            </Route>
            <Route path="accounts" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["accounts:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="accounts:view" />
                )}
              >
                <AccountsParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Accounts />}
              ></Route>
              <Route
                path="new"
                element={<AccountForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<AccountForm />}
              >
              </Route>
            </Route>
            <Route path="vendors" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["vendors:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="vendors:view" />
                )}
              >
                <VendorsParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Vendors />}
              ></Route>
              <Route
                path="new"
                element={<VendorForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<VendorForm />}
              >
              </Route>
              <Route
                path="import"
                element={<ImportVendors />}
              >
              </Route>
            </Route>
            <Route path="payments" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["payments:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="payments:view" />
                )}
              >
                <PaymentsParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Payments />}
              ></Route>
              <Route
                path="new"
                element={<PaymentForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<PaymentForm />}
              >
              </Route>
              <Route
                path="import"
                element={<ImportPayments />}
              >
              </Route>
            </Route>
            <Route path="expenses" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["expenses:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="expenses:view" />
                )}
              >
                <ExpensesParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Expenses />}
              ></Route>
              <Route
                path="new"
                element={<ExpenseForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<ExpenseForm />}
              >
              </Route>
              <Route
                path="import"
                element={<ImportExpenses />}
              >
              </Route>
            </Route>
            <Route path="invoices" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["invoices:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="invoices:view" />
                )}
              >
                <InvoicesParentPage />
              </AccessControl>}>
              <Route
                path=""
                element={<Invoices />}
              ></Route>
              <Route
                path="new"
                element={<InvoiceForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<InvoiceForm />}
              >
              </Route>
            </Route>
            <Route path="quotes" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["quotes:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="quotes:view" />
                )}
              >
                <QuotesParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Quotes />}
              ></Route>
              <Route
                path="new"
                element={<QuoteForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<QuoteForm />}
              >
              </Route>
            </Route>
            <Route path="products" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["products:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="products:view" />
                )}
              >
                <ProductsParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Products />}
              ></Route>
              <Route
                path="import"
                element={<ImportProducts />}
              >
              </Route>
            </Route>
            <Route path="employees" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["employees:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="employees:view" />
                )}
              >
                <EmployeesParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Employees />}
              ></Route>
              <Route
                path="new"
                element={<EmployeeForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<EmployeeForm />}
              >
              </Route>
            </Route>
            <Route path="contacts" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["contacts:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="contacts:view" />
                )}
              >
                <ContactsParentPage />
              </AccessControl>}
            >
              <Route
                path=""
                element={<Contacts />}
              ></Route>
              <Route
                path="new"
                element={<ContactForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<ContactForm />}
              >
              </Route>
            </Route>
            <Route path="documents" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["documents:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="documents:view" />
                )}
              >
                <DocumentParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Documents />}
              ></Route>
              <Route
                path="new"
                element={<DocumentForm />}
              >
              </Route>
            </Route>
            <Route path="assetfiles" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["assetfiles:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="assetfiles:view" />
                )}
              >
                <AssetFileParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<AssetFiles />}
              ></Route>
              <Route
                path="new"
                element={<AssetFileForm />}
              >
              </Route>
            </Route>
            <Route path="systemmonitoring" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["systemmonitoring:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="systemmonitoring:view" />
                )}
              >
                <SystemMonitoring />
              </AccessControl>
            } />
            <Route path="templates" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["templates:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="templates:view" />
                )}
              >
                <TemplateParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Templates />}
              ></Route>
              <Route
                path="new"
                element={<TemplateForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<TemplateForm />}
              >
              </Route>
              <Route
                path="editor"
                element={<EditorViewer />}
              >
              </Route>
            </Route>
            <Route path="users" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["users:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="users:view" />
                )}
              >
                <UsersParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Users />}
              ></Route>
              <Route
                path="new"
                element={<UserForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<UserForm />}
              >
              </Route>
            </Route>
            <Route path="sales" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["sales:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="sales:view" />
                )}
              >
                <SalesParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<Sales />}
              ></Route>
              {/* <Route
                path="new"
                element={<DocumentForm />}
              >
              </Route> */}
            </Route>
            <Route path="leads" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["leads:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="leads:view" />
                )}
              >
                <LeadsParentPage />
              </AccessControl>}>
              <Route
                path=""
                element={<Leads />}
              ></Route>
              <Route
                path="new"
                element={<LeadForm />}
              >
              </Route>
              <Route
                path="edit/:id"
                element={<LeadForm />}
              >
              </Route>
            </Route>
            <Route path="whatsapp" element={
              <AccessControl
                userPermissions={user?.user_permissions}
                allowedPermissions={["leads:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="leads:view" />
                )}
              >
                <WhatsappParentPage />
              </AccessControl>
            }>
              <Route
                path=""
                element={<WhatsApp />}
              ></Route>
              <Route
                path="chat"
                element={<WhatsAppChat />}
              ></Route>
              <Route
                path="send-message"
                element={<SendMessageInBulk />}
              ></Route>
              <Route
                path="templates"
                element={<WAMessageTemplates />}
              ></Route>
            </Route>
            <Route
              path="settings"
              element={
                <AccessControl
                  userPermissions={user?.user_permissions}
                  allowedPermissions={["settings:view"]}
                  renderNoAccess={() => (
                    <NoAccess permissionNeeded="settings:view" />
                  )}
                >
                  <Settings />
                </AccessControl>
              }
            >
            </Route>
          </Route>}
          <Route
            path=""
            element={<Login />}
          ></Route>
          ///////////////////////////
          <Route path="initiatePhonePe" element={<InitiatePhonePe />}>

          </Route>
          <Route
            path="*"
            element={<NotFoundPage />}
          ></Route>
        </Routes>
        {isAuthenticated && tasks.length > 0 && <div style={{ position: "fixed", bottom: 0, right: 0, zIndex: 100, backgroundColor: "#fff" }}>
          <Collapse
            expandIconPosition={'start'}
            style={{ width: "300px" }}
            // expandIcon={(props) =>
            //   <SettingOutlined
            //     onClick={(event) => {
            //       // If you don't want click extra trigger collapse, you can prevent this:
            //       event.stopPropagation();
            //     }}
            //   />
            // }
            items={[
              {
                key: '1',
                label: 'Preparing Download...',
                extra: <>
                  <Popconfirm
                    title="Cancel Task"
                    style={{ zIndex: 1000 }}
                    description="Once the tasks are cancelled, cannot be retrieve."
                    okText="Cancel Tasks"
                    cancelText="Continue Tasks"
                    onConfirm={() => {
                      dispatch(userActions.markCompleted({ ids: tasks.map(task => task.id) }))
                    }}
                    onOpenChange={() => console.log('open change')}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <CloseCircleOutlined
                      onClick={(event) => {
                        // If you don't want click extra trigger collapse, you can prevent this:
                        event.stopPropagation();
                        // dispatch({ type: "CLEAR_TASKS" });
                      }}
                    />
                  </Popconfirm>
                </>,
                children: <List
                  // header={<div>Header</div>}
                  // footer={<div>Footer</div>}
                  dataSource={tasks}
                  renderItem={(item) => (
                    <List.Item>
                      <Typography.Text>{`${item.model} Export`}</Typography.Text> {item.status}
                      {item.status == "PENDING" && <Typography.Text><Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 24,
                            }}
                            spin
                          />
                        } /></Typography.Text>}
                      {item.status == "SUCCESS" && <Typography.Text><Spin
                        indicator={
                          <CloudDownloadOutlined style={{ fontSize: 24 }} onClick={async () => {
                            await userActions.downloadExportFileUsingChunks(item.id)
                          }} />
                        } /></Typography.Text>}
                    </List.Item>
                  )}
                />,
              },
            ]}
          />
        </div>}
      </Router>
      <Modal
        title="Do you want to continue logging in?"
        centered
        open={sessionModalOpen}
        onOk={() => {
          setSessionModalOpen(false)
          setSessionTimeout(10);
          clearInterval(sessionTimeoutVar);
          resetAuthenticationTimeout();
        }}
        okText={"Continue Logging In"}
        cancelText={"Logout"}
        onCancel={() => {
          setSessionModalOpen(false)
          dispatch(authActions.logout())
          window.location.href = "/";
        }}
      >
        <p>You will be logged out in {sessionTimeout} seconds.</p>
      </Modal>
    </>
  );
}
export default Routers;