import React, { useEffect, useState } from 'react';
import { Button, Flex, Popconfirm, Spin, Table } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import useScrollBottom from 'CustomHooks/useScrollBottom';
import { Input } from "antd";


const TemplatesTable = () => {

    const bottom = useScrollBottom();
    const { Search } = Input;

    const [searchTerm, setSearchTerm] = useState("");

    const [totalRows, setTotalRows] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchTemplates = async (page) => {
        setLoading(true);
        try {
            const response = await actions.loadTemplates(page, searchTerm);
            if (response.status == 200) {
                const data = response.data;
                if (page) {
                    setDataSource([...dataSource, ...data.results])
                    setTotalRows(data.count);
                } else {
                    setDataSource([...data.results])
                    setTotalRows(data.count);
                }
            }
        } catch (e) {

        }
        setLoading(false);
    }

    const onSearch = (value) => {
        if (searchTerm === value) {

        } else {
            setSearchTerm(value);
            setDataSource([]);
            setPageNumber(0);
        }
    }

    useEffect(() => {
        let p = pageNumber + 1;
        // console.log({ next_p: p, dataSource, totalRows });
        if (p === 1) {
            fetchTemplates(p);
            setPageNumber(p);
        } else if (dataSource && dataSource.length < totalRows) {
            fetchTemplates(p);
            setPageNumber(p);
        }
    }, [bottom, searchTerm]);

    const handleDelete = async (id) => {
        const newData = dataSource.find((item) => item.id === id);
        // setDataSource(newData);
        const response = await actions.deleteUsers(
            newData.id
        );
        console.log(response);
        if (response) {
            fetchTemplates();
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    }


    const defaultColumns = [
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'DESCRPITION',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'TEMPLATE TYPE',
            dataIndex: 'template_type',
            key: 'template_type'
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (_, record) => {
                // console.log(record);
                return dataSource?.length >= 1 ? (
                    <Flex gap="middle">
                        <div style={{ cursor: "pointer" }} onClick={() => { console.log("View Document"); }}>
                            <EyeOutlined />
                        </div>
                        <a>
                            <EditOutlined
                                style={{ verticalAlign: 'middle' }}
                                onClick={() => navigate(`/templates/edit/${record.id}/`)}
                            />
                        </a>
                        <Popconfirm title="Sure to delete?"
                            onConfirm={() => handleDelete(record.id)}
                        >
                            <a>
                                <DeleteOutlined />
                            </a>
                        </Popconfirm>
                    </Flex>
                ) : null
            },

        }
    ];

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };


    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            let p = pageNumber + 1;
            // console.log({ next_p: p, dataSource, totalRows });
            if (p === 1) {
                fetchTemplates(p);
                setPageNumber(p);
            } else if (dataSource && dataSource.length < totalRows) {
                fetchTemplates(p);
                setPageNumber(p);
            }
        }
    };


    const [selectionType, setSelectionType] = useState('checkbox');

    return (
        <div style={{ padding: 10 }}>
            <Flex gap="middle" justify='space-between'>
                <Flex gap="middle" align='center'>
                    <div>
                        ({dataSource?.length} / {totalRows})
                    </div>
                    <div>
                        <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
                    </div>
                </Flex>
                <Flex gap="small" justify='flex-end'>
                    <Button
                        onClick={() => navigate("/templates/new")}
                        icon={<UserAddOutlined />}
                        type="primary"
                        style={{
                            float: "right"
                        }}
                    >
                        Add Template
                    </Button>
                </Flex>
            </Flex>
            <Flex>
                <div style={{ height: "75vh", overflow: "hidden", marginTop: 20 }} onScroll={handleScroll}>
                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection,
                        }}
                        bordered
                        size="small"
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={row => row.id}
                        pagination={false}
                        scrollToFirstRowOnChange
                        stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                        scroll={{ y: `calc(75vh - 150px)` }}
                    />
                    <div style={{ margin: 20 }}>{
                        loading &&
                        <>
                            <Spin tip="Loading" size="small">
                                <div className="content" />
                            </Spin>
                        </>}
                    </div>
                </div>
            </Flex>
        </div>
    );
};
export default TemplatesTable;