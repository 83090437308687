import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Flex, Form, Input, Popconfirm, Table } from 'antd';
import { ImportOutlined, UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"
import * as actions from "store/actions/AuthActions"
import useScrollBottom from 'CustomHooks/useScrollBottom';

const VendorsTable = () => {

  const bottom = useScrollBottom();
  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [dataSource, setDataSource] = useState();
  const [count, setCount] = useState(2);
  const navigate = useNavigate();

  const fetchVendors = async (page) => {
    const response = await actions.loadVendors(page, searchTerm);
    console.log({ response, dataSource });
    if (response && response.results && response.results.length > 0) {
      if (dataSource && dataSource.length > 0) {
        const toPut = [];
        const pushedIds = [];
        dataSource.forEach((d, i) => {
          if (!pushedIds.includes(d.id)) {
            pushedIds.push(d.id);
            toPut.push(d);
          }
        });
        response.results.forEach((rr, i) => {
          if (!pushedIds.includes(rr.id)) {
            pushedIds.push(rr.id);
            toPut.push(rr);
          }
        })
        setDataSource(toPut);
      } else {
        setTotalRows(response.count);
        setDataSource(response.results);
      }
    }

  }

  const onSearch = (value) => {
    if (searchTerm === value) {

    } else {
      setSearchTerm(value);
      setDataSource([]);
      setPageNumber(0);
    }
  }

  useEffect(() => {
    let p = pageNumber + 1;
    // console.log({ next_p: p, dataSource, totalRows });
    if (p === 1) {
      fetchVendors(p);
      setPageNumber(p);
    } else if (dataSource && dataSource.length < totalRows) {
      fetchVendors(p);
      setPageNumber(p);
    }
  }, [bottom, searchTerm]);



  const handleDelete = async (id) => {
    const newData = dataSource.find((item) => item.id === id);
    // setDataSource(newData);
    // console.log(newData)
    const response = await actions.deleteVendors(
      newData.id
    );
    console.log(response);
    if (response) {
      fetchVendors()
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  }

  const defaultColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name - b?.name,
    },
    {
      title: 'CONTACT EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a?.email - b?.email,
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address',
      key: 'address',
      // filters: [
      //   {
      //     text: 'London',
      //     value: 'London',
      //   },
      //   {
      //     text: 'New York',
      //     value: 'New York',
      //   },
      // ],
      // onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
    {
      title: 'GST NUMBER',
      dataIndex: 'gst_number',
      key: 'gst_number',
      sorter: (a, b) => a?.gst_number - b?.gst_number,
    },
    {
      title: 'MOBILE',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a?.phone - b?.phone,
    },
    {
      title: 'WEBSITE',
      dataIndex: 'website',
      key: 'website',
      sorter: (a, b) => a?.website - b?.website,
    },
    {
      title: 'BALANCE',
      dataIndex: 'balance',
      key: 'balance',
      sorter: (a, b) => a?.balance - b?.balance,
    },
    {
      title: 'PAID TO DATE',
      dataIndex: 'paid_date',
      key: 'paid_date',
      sorter: (a, b) => a?.paid_date - b?.paid_date,
    },
    {
      title: 'CREATED DATE',
      dataIndex: 'create_date',
      key: 'create_date',
      sorter: (a, b) => a?.create_date - b?.create_date,
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => {
        // console.log(record);
        return dataSource?.length >= 1 ? (
          <Flex gap="middle">
            <a>
              <EditOutlined
                onClick={() => navigate(`/vendors/edit/${record.id}/`)}
                style={{
                  verticalAlign: 'middle',
                }}
              />
            </a>
            <Popconfirm title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Flex>
        ) : null
      }
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: '32',
      address: `London, Park Lane no. ${count}`,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const [selectionType, setSelectionType] = useState('checkbox');

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between p-2 sticky-top custom-top'>
        <p className='ms-2 mb-0'>
          ({dataSource?.length} / {totalRows})
        </p>

        <div>
          <Search placeholder="input search text" onSearch={onSearch} style={{ width: 300 }} />
        </div>


        <Button
          // onClick={handleAdd}
          onClick={() => navigate("/vendors/new")}
          icon={<UserAddOutlined />}
          type="primary"
          style={{
            float: "right"
          }}
        >
          Add Vendor
        </Button>
      </div>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        bordered
        size="small"
        dataSource={dataSource}
        columns={columns}
        sticky
        pagination={false}
      // scroll={{ x: 1200 }}
      />
    </div>
  );
};
export default VendorsTable;