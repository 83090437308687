
import { NavSider,VendorsTable } from "components";
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

const Vendors = () => {

    return (
        <VendorsTable/>
    );

}
export default Vendors;