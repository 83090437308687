import React from 'react';
import {
    Form,
    Col,
    Row,
    Card,
} from 'antd';

import DraggerBox from 'components/DraggerBoxTable/DraggerBox';

const onChange = (key) => {
    console.log(key);
};

const AssetFileForm = () => {
    return (
        <>
            <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
            >
                <Row
                    style={{ margin: "15px" }}
                >
                    <Col span={24} style={{}}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="Details" bordered={false} style={{}}>
                                <DraggerBox mode="assetfiles"/>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
export default AssetFileForm;