import React, { useEffect, useState } from "react";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { Avatar, Layout, Dropdown, Space, Row, Col } from "antd";
import { Organization } from "components";
import { logout } from "store/slices/AuthSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedDate = now.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
        day: "numeric",
      });
      const day = now.toLocaleDateString("en-US", { weekday: "short" });
      const time = now.toLocaleTimeString("en-GB", { hour12: false });
      setCurrentDateTime(`${formattedDate} | ${day} | ${time}`);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const items = [
    {
      label: <a href="">My Profile</a>,
      key: "0",
    },
    {
      label: (
        <div
          onClick={() => {
            dispatch(logout());
            navigate("/");
          }}
        >
          Logout
        </div>
      ),
      key: "1",
    },
    // {
    //     type: 'divider',
    // },
    // {
    //     label: '3rd menu item',
    //     key: '3',
    // },
  ];

  return (
    <>
      <Header
        className="site-layout-background"
        style={{
          height: "50px",
          width: "100%",
        }}
      >
        <div style={{ float: "right", lineHeight: "50px" }}>
          {/* <UserOutlined size={50} /> */}

          <Dropdown
            style={{}}
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Avatar
                style={{
                  // border:"1px solid white",
                  marginRight: "9px",
                  backgroundColor: "#001529",
                }}
                icon={<UserOutlined />}
              />
              <Space style={{ color: "#001529", marginTop: "0px" }}>
                User
                <DownOutlined size={30} />
              </Space>
            </a>
          </Dropdown>
        </div>
        {/* Live Clock */}
        <div
          style={{ float: "right", lineHeight: "50px", marginRight: "10px" }}
        >
          <div
            style={{
              float: "left",
              lineHeight: "50px",
              marginRight: "20px",
              color: "#fff",
            }}
          >
            {currentDateTime}
          </div>
          <Organization />
        </div>
      </Header>
    </>
  );
};

export default Navbar;
