import { PaperClipOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Slider,
  Switch,
  TreeSelect,
  Upload,
  Flex,
  Row,
  Card,
  Col
} from 'antd';
import * as actions from "store/actions/AuthActions";
import QEditor from 'components/Editors/QEditor';
import EmailEditor from 'react-email-editor';
import EmailInput from 'components/Inputs/EmailInput';

import { useNotification } from "NotificationContext";

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const { TextArea } = Input;
const normFile = (e) => {
  // if (Array.isArray(e)) {
  //   return e;
  // }
  return e?.file;
};

const SendInvoiceDrawer = ({ instance }) => {

  const [form] = Form.useForm();

  const notify = useNotification()

  const [attachments, setAttachments] = useState([]);
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const [templates, setTemplates] = useState([]);


  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("Teson - Tax Invoice");


  const [pdfRenderData, setPdfRenderData] = useState(null);

  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState(null);
 
  const printDivContent = () => {
    var divToPrint = document.getElementById('printableDiv');
    var newWin = window.open('', '_blank');
    newWin.document.open();
    newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');
    newWin.document.close();
    setTimeout(function () { newWin.close(); }, 10);
  }

  const loadTemplates = async () => {
    try {
      const response = await actions.loadTemplatesWithType("INVOICE__");
      if(response.status === 200) {
        setTemplates(response?.data?.results);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const render_invoice = async (msgTemplate) => {
    try {
      const response = await actions.invoiceRender(instance?.id, msgTemplate.name);
      if (response.status == 200) {
        setPdfRenderData(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    // if (document?.id) {
    //   setDocToEdit(document);
    //   const split_array = document?.name.split(".");
    //   // setTitle(split_array.length > 2 ? split_array.subArray[0, split_array.length - 1].join(".") : split_array[0])
    //   setExtension(split_array[split_array.length - 1]);
    //   // setDescription(document?.description);
    //   form.setFieldsValue({ "subject": `Attached Document: [${document?.name}]` })
    //   setLocked(document?.is_locked);
    //   console.log(document);
    //   emailEditorRef.current?.editor.showPreview('desktop');
    // }
    if (instance?.id) {
      console.log(instance);
      // setAttachments(instance.attachments);
      setAttachments(instance.attachments.map((item, index) => {
        if (item) {
          return { label: item.document.name, value: item.document.id };
        }
      }))
    }
    loadTemplates();
  }, [instance]);

  useEffect(()=> {
    if(selectedMessageTemplate){
      render_invoice(selectedMessageTemplate);
    }
  }, [selectedMessageTemplate]);

  const onFinish = async (values) => {
    console.log('Success:', values);
    // alert();
    // const new_values = { ...values, documents: [document?.id], to: toEmails, body: editorValue }
    // console.log("New values", new_values);
    setLoading(true);
    try {
      const response = await actions.sendInvoice(instance.id, values);
      if (response.status === 200) {
        notify("success", "Success", "Invoice sent successfully.")
      }
    }
    catch (err) {
      notify("error", "Error", `Failed to send invoice. ${err}`)
    }
    // const response = await authActions.emailDocuments(new_values);
    // if (response) {

    //   // dispatch(authActions.login(response));
    //   // notify("success", "DocumenttoEmails Updated Successfully!", null);
    //   // notification.success({
    //   //     message: `Login Successfull`,
    //   //     description: 'Hello, Ant Design!!',
    //   //     placement: 'topRight',
    //   // });
    // } else {
    //   notify("error", "Login Failed!", null);
    // }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const emailEditorRef = useRef(null);
  const handleEditorLoad = (design) => {
    emailEditorRef.current.loadDesign(design);
  }


  // const options = ['Option 1', 'Option 2', 'Option 3'];

  const handleChange = (value) => {
      console.log(`selected ${value}`);

      // setSelectedTemplate(messageTemplates.find(item => item.id === value));
      setSelectedMessageTemplate(templates.find(item => item.id === value));
  };

  const [componentDisabled, setComponentDisabled] = useState(true);

  return (
    <>
      <div>

        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          style={{
            minWidth: 800,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          initialValues={{ subject: subject }}
        >
          <EmailInput label="To" name="to" required message="Please enter emails" />
          <EmailInput label="CC" name="cc" />
          <EmailInput label="BCC" name="bcc" />
          {/* <EmailInput label="Email Template" name="template" /> */}
          <Form.Item label="Message" name="template" rules={[{ required: true, message: 'Please enter template!' }]}>
            <Select
              // style={{
              //     width: 120,
              // }}
              onChange={handleChange}
              placeholder="Select Message Template"
              allowClear
              options={templates.map((item, index) => {
                return { label: `${item.name}`, value: `${item.id}` }
              })}
            />
          </Form.Item>
          <Form.Item label="Subject" name="subject" rules={[{ required: true, message: 'Please enter subject!' }]}>
            <Input />
          </Form.Item>
          {instance?.is_manual && <Form.Item name="attachments" label="Select Attachments" rules={[{ required: true, message: 'Please select at least one checkbox!' }]}>
            {/* <CheckboxGroup options={attachments}  style={{flexDirection: "column"}}/> */}
            <CheckboxGroup style={{ flexDirection: "column" }}>
              {attachments.map((item, index) => {
                return <Checkbox value={item.value}>{item.label}</Checkbox>
              })}
            </CheckboxGroup>
          </Form.Item>}
          {/* <Form.Item label="Message" name="body">
          <QEditor value={editorValue} setValue={setEditorValue}/>
        </Form.Item> */}
          {/* <Form.Item label="Attachments">
            <PaperClipOutlined />{` `}{document?.name}
        </Form.Item> */}
          {/* <Form.Item label="Upload">
          <Form.Item name="dragger" valuePropName="file" getValueFromEvent={normFile} noStyle>
            <Upload.Dragger maxCount={1} name="document_file" action="/upload.do" defaultFileList={[{ name: document?.name, status: 'done', url: document?.document_file }]}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <hr /> */}
          {/* <Form.Item label="Upload" valuePropName="file" getValueFromEvent={normFile} name="document_file">
          <Upload action="/upload.do" maxCount={1}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item> */}
          {/* <Flex>
          <EmailEditor ref={emailEditorRef} onReady={() => {
            // if (currentItem) {
            //   // setTemplateInfo(currentItem.html_content);
            //   // handleEditorLoad(JSON.parse(currentItem.react_email_design));
            // }
          }} />
        </Flex> */}
          <Form.Item wrapperCol={{ offset: 4 }}>
            <Button htmlType="submit" loading={loading}>{loading ? "Sending..." : "Send Email"}</Button>
          </Form.Item>
        </Form>
        {!instance?.is_manual && <Row>
          <Col span={24} style={{ padding: "10px 10px", backgroundColor: "#F0F2F5" }}>
            {/* <Button onClick={async() => {
              // printDivContent();
              await actions.invoicePdf(instance?.id);
            }}>Download</Button> */}
            <Card title="Message" bordered={false}>
              <div dangerouslySetInnerHTML={{ __html: pdfRenderData?.message }}>

              </div>
            </Card>
            <Card title="Attachment" bordered={false} style={{marginTop: 10}}>
              <div dangerouslySetInnerHTML={{ __html: pdfRenderData?.attachment }}>

              </div>
            </Card>
          </Col>
        </Row>}
      </div>
    </>
  );
};
export default SendInvoiceDrawer;